import React, { useState, useCallback, useEffect, useMemo } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Stack,
  Avatar,
  RadioGroup,
  FormControlLabel,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from "@mui/material";
import "./Contract.css";
import Grid from "@mui/material/Grid";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { CircleCheckIcon } from "../../../Components/Images/Images";
import { Cancel, Upload, Add } from "@mui/icons-material";
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import { LocalizationProvider, DateTimePicker, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { useDropzone } from "react-dropzone";
import { Link, useNavigate, useParams } from "react-router-dom";
import { GetApi, postApi } from "../../../Api/Api";
import CommomRadio from "../../../Components/CommonRadio/CommonRadio";
import { getUserDataFromLocalStorage } from "../../../Services/localstorage.service";
import ReviewNewContract from "./ReviewProjectContract";
import { useSnackbar } from "../../../Contexts/SnackbarContext";
import config from "../../../Config/Config";
import { ccyFormat, checkNumberValue, formatDate, validAmountCheck } from "../../../Utils/utils";
import SpTransactionProgressModal from "../../SmartContracts/SpTransactionProgressModal";
import convertToDecimal from "../../../Components/Common/convertToDecimal";
import CommonBackdropLoader from "../../../Components/Common/CommonBackdropLoader";

const PostNewContract = ({ previousData }) => {

  const defaultMilestone = { description: null, dueDate: null, Amount: "", descriptionError: '', amountError: '' }
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const { projectId, spvId, offerId } = useParams();
  const userData = getUserDataFromLocalStorage();
  const [serviceFee, setServiceFee] = useState({});
  const [review, setReview] = useState(false);
  const [formData, setFormData] = useState({});
  const [contractId, setContractId] = useState('');
  const [individualProject, setIndividualProject] = useState([]);
  const [spvData, setSpvData] = useState({ name: "vikas" });
  const [offerData, setOfferData] = useState(null);

  const [dateTime, setDateTime] = useState('');
  const [deliveryDate, setDeliveryDate] = useState(null);
  const [openDateTimePicker, setOpenDateTimePicker] = useState(false);
  const [step1Title, setStep1Title] = useState("");

  const [step2Description, setstep2Description] = useState("");
  const [textCount, setTextCount] = useState(["5000"]);

  const [selectedOption, setSelectedOption] = useState('project');
  const [step3ProjectBudget, setStep3ProjectBudget] = useState("");
  const [step3HourlyRateFrom, setStep3HourlyRateFrom] = useState("");
  const [step3HourlyRateTo, setStep3HourlyRateTo] = useState("");
  const [step3Milestone, setStep3Milestone] = useState([defaultMilestone]);

  const [step5Description, setStep5Description] = useState("");

  const [totalMilestoneAmount, setTotalMilestoneAmount] = useState(0);

  const [uploadedImage, setUploadedImage] = useState([]);
  const [existingFiles, setExistingFiles] = useState([]);
  const [finishButtonClicked, setFinishButtonClicked] = useState(false);
  const [deletedDoc, setDeletedDoc] = useState([]);
  const [docFiles, setDocFiles] = useState([]);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const [openTransactionModal, setOpenTransactionModal] = useState(false);
  const [propData, setPropData] = useState({});


  const getGeneratedContractId = useCallback(async () => {
    try {
      const res = await GetApi("/services/generateContractId");
      if (res?.data?.data)
        setContractId(res?.data?.data);
      else
        setContractId([])
    } catch (error) {
    }
  }, [])

  /**
   * getting the service fee
   */
  useEffect(() => {
    const getServiceFeeConfig = async () => {
      try {
        const res = await GetApi("/services/serviceProviderConfig");
        if (res?.data && res?.data?.data?.projectContractServiceFee) setServiceFee(res?.data?.data?.projectContractServiceFee);
        else setServiceFee(config?.SERVICE_FEE?.CLIENT);
      } catch (error) {
        console.log(error);
      }
    }
    getServiceFeeConfig();
  }, []);

  useEffect(() => {
    function scrollTopFunction() {
      document.documentElement.scrollTop = 0;
    }
    scrollTopFunction()
  }, []);

  const getProjectData = useCallback(() => {
    GetApi(`/services/sp_projectRequirement?id=${projectId}`).then((res) => {
      if (res?.status === 200) {
        if (res.data && res.data.data && res.data.data.length > 0) {
          setIndividualProject(res.data.data[0]);
          if (res.data.data[0]?.project_title) setStep1Title(res.data.data[0]?.project_title);
          if (res.data.data[0]?.describe_services) {
            setstep2Description(res.data.data[0]?.describe_services);
            setTextCount(5000 - Number(res.data.data[0]?.describe_services.length));
          }
          if (res?.data?.data[0]?.sp_media_tables && res?.data?.data[0]?.sp_media_tables.length > 0) {
            setExistingFiles(res?.data?.data[0]?.sp_media_tables);
          } else {
            setExistingFiles([]);
          }
          if (res.data.data[0]?.budget === 'Hourly Rate') {
            setSelectedOption('hourly');
          }
          if (res.data.data[0]?.budget === 'Project Budget') {
            setSelectedOption('project');
          }
        }
      }
    })
  }, [projectId]);

  const getSpvData = useCallback(() => {
    GetApi(`/services/ProfileDetails?user_id=${spvId}`).then((res) => {
      if (res?.status === 200) {
        if (res.data && res.data.data && res.data.data.length > 0) {
          setSpvData(res.data.data[0]);
        }
      }
    })
  }, [spvId]);

  const getOfferData = useCallback(() => {
    GetApi(`/services/spvOffer?id=${offerId}`).then((res) => {
      if (res?.status === 200) {
        if (res.data && res.data.data && res.data.data.length > 0) {
          setOfferData(res.data.data[0]);
          setStep3ProjectBudget(res.data.data[0]?.offer_amount);
        }
      }
    })
  }, [offerId]);

  useEffect(() => {
    getGeneratedContractId();
    getProjectData();
    getSpvData();
    getOfferData();
  }, []);

  const handleChange = (event) => {
    if (event.target.value === 'milestone') {
      setStep3ProjectBudget(0);
      setStep3HourlyRateFrom(0);
      setStep3HourlyRateTo(0);
    }
    if (event.target.value === 'project') {
      setStep3HourlyRateFrom(0);
      setStep3HourlyRateTo(0);
      setStep3Milestone([defaultMilestone]);
    }
    setSelectedOption(event.target.value);
  };

  const switchToHourly = () => {
    setStep3ProjectBudget(0);
    setStep3Milestone([defaultMilestone]);
    setSelectedOption('hourly');
  }

  useEffect(() => {
    const updateDateTime = () => {
      /**Set current date */
      setDateTime(formatDate(new Date(), 'MM/dd/yy - hh:mm a'));
    };

    updateDateTime();
    const intervalId = setInterval(updateDateTime, 60000); // Update every minute

    return () => clearInterval(intervalId);
  }, []);

  // handle uploaded docs
  const onDrop = useCallback(
    (acceptedFiles) => {
      const pdfFilesArray = acceptedFiles.filter(
        (file) => file.type === "application/pdf"
      );
      let dataDetail = [...uploadedImage, ...pdfFilesArray];
      setUploadedImage(dataDetail);
    },
    [uploadedImage]
  );

  const { getRootProps, open, getInputProps } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: { "application/pdf": [".pdf"] },
    onDrop,
  });

  // handle deleted docs
  const handleDeleteImage = async (file) => {
    if (file) {
      const newDocFile = docFiles.filter((docFile) => {
        setDeletedDoc([...deletedDoc, docFile.id]);
        return docFile.image !== file;
      });
      setDocFiles(newDocFile);
    }
    const newUploadedImages = uploadedImage.filter(
      (image) => image.path !== file.path
    );
    setUploadedImage(newUploadedImages);
  };

  const handleDeleteExistingFile = async (file) => {
    const newUploadedImages = existingFiles.filter(
      (image) => image.path !== file.path
    );
    setExistingFiles(newUploadedImages);
  }

  // preparing to show uploaded docs
  const files = uploadedImage?.map((file) => (
    <Box className="showUploadedDocuments">
      <Box
        key={file.path}
        className="showUploadedDocuments-wrap"
        display={"flex"}
        alignItems={"center"}
      >
        <Box className="fileName" mr={1}>
          {file?.path?.includes("services/uploads/")
            ? file.original_name
            : file.name}
        </Box>
        <Cancel
          onClick={() => handleDeleteImage(file)}
          className="removeIcon"
        />
      </Box>
    </Box>
  ));

  useEffect(() => {
    if (previousData) {
      setStep1Title(previousData.step1Title);
      setstep2Description(previousData?.step2Description)
      setStep3ProjectBudget(previousData.step3ProjectBudget)
    }
  }, [previousData]);

  useEffect(() => {
    // Check for validation errors when form data changes
    if (finishButtonClicked) {
      validate();
    }
  }, [step1Title, step3ProjectBudget, step2Description, uploadedImage, existingFiles, step3HourlyRateFrom, step3HourlyRateTo, step3Milestone]);

  useEffect(() => {
    if (uploadedImage?.length > 0) {
      const newlyAddedFile = uploadedImage[uploadedImage?.length - 1];
      const checkExistingFileName = uploadedImage?.filter((i) => i.name === newlyAddedFile?.name);
      const checkExistingFile = existingFiles?.filter((i) => i.original_name === newlyAddedFile?.name);
      if (checkExistingFileName?.length > 1 || checkExistingFile?.length > 0) {
        showSnackbar('You cannot add a file with the same name again.', 'warning');
        let tempArray = [...uploadedImage];
        tempArray.pop();
        setUploadedImage(tempArray);
      }
    }
  }, [uploadedImage]);
  /**
   * Validation function for the form Fields
   * @returns
   */
  const validate = () => {
    let errors = {};
    if (!step1Title) {
      errors.step1Title = "Contract Title is required";
    } else {
      errors.step1Title = "";
    }
    if (step1Title) {
      if (step1Title.length > 50) {
        errors.step1Title = "Title cannot exceed 50 characters.";
      }
      if (step1Title.length < 3) {
        errors.step1Title = "Title must be at least 3 characters.";
      }
    }
    if (step2Description?.length < 500) {
      errors.step2Description = 'Please enter a description of at least 500 characters.';
    }
    if (step2Description?.length >= 500) {
      errors.step2Description = '';
    }
    if (!step2Description || step2Description.trim() === '') {
      errors.step2Description = 'Description is required';
    }

    if (uploadedImage?.length === 0 && existingFiles?.length === 0) {
      errors.Document = 'Document is required';
    }
    if (uploadedImage?.length > 0 || existingFiles?.length > 0) {
      errors.Document = '';
    }
    if ((uploadedImage?.length + existingFiles?.length) > 5) {
      errors.Document = 'You can upload a maximum of 5 files.';
    }

    if (selectedOption === 'project') {
      errors.step3ProjectBudget = step3ProjectBudget ? '' : 'required';

      errors.step3Milestone = '';
      errors.step3HourlyRateFrom = '';
      errors.step3HourlyRateTo = '';
    }
    if (selectedOption === 'milestone') {
      errors.step3Milestone = step3Milestone;

      errors.step3ProjectBudget = '';
      errors.step3HourlyRateFrom = '';
      errors.step3HourlyRateTo = '';
      // if(step3Milestone.filter((itm)=> !itm.descriptionError || !itm.amountError).length === 0){
      //   errors.step3Milestone = '';
      // }
      if (step3Milestone && step3Milestone.length > 0) {
        let milErr = step3Milestone.map((i) => {
          let amtErr = validAmountCheck(i.Amount);
          let dscErr = '';
          if (i.description) {
            if (i.description && i.description.trim()) {
              if (i.description && i.description.length > 80) {
                dscErr = 'Please enter 80 characters max';
              }
            } else {
              dscErr = 'Please enter valid description';
            }
          }
          if (i.description === null || i.description === '') dscErr = 'Description is required';
          return { ...i, descriptionError: dscErr, amountError: amtErr };
        });
        if (milErr.filter((itm) => itm.descriptionError || itm.amountError).length === 0) {
          errors.step3Milestone = '';
        } else {
          errors.step3Milestone = milErr;
        }
      }

    }
    if (selectedOption === 'hourly') {
      // errors.step3HourlyRateFrom = step3HourlyRateFrom ? '' : 'required';
      // errors.step3HourlyRateTo = step3HourlyRateTo ? '' : 'required';

      errors.step3ProjectBudget = step3ProjectBudget ? '' : 'required';
      errors.step3Milestone = '';
    }

    if (!deliveryDate) {
      errors.deliveryDate = 'delivery date is required';
    }
    setErrors(errors);
    return Object.keys(errors).every((key) => errors[key] === "");
  };

  const totalPriceOfProject = useMemo(() => {
    if (selectedOption === 'milestone') {
      return ccyFormat(checkNumberValue(totalMilestoneAmount));
    }
    return ccyFormat(checkNumberValue(step3ProjectBudget));
  }, [step3ProjectBudget, totalMilestoneAmount])

  const raceServiceFee = useMemo(() => {
    if (selectedOption === 'milestone') {
      return ccyFormat((checkNumberValue(totalMilestoneAmount ?? 0) * serviceFee) / 100);
    }
    if (selectedOption === 'hourly') {
      return ccyFormat(checkNumberValue(0));
    }
    return ccyFormat((checkNumberValue(step3ProjectBudget ?? 0) * serviceFee) / 100);
  }, [step3ProjectBudget, totalMilestoneAmount])

  const handleReviewProject = async () => {
    setFinishButtonClicked(true);
    const isValid = validate();
    const data = {
      dateCreated: dateTime,
      clientName: `${userData?.first_name}${userData?.last_name}`,
      hireName: `${spvData?.first_name} ${spvData?.last_name}`,
      contractId: contractId,
      Title: step1Title,
      Description: step2Description,
      Document: uploadedImage,
      ProjectBudget: step3ProjectBudget,
      projectId, spvId, totalPriceOfProject, raceServiceFee, deliveryDate
    };

    if (isValid) {
      // Proceed with reviewing the contract
      setFormData(data);
      setReview(true);
      document.documentElement.scrollTop = 0;
    }
  }

  const handleChangeDiscription = (e) => {
    const textData = e.target.value;
    if (textData.length < 5000) {
      setstep2Description(e.target.value);
    }
    setTextCount(5000 - textData.length);
  };

  const handleDeleteMilestone = (index) => {
    let tempArr = [...step3Milestone];
    tempArr.splice(index, 1);
    let sum = 0;
    tempArr.map((itm) => {
      if (itm.Amount === '' || isNaN(itm.Amount)) return itm;
      sum = sum + Number(itm.Amount);
      return itm;
    });
    setTotalMilestoneAmount(sum);
    setStep3Milestone(tempArr);
    setErrors({ ...errors, step3Milestone: tempArr });
  }

  const handleAddMilestone = () => {
    let milestoneObjForAdd = defaultMilestone;
    if (step3Milestone.length > 0) {
      const totalAmount = step3Milestone.reduce((accumulator, item) => {
        return accumulator + Number(item.Amount || 0);
      }, 0);
      if (totalAmount < offerData?.offer_amount) {
        milestoneObjForAdd = { ...milestoneObjForAdd, Amount: offerData?.offer_amount - totalAmount };
        let tempArr = [...step3Milestone, defaultMilestone];
        if (step3Milestone[step3Milestone.length - 1].Amount) {
          setErrors({ ...errors, step3Milestone: tempArr });
          setStep3Milestone(tempArr);
        } else {
          setErrors({ ...errors, step3Milestone: tempArr });
        }
      }
    } else {
      let tempArr = [...step3Milestone, defaultMilestone];
      setErrors({ ...errors, step3Milestone: tempArr });
      setStep3Milestone(tempArr);
    }
  }

  const handleMilestonechange = (key, value, index) => {
    let tempArr = [...step3Milestone];
    if (tempArr && tempArr.length > 0) {
      tempArr = tempArr.map((milestone, idx) => {
        if (index === idx) {
          let amtErr = '';
          if (key === 'Amount') {
            amtErr = validAmountCheck(value);
          }
          let dscErr = '';
          if (key === 'description') {
            if (value) {
              if (value && value.trim()) {
                if (value && value.length > 80) {
                  dscErr = 'Please enter 80 characters max';
                }
              } else {
                dscErr = 'Please enter valid description';
              }
            } else {
              dscErr = 'Description is required';
            }
          }
          return {
            ...milestone,
            [key]: value,
            'amountError': amtErr,
            'descriptionError': dscErr
          }
        }
        return milestone;
      })
      let sum = 0;
      tempArr.map((itm) => {
        if (itm.Amount === '' || isNaN(itm.Amount)) return itm;
        sum = sum + Number(itm.Amount);
        return itm;
      });
      setTotalMilestoneAmount(sum);
      setErrors({ ...errors, step3Milestone: tempArr });
      setStep3Milestone(tempArr);
    }
  }

  const handleDateChange = (newDate) => {
    if (newDate) {
      setDeliveryDate(newDate);
      setErrors((prevErrors) => ({ ...prevErrors, 'deliveryDate': "" }));
    }
  }

  const handleSubmitContract = async () => {

    let milestoneArr = [];
    milestoneArr = step3Milestone?.map((item) => {
      return {
        // description: '',
        expectedSubmissionDate: (item?.dueDate ? new Date(item?.dueDate).getTime() : 0),  // uint256
        amount: convertToDecimal(item?.Amount ? item?.Amount : 0), // uint256
        submittedDate: 0, // uint256
        approvedDate: 0, // uint256
        paymentClearDate: 0, // uint256
        milestoneStatus: 0
      }
    })
    let contractBudget = totalPriceOfProject;
    let contractPayload = {
      // _projectId: projectId ? projectId : 0, // uint256
      _projectId: offerData?.blockchain_id, // uint256
      _serviceProviderAddress: offerData?.serviceProviderData?.wallet_address,
      // _title: step1Title,
      // _description: step2Description,
      _ct: {
        termType: selectedOption, // enum
        milestones: milestoneArr,
        contractBudget: convertToDecimal(contractBudget ? contractBudget : 0), // uint256
        hourlyRate: 0, // uint256
        expectedHours: 0 // uint256
      },
      _deliveryDate: (deliveryDate ? new Date(deliveryDate).getTime() : 0),  // uint256
      _dbProjectId: offerData?.project_id,
    }

    setPropData(contractPayload);
    setOpenTransactionModal(true);
    return
  };

  const confirmStake = async () => {
    setOpenTransactionModal(false)
    setLoading(true);
    let formData = new FormData();
    if (projectId !== 'project') formData.append("project_id", projectId);
    formData.append("spv_id", spvId);
    formData.append("client_id", userData.id);
    formData.append("title", step1Title);
    formData.append("describe_project", step2Description);
    formData.append("client_message", step5Description);
    formData.append("project_budget", step3ProjectBudget);
    if (selectedOption === 'milestone') formData.append("by_milestone", JSON.stringify(step3Milestone));
    formData.append("service_fees", raceServiceFee);
    formData.append("project_fees", totalPriceOfProject);
    formData.append("total_fees", ccyFormat(checkNumberValue(raceServiceFee) + checkNumberValue(totalPriceOfProject)));
    formData.append("status", 1);
    uploadedImage?.forEach((val) => {
      formData.append("document", val);
    });

    if (existingFiles && existingFiles.length > 0) {
      let existingFile = existingFiles.map((item) => {
        return {
          mimetype: item.type,
          location: item.path,
          originalname: item.original_name,
          key: item.filename
        }
      })
      formData.append(`existingFiles`, JSON.stringify(existingFile));
    }
    formData.append("contract_id", contractId);
    if (offerId !== 'offer') formData.append("offer_id", offerId);
    formData.append("project_term", selectedOption);
    formData.append("delivery_date", deliveryDate);
    // Post new contract
    postApi(`/services/spvContract`, formData).then((res) => {
      if (res?.status === 200) {
        if (res?.data?.code === 200) {
          showSnackbar(res?.data?.message, 'success');
          setTimeout(() => {
            setLoading(false);
            navigate(`/service-proposer-dashboard/myorder`);
          }, 500);
        } else {
          setLoading(false);
          showSnackbar(res?.data?.message, 'error');
        }
      } else { // something went wrong user or backend side
        //  showSnackbar(res?.data?.message, 'error');
      }
    })

  }

  const handleModalClose = () => {
    setOpenTransactionModal(false);
  }

  if (review) {
    return <>
      <ReviewNewContract setReview={setReview} formData={formData} handleSubmitContract={handleSubmitContract} step5Description={step5Description} setStep5Description={setStep5Description} loading={loading} />
      <CommonBackdropLoader loading={loading} />
      {openTransactionModal ? <SpTransactionProgressModal identity={'create-new-contract'} confirmStake={confirmStake} propData={propData} handleModalClose={handleModalClose} openTransactionModal={openTransactionModal} /> : null}
    </>
  }
  return (
    <Box className="dashboard-overview-right service-proposer-postproject">
      <Box className="post-project-heading">
        <Typography component="h4" className="title">
          Propose a New Contract
        </Typography>
      </Box>
      <Box className="post-project-container">
        <Box className="card-container" >
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Typography className="text-blueGray-400">Date Created</Typography>
              <Typography sx={{ fontWeight: 500 }}>{dateTime}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography className="text-blueGray-400">Client / From</Typography>
              <Typography sx={{ fontWeight: 500 }}>{userData?.first_name} {userData?.last_name}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography className="text-blueGray-400">Service Provider / To</Typography>
              <Typography sx={{ fontWeight: 500 }}>{`${spvData?.first_name} ${spvData?.last_name}`}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Button className="btn-rounded btn-grey">Contract ID: {contractId}</Button>
            </Grid>
          </Grid>
        </Box>
        {/* Step 1 */}
        <Box className="card-container" mt={2}>
          <Box className="left-section">
            <Typography component="p" className="font-16">
              Step 1
            </Typography>
            <Typography component="p" className="font-22">
              Start with a contract title.
            </Typography>
            <Typography component="p" className="font-12 text-blueGray-300">
              Create a contract title that encapsulates the entire project and service.
            </Typography>
          </Box>
          <Box className="right-section">
            <Box className="input-box">
              <TextField
                id="outlined-basic"
                label="Write a title for your project post"
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                placeholder="E.g. Auditor needed for real estate project"
                value={step1Title}
                onChange={(e) => setStep1Title(e.target.value)}
              />
              {errors.step1Title && <Typography variant="body2" color="error">{errors.step1Title}</Typography>}
            </Box>
          </Box>
        </Box>

        {/* Step 2 */}
        <Box className="card-container" mt={2}>
          <Box className="left-section">
            <Typography component="p" className="font-16">
              Step 2
            </Typography>
            <Typography component="p" className="font-22">
              Describe the project
            </Typography>
            <Typography component="p" className="font-12 text-blueGray-300">
              Make sure you include:
            </Typography>
            <ul>
              <li className="font-12 text-blueGray-300">
                Clear expectations about your tasks or deliverables
              </li>
              <li className="font-12 text-blueGray-300">
                Skills required for your work
              </li>
              <li className="font-12 text-blueGray-300">
                Details about how you or your team like to work
              </li>
            </ul>
          </Box>
          <Box className="right-section">
            <Box className="new-skill-container">
              <Box className="title-box">
                <Typography component="h4" className="title">
                  Describe what you need
                </Typography>
              </Box>
              <Box
                component="form"
                sx={{ "& .MuiTextField-root": { m: 1, width: "99%" } }}
                noValidate
                autoComplete="off"
              >
                <TextField
                  id="outlined-multiline-flexible"
                  placeholder="Already have a description? Paste it here!"
                  multiline
                  maxRows={10}
                  rows={4}
                  value={step2Description}
                  onChange={(e) => handleChangeDiscription(e)}
                />
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  {errors.step2Description ? <Typography variant="body2" color="error">{errors.step2Description}</Typography> : <Typography paddingLeft={"32px"} color={'#94A3B8'} className='font-12'>Enter at least 500 characters</Typography>}
                  <Typography paddingRight={"24px"} color={'#94A3B8'} className="font-12">
                    {textCount} characters left
                  </Typography>
                </Box>
              </Box>
              <Box className="attach-file-btn" mt={2}>
                <Box
                  {...getRootProps({
                    className: "dropzone",
                  })}
                >
                  <input {...getInputProps()} />
                  <Stack direction="row" spacing={0} flexWrap={"wrap"}>
                    <Box className="btn-wrap" mb={{ xs: 2, sm: 4, md: 0 }}>
                      <Button
                        className="btn-rounded btn-blue-600 btn-text-white"
                        onClick={open}
                        startIcon={<Upload />}
                        sx={{ color: "white" }}
                      >
                        Attach Files
                      </Button>
                    </Box>
                  </Stack>
                </Box>
                {files !== "" ? (
                  <Box
                    sx={{
                      paddingBottom: "8px",
                      overflow: "hidden",
                    }}
                  >
                    {files?.map((fileName, ind) => {
                      return (
                        <>
                          {" "}
                          <Box
                            alignItems={"center"}
                            mt={1}
                            mb={2}
                            key={ind}
                            sx={{ display: "flex" }}
                          >
                            <Avatar
                              src={CircleCheckIcon}
                              alt="Icon"
                              sx={{ width: 24, height: 24 }}
                            />
                            <Box
                              className="dD-file-name text-black"
                              ml={1}
                              sx={{ color: "#fff" }}
                            >
                              {fileName}
                            </Box>
                          </Box>
                        </>
                      );
                    })}
                    {existingFiles && existingFiles.length > 0 && existingFiles?.map((file, ind) => {
                      return (
                        <>
                          {" "}
                          <Box
                            alignItems={"center"}
                            mt={1}
                            mb={2}
                            key={ind}
                            sx={{ display: "flex" }}
                          >
                            <Avatar
                              src={CircleCheckIcon}
                              alt="Icon"
                              sx={{ width: 24, height: 24 }}
                            />
                            <Box
                              className="dD-file-name text-black"
                              ml={1}
                              sx={{ color: "#fff" }}
                            >
                              {/* {file?.original_name} */}
                              <Box className="showUploadedDocuments">
                                <Box
                                  key={file.path}
                                  className="showUploadedDocuments-wrap"
                                  display={"flex"}
                                  alignItems={"center"}
                                >
                                  <Box className="fileName" mr={1}>
                                    {file?.original_name}
                                  </Box>
                                  <Cancel
                                    onClick={() => handleDeleteExistingFile(file)}
                                    className="removeIcon"
                                  />
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </>
                      );
                    })}
                    {errors.Document && (
                      <Typography variant="body2" style={{ color: "#d32f2f" }}>
                        {errors.Document}
                      </Typography>
                    )}
                  </Box>
                ) : (
                  ""
                )}
              </Box>
            </Box>
          </Box>
        </Box>

        {/* Step 3 */}
        <Box className="card-container" mt={2}>
          <Box className="left-section">
            <Typography component="p" className="font-16">
              Step 3
            </Typography>
            <Typography component="p" className="font-22">
              Project Terms
            </Typography>
          </Box>
          <Box className="right-section">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Box className="new-skill-container">
                <Box className="title-box">
                  <Typography component="h4" className="title">
                    Delivery Date <span style={{ color: "red" }}>*</span>
                  </Typography>
                </Box>
                <Box
                  component="form"
                  sx={{ "& .MuiTextField-root": { m: 1, width: "99%" } }}
                  noValidate
                  autoComplete="off"
                >
                  <Box className="DatePicker SPAddEducation">
                    <DatePicker
                      open={openDateTimePicker}
                      onOpen={() => setOpenDateTimePicker(true)}
                      onClose={() => setOpenDateTimePicker(false)}
                      value={deliveryDate}
                      onChange={(e) => handleDateChange(e)}
                      onError={(newError) => setErrors((prevErrors) => ({ ...prevErrors, 'deliveryDate': newError === 'disablePast' ? 'Please select valid date' : newError }))}
                      slotProps={{
                        textField: {
                          helperText: errors.deliveryDate,
                          onClick: (e) => setOpenDateTimePicker(true),
                          error: !!errors.deliveryDate,
                        },
                      }}
                      disablePast
                    />
                  </Box>
                </Box>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={6} md={6} sm={12}>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={selectedOption}
                        onChange={handleChange}
                      >
                        <FormControlLabel value="milestone" control={<CommomRadio color="#fff" />} label="By milestone" />
                        <FormControlLabel value="project" control={<CommomRadio color="#fff" />} label="By project" />
                      </RadioGroup>
                    </Grid>
                    <Grid item xs={6} md={6} sm={12}>
                      <Box onClick={() => switchToHourly()}
                        className={`card-main`}
                      >
                        <Typography component="h4" className="title">
                          <SwapHorizIcon sx={{ margin: "0px 10px -8px 10px" }} />  Switch to hourly
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>

                  <Box mt={3}>
                    {selectedOption === 'milestone' && (
                      <>
                        <Box className="title-box">
                          <Typography component="h4" className="title">
                            How many milestones do you want to include?&nbsp;&nbsp;
                            <Link to={'#'} style={{fontWeight:"bold"}} className="text-golden font-16 text-underline view-invoice-text">
                              Offer Amount - ${offerData?.offer_amount}
                            </Link>
                          </Typography>
                        </Box>
                        {step3Milestone && step3Milestone.map((milestone, index) => (
                          <><Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                            <Grid item xs={2} sm={4} md={4}>
                              <Box className="input-box">
                                <TextField
                                  id="outlined-basic"
                                  label="Milestone Description"
                                  InputLabelProps={{ shrink: true }}
                                  variant="outlined"
                                  value={milestone.description}
                                  onChange={(e) => handleMilestonechange('description', e.target.value, index)} />
                                {errors.step3Milestone && errors.step3Milestone.length > 0 && errors.step3Milestone[index].descriptionError && <Typography variant="body2" color="error">{errors.step3Milestone[index].descriptionError}</Typography>}
                              </Box>
                            </Grid>
                            <Grid item xs={2} sm={4} md={4}>
                              <Box className="input-box DatePicker SPAddEducation">
                                {index === 0 ? <DatePicker
                                  id="outlined-basic"
                                  label="Due Date(optional)"
                                  placeholder="MM/DD/YYYY"
                                  variant="outlined"
                                  value={milestone.dueDate}
                                  onChange={(e) => handleMilestonechange('dueDate', e, index)}
                                  disablePast
                                  maxDate={deliveryDate}
                                  renderInput={(params) => (
                                    <TextField {...params} required={false} />
                                  )}
                                /> :
                                  <DatePicker
                                    id="outlined-basic"
                                    label="Due Date(optional)"
                                    placeholder="MM/DD/YYYY"
                                    variant="outlined"
                                    value={milestone.dueDate}
                                    onChange={(e) => handleMilestonechange('dueDate', e, index)}
                                    disablePast
                                    minDate={step3Milestone[index - 1]?.dueDate ? step3Milestone[index - 1]?.dueDate : new Date()}
                                    maxDate={deliveryDate}
                                    renderInput={(params) => (
                                      <TextField {...params} required={false} />
                                    )}
                                  />}
                              </Box>
                            </Grid>
                            <Grid item xs={2} sm={3} md={3}>
                              <Box className="input-box">
                                <TextField
                                  id="outlined-basic"
                                  label="Amount"
                                  InputLabelProps={{ shrink: true }}
                                  variant="outlined"
                                  placeholder="$"
                                  value={"$" + milestone.Amount}
                                  onChange={(e) => handleMilestonechange('Amount', e.target.value.substring(1), index)} />
                                {errors.step3Milestone && errors.step3Milestone.length > 0 && errors.step3Milestone[index].amountError && <Typography variant="body2" color="error">{errors.step3Milestone[index].amountError}</Typography>}
                              </Box>
                            </Grid>
                            <Grid item xs={1} sm={1} md={1} className="cross-icon-contract">
                              <Box className="input-box ">
                                <Cancel
                                  onClick={() => handleDeleteMilestone(index)}
                                  className="removeIcon " />
                              </Box>
                            </Grid>
                          </Grid><br /></>
                        ))}
                        <Grid item xs={2} sm={12} md={12}>
                          <Box>
                            <Typography onClick={() => handleAddMilestone()} sx={{ cursor: "pointer" }} component="h4" className="title">
                              <Add sx={{ margin: "10px 10px -8px 10px" }} />  Add milestone
                            </Typography>
                          </Box>
                        </Grid>
                      </>
                    )}
                    {(selectedOption === 'project' || selectedOption === 'hourly') && (
                      <>
                        <Box className="title-box">
                          <Typography component="h4" className="title">
                            How much offer do you want to include?&nbsp;&nbsp;
                            <Link to={'#'} style={{fontWeight:"bold"}} className="text-golden font-16 text-underline view-invoice-text">
                              Offer Amount - ${offerData?.offer_amount}
                            </Link>
                          </Typography>
                        </Box>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={12}>
                            <Box className="input-box">
                              <TextField
                                id="outlined-basic"
                                label={selectedOption === 'project' ? "Amount" : "/hr"}
                                InputLabelProps={{ shrink: true }}
                                variant="outlined"
                                placeholder="/hr"
                                value={"$" + step3ProjectBudget} // Concatenating "$" with input value
                                onChange={(e) => {
                                  setStep3ProjectBudget(
                                    e.target.value.substring(1)
                                  )
                                  const tempError = errors;
                                  const err = validAmountCheck(e.target.value.substring(1));
                                  if (err) setErrors({ ...tempError, step3ProjectBudget: err });
                                  else setErrors({ ...tempError, step3ProjectBudget: '' });
                                }} // Removing "$" before setting state
                              />
                              {errors.step3ProjectBudget && <Typography variant="body2" color="error">{errors.step3ProjectBudget}</Typography>}
                            </Box>
                          </Grid>
                        </Grid>
                      </>
                    )}
                    {/* {selectedOption === 'hourly' && (
                      <>
                        <Box className="title-box">
                          <Typography component="h4" className="title">
                            How much offer do you want to include?
                          </Typography>
                        </Box>
                        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} mt={2}>
                          <Grid item xs={2} sm={6} md={6}>
                            <Box className="input-box">
                              <TextField
                                id="outlined-basic"
                                label="From"
                                InputLabelProps={{ shrink: true }}
                                variant="outlined"
                                placeholder="/hr"
                                value={step3HourlyRateFrom}
                                onChange={(e) =>
                                  setStep3HourlyRateFrom(e.target.value)
                                }
                              />
                              {errors.step3HourlyRateFrom && <Typography variant="body2" color="error">Field is required</Typography>}
                            </Box>
                          </Grid>
                          <Grid item xs={2} sm={6} md={6}>
                            <Box className="input-box">
                              <TextField
                                id="outlined-basic"
                                label="To"
                                InputLabelProps={{ shrink: true }}
                                variant="outlined"
                                placeholder="/hr"
                                value={step3HourlyRateTo}
                                onChange={(e) =>
                                  setStep3HourlyRateTo(e.target.value)
                                }
                              />
                              {errors.step3HourlyRateTo && <Typography variant="body2" color="error">Field is required</Typography>}
                            </Box>
                          </Grid>
                        </Grid>
                      </>
                    )} */}
                  </Box>
                </Box>
              </Box>
            </LocalizationProvider>
          </Box>
        </Box>

        {/* Step 4 */}
        <Box className="" mt={2}>
          <Box className="table-view-container">
            <Grid container spacing={2}>
              <Grid item xs={9} md={6} sm={6}>
                <Box className="your-order-div" p={2}>
                  <Typography className="font-16 text-white">
                    Fees
                  </Typography>
                  <Typography className="text-blueGray-300 font-12" ml={2}>
                    {formatDate(new Date(), 'MMM d, yyyy')}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={3} md={6} sm={6} sx={{ alignSelf: "center", textAlign: 'end' }} pr={2}>
                <Link to={'#'} className="text-golden font-12 text-underline view-invoice-text">
                  Learn more about fees
                </Link>
              </Grid>
            </Grid>

            <Box mt={1} className="item-table-main">
              <TableContainer >
                <Table aria-label="spanning table">
                  <TableHead>
                    <TableRow>
                      <TableCell>TOTAL PRICE OF PROJECT</TableCell>
                      <TableCell align="right"></TableCell>
                      <TableCell align="right"></TableCell>
                      <TableCell align="right">
                        {totalPriceOfProject}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>This includes all milestones, and this is the amount your Service Provider will see</TableCell>
                      <TableCell align="right"></TableCell>
                      <TableCell align="right"></TableCell>
                      <TableCell align="right">
                        {totalPriceOfProject}
                      </TableCell>
                    </TableRow>
                    <TableRow className="sub-total-div">
                      <TableCell>RACE SERVICE FEE</TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell align="right">
                        {raceServiceFee}
                      </TableCell>
                    </TableRow>
                    <TableRow className="sub-total-div">
                      <TableCell>YOU'LL PAY</TableCell>
                      <TableCell></TableCell>
                      <TableCell align="right"></TableCell>
                      <TableCell align="right">
                        {ccyFormat(checkNumberValue(raceServiceFee) + checkNumberValue(totalPriceOfProject))}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Your estimated payment, after all fees</TableCell>
                      <TableCell align="right"></TableCell>
                      <TableCell align="right"></TableCell>
                      <TableCell align="right">
                        {ccyFormat(checkNumberValue(raceServiceFee) + checkNumberValue(totalPriceOfProject))}
                      </TableCell>
                    </TableRow>
                  </TableBody>

                </Table>
              </TableContainer>
            </Box>
          </Box>
        </Box>
        <Box className="confirm-title">
          <Typography className="text-blueGray-300 footer-text">
            When the Service Provider accepts, you‘ll receive an order for final confirmation
          </Typography>
        </Box>

        {/* Review Project Button */}
        <Box className="new-project-btn">
          <Typography
            className="footer-text cursor-pointer"
            sx={{ color: "#398CD1" }} mr={8} mt={1}
            onClick={() => navigate(-1)}
          >Cancel</Typography>
          <Button
            variant="outlined"
            startIcon={<RemoveRedEyeIcon />}
            onClick={handleReviewProject}
          >
            Review Contract
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default PostNewContract;