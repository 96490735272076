// setRepaymentConfigs.js

import { writeContract, waitForTransaction, prepareWriteContract } from "@wagmi/core";
import { CONTRACTS, CONTRACT_ABI } from "../../Web3/Contracts";

/**
 * Approve the tokens for spender contract
 * @param {String} address user wallet address
 * @param {String} spenderAddress spender contract address
 * @param {String} allowanceAmt  amount to approve
 * @returns transaction data
 */
async function setRepaymentConfigs(address, assetData, showSnackbar, handleActiveStep, handleModalClose, handleSuccess) {

    try {
        let repaymentConfigData = assetData?.repaymentData
        // Now check for allowance RACE Tokens
        const { request: setRepaymentConfigs } = await prepareWriteContract({
            address: CONTRACTS.DIC_PROPOSAL_LOAN,
            abi: CONTRACT_ABI[CONTRACTS.DIC_PROPOSAL_LOAN],
            account: address,
            chainId: 6806,
            functionName: "setRepaymentConfigs",
            args: [
                assetData?.blockchain_id,
                {
                    emiPenaltyPercent: repaymentConfigData?.emiPenaltyPercent * 100,
                    emiPenaltyAmount: repaymentConfigData?.emiPenaltyAmount * 1e18,
                    repossessionContinuous: repaymentConfigData?.repossessionContinuous,
                    repossessionContiguous: repaymentConfigData?.repossessionContiguous,
                    yeildMaintainancePercent: repaymentConfigData?.yeildMaintainancePercent * 100,
                    yeildMaintainanceAmount: repaymentConfigData?.yeildMaintainanceAmount * 1e18,
                    isMinimumPrepayment: repaymentConfigData?.isMinimumPrepayment,
                    requiredMonthForMinimumPrepayment: repaymentConfigData?.isMinimumPrepayment ? repaymentConfigData?.requiredMonthForMinimumPrepayment : 0,
                    minPrepaymentPercent: repaymentConfigData?.isMinimumPrepayment ? repaymentConfigData?.minPrepaymentPercent * 100 : 0,
                    maxPrepaymentPercent: repaymentConfigData?.isMinimumPrepayment ? repaymentConfigData?.maxPrepaymentPercent * 100 : 0,
                    minPrepaymentFeesPercent: repaymentConfigData?.isMinimumPrepayment ? repaymentConfigData?.minPrepaymentFeesPercent * 100 : 0,
                    minPrepaymentFeesAmount: repaymentConfigData?.isMinimumPrepayment ? repaymentConfigData?.minPrepaymentFeesAmount * 1e18 : 0
                }
            ]
        })

        const { hash } = await writeContract(setRepaymentConfigs);

        if (hash) {
            const data = await waitForTransaction({
                hash: hash,
            })
            if (data.status === "success") {
                showSnackbar("Transaction Successful", 'success')
                handleActiveStep(2)
                setTimeout(() => {
                    handleSuccess(assetData)
                    handleModalClose();
                    handleActiveStep(-1)
                    return { data }
                }, 1000)
            } else {
                handleModalClose();
                handleActiveStep(-1)
                showSnackbar("Transaction Failed", 'error')
                return
            }
        }
    } catch (error) {
        console.error('Error minting asset:', error);
        throw error; // propagate the error
    }
}

export { setRepaymentConfigs };