/**
 * Function to convert a number number * 10^18
 * @param {Number} number 
 * @returns 
 */
export default function convertToDecimal(number) {
    let newNumber = Math.ceil(number * 10) / 10;
    return (parseInt(newNumber * 10) * 1e17).toLocaleString("fullwide", { useGrouping: !1 })
}

// import { ethers } from "ethers";

/**
 * Function to convert a number to number * 10^18 using ethers.js
 * @param {Number} number
 * @returns {String} Converted number in Wei (10^18)
 */
// export default function convertToDecimal(number) {
//     const weiValue = ethers.parseUnits(number.toString(), 18);
//     return weiValue.toString();
// }