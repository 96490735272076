import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Box, Button, Grid, IconButton, Radio, TextField, Tooltip, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

// Import custom components for this page
import { NewLogo, Quest4 } from "../../Components/Images/Images";
import { postApi } from "../../Api/Api";
import { Add, Info, KeyboardArrowLeft, Remove } from "@mui/icons-material";
import NumericFormatCustom from "../../Components/Common/NumericFormatCustom";
import NumericFormatCustomPercent from "../../Components/Common/NumericFormatCustomPercent";
import NumericFormatCustomDollar from "../../Components/Common/NumericFormatCustomDollar";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import TransactionProgressModal from "../../Components/SmartContract/TransactionProgressModal";

export default function SetRepaymentConfigPage() {
    const navigate = useNavigate();
    const location = useLocation();
    let asset_id = location?.state?.asset_id;

    // states
    const [assetData, setAssetData] = useState();
    const [currentImage, setCurrentImage] = useState();
    const [openModal, setOpenModal] = useState(false);
    const [isError, setIsError] = useState(false);

    // state to handle all inputs and data
    const [formData, setFormData] = useState({
        emiPenaltyPercent: 0,
        emiPenaltyAmount: 0,
        repossessionContinuous: 0,
        repossessionContiguous: 0,
        yeildMaintainancePercent: 0,
        yeildMaintainanceAmount: 0,
        isMinimumPrepayment: false,
        minPrepaymentPercent: 0,
        maxPrepaymentPercent: 0,
        minPrepaymentFeesPercent: 0,
        minPrepaymentFeesAmount: 0,
        requiredMonthForMinimumPrepayment: 0,
        continuous: true
    });

    // get asset data
    useEffect(() => {
        if (asset_id) {
            async function getAssetData() {
                const res = await postApi("/proposer/assetsDetails", { asset_id: parseInt(asset_id) });
                if (res?.data?.data && res?.data?.data[0]) {
                    setAssetData(res?.data?.data[0])
                    setCurrentImage(`${process.env.REACT_APP_IMAGE_URL}${res.data.data[0]?.asset_gallery[0]?.images?.path}`);
                }
            }
            setTimeout(() => {
                getAssetData();
            }, 100);
        }
    }, [])

    useEffect(() => {
        // check null values
        if (formData.emiPenaltyPercent === '' || formData.emiPenaltyAmount === '' || (formData.continuous ? formData.repossessionContinuous === '' : formData.repossessionContiguous === '') || formData.yeildMaintainancePercent === '' || formData.yeildMaintainanceAmount === '' || formData.isMinimumPrepayment && (formData.minPrepaymentPercent === '' || formData.maxPrepaymentPercent === '' || formData.minPrepaymentFeesPercent === '' || formData.minPrepaymentFeesAmount === '' || formData.requiredMonthForMinimumPrepayment === '')) {
            setIsError(true);
        } else if (formData.emiPenaltyPercent > 100 || formData.yeildMaintainancePercent > 100 || (formData.isMinimumPrepayment && (formData.minPrepaymentPercent > 100 || formData.maxPrepaymentPercent > 100 || formData.minPrepaymentFeesPercent > 100))) {
            setIsError(true);
        } else {
            setIsError(false);
        }
    }, [formData])

    const handleNavigate = () => {
        navigate(`/dic/project-details/${asset_id}`, {
            replace: true, // This will replace the current entry in the history stack
        })
    }

    // Style for left-half background image
    const styles = {
        backgroundImage: `url(${Quest4})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        minHeight: '100vh',
        marginRight: '0px',
        // backgroundPosition: "center",
        backgroundAttachment: "fixed",
        boxShadow: '10px white',  // Adds a 2px blue border
        // filter: 'blur(2px)', // Adjust the blur level as needed
    }

    /**
    * Handle set repayment config modal save button
    */
    const handleSave = () => {
        console.log(formData);
        // check all validation before contract call
        if (isError) return
        setOpenModal(true)
    }

    /**
    * Handle set repayment config modal close
    */
    const handleModalClose = () => {
        setOpenModal(false)
    }

    /**
    * Handle set repayment confirmation
    */
    const handleConfirmTransaction = () => {
        setOpenModal(false)
        navigate(`/dic/project-details/${asset_id}`, {
            replace: true, // This will replace the current entry in the history stack
        })
    }

    const handleChange = (key) => (e) => {
        setFormData({ ...formData, [key]: e.target.value });
    };

    const handleKeyDown = (event) => {
        let keyArr = ["-", "+", "."]
        if (keyArr.includes(event.key)) {
            event.preventDefault();
        }
    };

    return (
        <Box className="projectProposer questionnaire-page">
            <Box className="questionnaire-section">
                <Grid container spacing={0}>
                    <Grid item xs={12} sm={5} md={5}>
                        <Box className="auth-left" style={styles}>
                            <Box className="quests-left-title">
                                <Typography component="h1" className="title">
                                    Set Repayment Terms
                                </Typography>
                            </Box>
                            <Box className="auth-logo">
                                <Link to="/">
                                    <Box component="img" src={NewLogo} alt="Race logo" />
                                </Link>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={7} md={7}>
                        <Box className="auth-right quests-right-contents-box" sx={{ gap: 6 }}>
                            <Box textAlign={"right"} className="alreadyAc-des">
                                <Button startIcon={<KeyboardArrowLeft />} onClick={handleNavigate} className='btn-rounded btn-text-white btn-blue-600'>
                                    Back
                                </Button>
                            </Box>
                            <Box className="quests-right-contents">
                                <OverlayScrollbarsComponent defer
                                    style={{ width: '100%', minHeight: '340px', maxHeight: '60vh', margin: '10px 24px 0 10px' }}
                                >
                                    <Grid container spacing={{ md: 3, sm: 2.5, xs: 2 }}>
                                        {/* Penalty Percentage */}
                                        <CustomTextField
                                            label="Penalty Percentage"
                                            inputComponent={NumericFormatCustomPercent}
                                            value={formData.emiPenaltyPercent}
                                            name="emiPenaltyPercent"
                                            onChange={handleChange('emiPenaltyPercent')}
                                            onKeyDown={handleKeyDown}
                                            type='%'
                                            tooltipText="This is the percentage applied as a penalty."
                                            isOr={true}
                                        />

                                        {/* OR */}
                                        <Grid item sm={0.8} md={0.8} style={{ display: 'flex', alignItems: 'center' }} marginBottom={{ xs: 0, sm: '-24px' }}>
                                            <Box>
                                                <Box width="100%" style={{ color: 'black', fontWeight: '500', fontSize: '19px' }}>
                                                    OR
                                                </Box>
                                            </Box>
                                        </Grid>

                                        {/* Penalty Amount */}
                                        <CustomTextField
                                            label="Penalty Amount"
                                            inputComponent={NumericFormatCustomDollar}
                                            value={formData.emiPenaltyAmount}
                                            name="emiPenaltyAmount"
                                            onChange={handleChange('emiPenaltyAmount')}
                                            onKeyDown={handleKeyDown}
                                            tooltipText="This is the penalty amount in USD."
                                            isOr={true}
                                        />

                                        {/* Yield Maintenance Percentage */}
                                        <CustomTextField
                                            label="Yield Maintenance Percentage"
                                            inputComponent={NumericFormatCustomPercent}
                                            value={formData.yeildMaintainancePercent}
                                            name="yeildMaintainancePercent"
                                            onChange={handleChange('yeildMaintainancePercent')}
                                            onKeyDown={handleKeyDown}
                                            type='%'
                                            tooltipText="Percentage of yield maintenance."
                                            isOr={true}
                                        />

                                        {/* OR */}
                                        <Grid item sm={0.8} md={0.8} style={{ display: 'flex', alignItems: 'center' }} marginBottom={{ xs: 0, sm: '-24px' }}>
                                            <Box>
                                                <Box width="100%" style={{ color: 'black', fontWeight: '500', fontSize: '19px' }}>
                                                    OR
                                                </Box>
                                            </Box>
                                        </Grid>

                                        {/* Yield Maintenance Amount */}
                                        <CustomTextField
                                            label="Yield Maintenance Amount"
                                            inputComponent={NumericFormatCustomDollar}
                                            value={formData.yeildMaintainanceAmount}
                                            name="yeildMaintainanceAmount"
                                            onChange={handleChange('yeildMaintainanceAmount')}
                                            onKeyDown={handleKeyDown}
                                            tooltipText="This is the yield maintenance amount in USD."
                                            isOr={true}
                                        />

                                        {/* Repossession Month */}
                                        <Grid item xs={12} sm={12} md={12}>
                                            <Box style={{ display: 'flex' }}>
                                                <Typography className="font-16 text-black" sx={{ display: 'flex', gap: '2px' }}>
                                                    Repossession Month
                                                    <Tooltip title={'The month in which the repossession occurred or is planned.'} placement="top" arrow>
                                                        <IconButton size="small" style={{ padding: '0 5px' }}>
                                                            <Info fontSize="small" />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Typography>
                                            </Box>
                                            <Grid container spacing={{ md: 4, sm: 1, xs: 2 }} style={{ display: 'flex', alignItems: 'center' }}>
                                                <Grid item xs={11.6} sm={11.6} md={5.6}>
                                                    <Box my={1} style={{ overflowWrap: 'anywhere' }}>
                                                        <Box style={{ width: '100%', display: 'flex', gap: '16px' }}>
                                                            <Box style={{ display: 'flex', alignItems: 'center' }}>
                                                                <Radio onChange={() => setFormData({ ...formData, continuous: true })} checked={formData?.continuous ? true : false} />
                                                                <Typography className="font-16 text-black" sx={{ display: 'flex', gap: '2px' }}>
                                                                    Continuous
                                                                </Typography>
                                                            </Box>
                                                            <Box style={{ display: 'flex', alignItems: 'center' }}>
                                                                <Radio onChange={() => setFormData({ ...formData, continuous: false })} checked={formData?.continuous ? false : true} />
                                                                <Typography className="font-16 text-black" sx={{ display: 'flex', gap: '2px' }}>
                                                                    Contiguous
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </Grid>
                                                <CustomTextField
                                                    inputComponent={NumericFormatCustom}
                                                    value={formData.continuous ? formData.repossessionContinuous : formData.repossessionContiguous}
                                                    onChange={(e) =>
                                                        formData.continuous
                                                            ? setFormData({ ...formData, repossessionContinuous: e.target.value })
                                                            : setFormData({ ...formData, repossessionContiguous: e.target.value })
                                                    }
                                                    onKeyDown={handleKeyDown}
                                                />
                                            </Grid>
                                        </Grid>

                                        {/* Is Minimum Prepayment Required */}
                                        <Grid item xs={12} sm={12} md={12}>
                                            <Grid container spacing={{ md: 4, sm: 3, xs: 2 }} style={{ display: 'flex', alignItems: 'center' }}>
                                                <Grid item xs={11.6} sm={5.6} md={5.6}>
                                                    <Box style={{ display: 'flex' }}>
                                                        <Typography className="font-16 text-black" sx={{ display: 'flex', gap: '2px' }}>
                                                            Is Minimum Prepayment Required
                                                            <Tooltip title={'Indicates if a minimum prepayment is required for the loan.'} placement="top" arrow>
                                                                <IconButton size="small" style={{ padding: '0 5px' }}>
                                                                    <Info fontSize="small" />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={11.6} sm={5.6} md={5.6}>
                                                    <Box my={1}>
                                                        <Box style={{ width: '100%', display: 'flex', gap: '16px' }}>
                                                            <Box style={{ display: 'flex', alignItems: 'center' }}>
                                                                <Radio onChange={() => setFormData({ ...formData, isMinimumPrepayment: true })} checked={formData?.isMinimumPrepayment ? true : false} />
                                                                <Typography className="font-16 text-black" sx={{ display: 'flex', gap: '2px' }}>
                                                                    Yes
                                                                </Typography>
                                                            </Box>
                                                            <Box style={{ display: 'flex', alignItems: 'center' }}>
                                                                <Radio onChange={() => setFormData({ ...formData, isMinimumPrepayment: false })} checked={formData?.isMinimumPrepayment ? false : true} />
                                                                <Typography className="font-16 text-black" sx={{ display: 'flex', gap: '2px' }}>
                                                                    No
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        {/* Display these fields if isMinimumPrepayment is true */}
                                        {formData.isMinimumPrepayment ?
                                            <>
                                                <CustomTextField
                                                    label="Minimum Prepayment Amount Percent"
                                                    inputComponent={NumericFormatCustomPercent}
                                                    value={formData.minPrepaymentPercent}
                                                    name="minPrepaymentPercent"
                                                    onChange={handleChange('minPrepaymentPercent')}
                                                    onKeyDown={handleKeyDown}
                                                    type='%'
                                                    tooltipText="Enter the minimum percentage of the prepayment amount required."
                                                />
                                                <CustomTextField
                                                    label="Maximum Prepayment Amount Percent"
                                                    inputComponent={NumericFormatCustomPercent}
                                                    value={formData.maxPrepaymentPercent}
                                                    name="maxPrepaymentPercent"
                                                    onChange={handleChange('maxPrepaymentPercent')}
                                                    onKeyDown={handleKeyDown}
                                                    type='%'
                                                    tooltipText="Enter the maximum percentage of the prepayment amount allowed."
                                                />
                                                <CustomTextField
                                                    label="Minimum Prepayment Fees Percent"
                                                    inputComponent={NumericFormatCustomPercent}
                                                    value={formData.minPrepaymentFeesPercent}
                                                    name="minPrepaymentFeesPercent"
                                                    onChange={handleChange('minPrepaymentFeesPercent')}
                                                    onKeyDown={handleKeyDown}
                                                    type='%'
                                                    tooltipText="Enter the minimum percentage of prepayment fees required."
                                                    isOr={true}
                                                />

                                                {/* OR */}
                                                <Grid item sm={0.8} md={0.8} style={{ display: 'flex', alignItems: 'center' }} marginBottom={{ xs: 0, sm: '-24px' }}>
                                                    <Box>
                                                        <Box width="100%" style={{ color: 'black', fontWeight: '500', fontSize: '19px' }}>
                                                            OR
                                                        </Box>
                                                    </Box>
                                                </Grid>

                                                <CustomTextField
                                                    label="Maximum Prepayment Fees Amount"
                                                    inputComponent={NumericFormatCustomDollar}
                                                    value={formData.minPrepaymentFeesAmount}
                                                    name="minPrepaymentFeesAmount"
                                                    onChange={handleChange('minPrepaymentFeesAmount')}
                                                    onKeyDown={handleKeyDown}
                                                    tooltipText="Enter the maximum amount of prepayment fees in USD."
                                                    isOr={true}
                                                />
                                                <CustomTextField
                                                    label="Required Month for Minimum Prepayment"
                                                    inputComponent={NumericFormatCustom}
                                                    value={formData.requiredMonthForMinimumPrepayment}
                                                    name="requiredMonthForMinimumPrepayment"
                                                    onChange={handleChange('requiredMonthForMinimumPrepayment')}
                                                    onKeyDown={handleKeyDown}
                                                    tooltipText="Enter the month in which minimum prepayment is required."
                                                />
                                            </>
                                            : null}
                                    </Grid>
                                </OverlayScrollbarsComponent>
                            </Box>
                            <Box mt={2}>
                                <Box textAlign={"right"} className='quests-btn-box' mt={4.7}>
                                    <Button endIcon={<KeyboardArrowRightIcon />} onClick={handleSave} className='btn-rounded btn-text-white btn-blue-600'>
                                        Save
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>

            {
                openModal
                    ? <TransactionProgressModal identity={'set-repayment-config'} confirmStake={handleConfirmTransaction} propData={{ ...assetData, repaymentData: formData }} handleModalClose={handleModalClose} openTransactionModal={openModal} />
                    : null
            }
        </Box>
    );
}

// Show error message
const ShowErrorMessage = ({ message }) => {
    return (<Typography className="text-red font-12" mt={'-2px'}>
        {message}
    </Typography>)
}

// Reusable CustomTextField Component
const CustomTextField = ({ label, inputComponent, value, name, onChange, onKeyDown, type, tooltipText, isOr }) => (
    <Grid item xs={11.6} sm={isOr ? 5.2 : 5.6} md={isOr ? 5.2 : 5.6}>
        {label ?
            <Box display="flex">
                <Typography className="font-16 text-black" sx={{ display: 'flex', gap: '2px' }}>
                    {label}
                    <Tooltip title={tooltipText} placement="top" arrow>
                        <IconButton size="small" style={{ padding: '0 5px' }}>
                            <Info fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </Typography>
            </Box> : null
        }
        <Box my={1}>
            <Box width="100%">
                <TextField
                    id="outlined-number"
                    type="text"
                    defaultValue={0}
                    className="loan-text"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ inputComponent }}
                    name={name}
                    value={value}
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                />
            </Box>
        </Box>
        {value === '' ? <ShowErrorMessage message={`${label} cannot be empty.`} /> : null}
        {type === '%' && value > 100 ? <ShowErrorMessage message={`${label} can not be greater than 100.`} /> : null}
    </Grid>
);
