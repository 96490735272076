// Importing necessary dependencies and styles
import React, { useState, useEffect } from "react";
import {
  Avatar,
  Box,
  Button,
  InputBase,
  Stack,
  Typography,
  Menu,
  MenuItem,
  ListItemIcon,
  List,
  Drawer,
  ListItem,
  Badge,
  IconButton,
  Tabs,
  Tab,
  Divider,
  TextField,
  Autocomplete,
  Paper
} from "@mui/material";
import { ArrowBackIos, KeyboardArrowDown, Search, SearchRounded } from "@mui/icons-material";
import {
  NewLogo,
  User,
  Notification,
  Timelapse,
  Message,
  custoDownArrow,
} from "../../Components/Images/Images";
import "./HeaderServiceProvider.css";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { useSocket } from "../../Contexts/SocketContext";
import { useSnackbar } from "../../Contexts/SnackbarContext";
import useAuth from "../../Contexts/useAuth";
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useSelector } from "react-redux";
import Logout from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import { ClearIcon } from "@mui/x-date-pickers";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { TabPanel } from "@mui/lab";
import { TabContext } from "@mui/lab";
import { GetApi, postApi, GetApiParam } from "../../Api/Api";
import { getUserDataFromLocalStorage, isUserLoggedIn } from "../../Services/localstorage.service";

// Component for rendering the logo
const LogoIcon = ({ navigateHome }) => (
  <Box className="logo">
    <img
      src={NewLogo}
      alt="Logo"
      onClick={navigateHome}
      style={{ cursor: "pointer" }}
    />
  </Box>
);

// Component for rendering each header item with text and optional icon
const HeaderItem = ({ text, icon, position, link }) => (
  <Link to={link}>
    <Box className="header-items" flexDirection={position ? "row-reverse" : ""}>
      {icon && React.cloneElement(icon, { className: "icon" })}
      <Typography className="text">{text}</Typography>
    </Box>
  </Link>
);

// Component for rendering the "Get Started" button
const GetStartedButton = () => (
  <Box className="getStarted">
    <Button variant="contained" className="btn-rounded btn-golden">
      Get Started
    </Button>
  </Box>
);

const CustomPaper = (props) => {
  return (
    <Paper {...props} style={{ backgroundColor: '#06487e', color: "white" }}>
      {props.children}
    </Paper>
  );
};

// Main Header component
const Header = ({ handleTimelapseClick }) => {
  const { login } = useAuth();
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [anchorEl3, setAnchorEl3] = useState(null);
  // State to handle user login
  const [isLogin, setIsLogin] = useState(false);
  // State variables for anchor element and its open state
  const [notifications, setNotifications] = useState([]);
  const [unreadNotifications, setUnreadNotifications] = useState(0);
  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  const socket = useSocket();
  const { showSnackbar } = useSnackbar();
  const [searchString, setSearchString] = useState('');
  const [services, setServices] = useState([]);
  const [anchorElSearch, setAnchorElSearch] = useState(null);
  const openSearch = Boolean(anchorElSearch);

  const open = Boolean(anchorEl);
  const openheadermenu = Boolean(anchorEl2);
  const open3 = Boolean(anchorEl3);

  const location = useLocation();

  const localData = isUserLoggedIn();
  const localStateData = getUserDataFromLocalStorage();
  const userData = useSelector((state) => state.auth.user);
  const { totalUnreadCount } = useSelector((state) => state.serviceChat);
  const { updateNotificationCount } = useSelector((state) => state.spNotification);

  useEffect(() => {
    getNotification();
    if (localData) {
      setIsLogin(true);
    } else {
      navigate('/')
    }
  }, [localData, updateNotificationCount]);

  const handleClickSearch = (event) => {
    if (Boolean(anchorElSearch)) {
      setAnchorElSearch(null)
    }
    else { setAnchorElSearch(event.currentTarget); }
  };
  const handleCloseSearch = () => {
    setAnchorElSearch(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose2 = (id) => {
    setAnchorEl2(null);
    document.documentElement.scrollTop = 0;
  };

  const handleClick3 = (event) => {
    setAnchorEl3(event.currentTarget);
    setValue(0);
  };

  const handleClose3 = (id) => {
    setAnchorEl3(null);
    document.documentElement.scrollTop = 0;
  };


  const handleClickGetStart = () => {
    navigate("/login");
  };

  /**
   * function to clear Local storage
   */
  const handleLogout = () => {
    localStorage.removeItem("chatbot-thread-id");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("rememberMeToken");
    localStorage.removeItem("Diclogin");
    localStorage.removeItem("user_data_dic");
    localStorage.removeItem("user_data");
    localStorage.removeItem("token");
    localStorage.removeItem("login");
    localStorage.removeItem("kyc_modal_closed");
    localStorage.removeItem("service-provider");
    localStorage.removeItem("service-proposer");
    window.location.href = '/';
  };

  const [menuOpen, setMenuOpen] = React.useState(false);
  const [isActive, setActive] = useState(false);

  const searchToggle = () => {
    setActive(!isActive);
  };
  const toggleDrawer = (newMenuOpen) => () => {
    setMenuOpen(newMenuOpen);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  async function getNotification() {
    try {
      if (localStateData?.id) {
        const res = await GetApi(`services/getNotifications/${localStateData.id}`);
        if (res?.data?.data && res?.data?.data?.data) {
          setNotifications(res?.data?.data?.data);
          setUnreadNotifications(res?.data?.data?.unreadCount);
        }
      } else {
        showSnackbar();
      }
    } catch (error) {
      console.log(error);
    }
  }
  // Function to calculate relative time
  const formatRelativeTime = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleString("en-US", {
      month: "short",
      day: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
    return formattedDate;
  };
  useEffect(() => {
    // Api to get getNotification
    const timer = setTimeout(() => {
      getNotification();
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  // new-notification received then call
  socket.on("sp-new-notification", (data) => {
    getNotification();
  });


  /**
   * Function to update notification is_read status
   * @param {Object} value
   */
  const handleNotificationsUpdate = (value) => {
    async function updateNotification() {
      try {
        const res = await postApi(`services/updateNotification/${value.id}`);
        if (res?.data?.code === 200) {
          // getting updated data
          getNotification();
        }
      } catch (error) {
        console.log(error);
      }
    }
    if (!value.is_read) {
      updateNotification();
    }
  };


  // code for restricting Routes for the Categories
  const notificationPath = (val) => {
    switch (val.type) {
      case "000002":
        return `/service-proposer-dashboard/view-job/${val?.contract_id}?tab=3`; //navigate to contract detail delivery
      case "000003":
        return `/service-proposer-dashboard/viewprojectposting/${val?.project_id}`; // navigate to project details
      case "000005":
        return `/service-proposer-dashboard/myorder?tab=0`; // navigate to contract list active
      case "000007":
        return `/service-proposer-dashboard/view-job/${val?.contract_id}?tab=0`; //navigate to contract details activity
      case "000013":
        return `/service-proposer-dashboard/viewprojectposting/${val?.project_id}`; // navigate to project details
      case "000014":
        return `/service-proposer-dashboard/view-contract/${val?.contract_id}`; // navigate to contract offer details
      default:
        return "";
    }
  };


  const handleNotificationView = (val) => {
    navigate(`${notificationPath(val)}`, {
      state: { from: "notification", proState: "notification" },
    });
    setAnchorEl3(null);
  };


  const DrawerListAfterLogin = (
    <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
      <List>
        <ListItem>
          <Link to="/service-proposer/find-a-service-provider">
            <MenuItem
              onClick={handleClose}
              className={
                location.pathname ===
                  "/service-proposer/find-a-service-provider"
                  ? "active"
                  : ""
              }
            >
              Find Service Providers
            </MenuItem>
          </Link>
        </ListItem>
        <ListItem>
          <Box sx={{ paddingLeft: "10px" }}>
            <Link to="">
              <img src={Notification} alt="" className="header-login" />
            </Link>
          </Box>
        </ListItem>
        <ListItem>
          <Box sx={{ paddingLeft: "10px", paddingTop: "10px" }}>
            <Link to="">
              <img src={Timelapse} alt="" className="header-login" />
            </Link>
          </Box>
        </ListItem>
        <ListItem>
          <Box sx={{ paddingLeft: "10px", paddingTop: "10px" }}>
            <Link to="/service-proposer-dashboard/chats">
              <img src={Message} alt="" className="header-login" />
            </Link>
          </Box>
        </ListItem>
      </List>
    </Box>
  );

  async function getServicesData() {
    try {
      let payload = { login_user_id: localStateData?.id };
      payload = {
        ...payload,
        searchString: searchString
      }
      const res = await GetApiParam(`/services/getServices`, payload);
      setServices(res?.data?.data?.length ? res.data.data : []);
    } catch (error) {
      console.log(error);
    }
  }
  const searchProject = (e) => {
    getServicesData(e.target.value ? e.target.value : '');
  }
  const handleChangeGlobalSearch = (e, value) => {
    if (value && value?.id) navigate(`/service-proposer/provider-service/${value?.id}`);
  }
  return (
    <Box className="service-marketplace-home">
      <Box className={location?.pathname?.split('/')[1] === 'service-proposer' ? 'container' : ''}>
        <Box className={`mobile-search ${isActive ? 'open' : null}`}>
          <Box className="sp-search-box">
            <Search className="icon" />
            {/* <InputBase 
                placeholder={"Search Services"} 
                className="input" 
                value={searchString} 
                onChange={searchProject} 
                // onClick={(e) => navigate(`/service-proposer/client-category-results?search=${e.target.value}`, { state: { search: e.target.value }})}
              /> */}
            <Autocomplete
              style={{ width: 500 }}
              // freeSolo
              autoComplete
              autoHighlight
              options={services}
              getOptionLabel={(option) =>
                `${option.services_title}`
              }
              PaperComponent={CustomPaper}
              onChange={handleChangeGlobalSearch}
              noOptionsText={<span style={{ color: '#fff' }}>Search...</span>}
              renderInput={(params) => (
                <TextField {...params}
                  onChange={searchProject}
                  variant="standard"
                  placeholder={"Search Services"}
                />
              )}
            />
          </Box>
          <ClearIcon onClick={searchToggle} sx={{ cursor: 'pointer' }} />
        </Box>
        <Stack
          className="main-header"
          spacing={1}
          direction="row"
          alignItems="center"
          flexWrap="wrap"
        >
          <LogoIcon navigateHome={() => navigate("/service-proposer")} />
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            <HeaderItem text="Back" icon={<ArrowBackIos />} link={-1} />
          </Box>
          <Box sx={{ display: { xs: "none", md: "block" }, position: 'relative' }}>
            <Box className="sp-search-box">
              <Search className="icon" />
              {/* <InputBase 
                placeholder={"Search Services"} 
                className="input" 
                value={searchString} 
                onChange={searchProject}
                list="data"
                // onClick={handleClickSearch}
                // onClick={(e) => navigate(`/service-proposer/client-category-results?search=${e.target.value}`, { state: { search: e.target.value }})}
              /> */}
              <Autocomplete
                style={{ width: 500 }}
                // freeSolo
                autoComplete
                autoHighlight
                options={services}
                getOptionLabel={(option) =>
                  `${option.services_title}`
                }
                PaperComponent={CustomPaper}
                onChange={handleChangeGlobalSearch}
                noOptionsText={<span style={{ color: '#fff' }}>Search...</span>}
                renderInput={(params) => (
                  <TextField {...params}
                    onChange={searchProject}
                    variant="standard"
                    placeholder={"Search Services"}
                  />
                )}
              />
            </Box>
          </Box>
          <Box sx={{ display: { xs: "block", md: "none" }, marginLeft: "auto !important" }}>
            <Button sx={{ color: "#ffffff", minWidth: "unset" }} onClick={searchToggle} >
              <SearchRounded />
            </Button>
          </Box>
          {/* <HeaderItem text="Find Service Providers" position={'reverse'} icon={<KeyboardArrowDown />} /> */}
          <Box className="header-items">
            <Box
              className="menu-item"
              sx={{ display: { xs: "none", md: "flex" } }}
            >
              <Box
                id="fade-button"
                aria-controls={open ? "fade-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                className="header-items"
              >
                <Typography className="text">
                  {"Find Service Providers"}
                </Typography>
                <KeyboardArrowDown className="icon" />
              </Box>
              <Menu
                id="fade-menu"
                MenuListProps={{
                  "aria-labelledby": "fade-button",
                }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                className="dropdown-menu InvesterHeader-dropdown"
                mt={8}
              >
                <Link to="/service-proposer/find-a-service-provider">
                  <MenuItem
                    onClick={handleClose}
                    className={
                      location.pathname ===
                        "/service-proposer/find-a-service-provider"
                        ? "active"
                        : ""
                    }
                  >
                    Find Service Providers
                  </MenuItem>
                </Link>
              </Menu>
            </Box>
          </Box>
          {isLogin ? (
            <>
              <Box
                className="right-header-nav"
                sx={{ width: { xs: "auto", md: "100%" }, }}
              >
                <Box
                  className="provider-social-wrapper"
                  sx={{ display: { xs: "none", md: "flex" } }}
                >
                  {/* <Link to="">
                    <img src={Notification} alt="" className="header-login" />
                  </Link> */}
                  <Box className="menu-item" >
                    <IconButton
                      className="notification"
                      sx={{ ml: { xs: 1, md: 3 } }}
                      id="fade-button"
                      aria-controls={open3 ? "fade-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open3 ? "true" : undefined}
                      onClick={handleClick3}
                    // onMouseOver={handleClick2}
                    >
                      {unreadNotifications ? (
                        <Badge badgeContent={unreadNotifications} color="primary">
                          <NotificationsIcon className="header-login" />
                        </Badge>
                      ) : (
                        <NotificationsIcon className="header-login" />
                      )}
                    </IconButton>
                    {notifications?.length ? (
                      <Menu
                        id="fade-menu"
                        MenuListProps={{
                          "aria-labelledby": "fade-button",
                        }}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        anchorEl={anchorEl3}
                        open={open3}
                        onClose={handleClose3}
                        // onMouseLeave={handleClose2}
                        className="mega-menu InvesterHeader-dropdown notifications"
                        keepMounted
                      >
                        <OverlayScrollbarsComponent
                          options={{
                            scrollbars: {
                              autoHide: "scroll",
                            },
                          }}
                          style={{ width: 358, maxHeight: 280, marginRight: "5px" }}
                        >
                          <Box className="header-notifications" sx={{ width: '358px' }}>
                            <TabContext value={value}>
                              <Tabs
                                value={value}
                                variant="scrollable"
                                onChange={handleChange}
                                scrollButtons={false}
                                aria-label="scrollable prevent tabs example"
                                className="not-tabs"
                              >
                                <Tab label="Notifications" className="tab-title" />
                              </Tabs>
                              <Divider thickness={2} className="divider" />
                              <TabPanel value={value} index={0}>
                                {notifications &&
                                  notifications?.map((val, ind) => {
                                    return (
                                      <Box
                                        key={`notification ${ind + 1}`}
                                        className="notification-item"
                                        onClick={() => { handleNotificationsUpdate(val); handleNotificationView(val); }}
                                      >
                                        <Box>
                                          <Typography variant="body1" className="title">
                                            <span>
                                              {val.message}
                                            </span>
                                          </Typography>
                                          <Typography variant="caption" className="time">
                                            {formatRelativeTime(val.createdAt)}
                                          </Typography>
                                        </Box>
                                        {!val?.is_read ? (
                                          <Box>
                                            <span className="unread-icon"></span>
                                          </Box>
                                        ) : null}
                                      </Box>
                                    );
                                  })}
                              </TabPanel>
                            </TabContext>
                          </Box>
                        </OverlayScrollbarsComponent>
                      </Menu>
                    ) : null}
                  </Box>

                  <Link to="">
                    <img src={Timelapse} alt="" className="header-login" />
                  </Link>
                  <Link to="/service-proposer-dashboard/chats">
                    <img src={Message} alt="" className="header-login" />
                    <Badge
                      badgeContent={totalUnreadCount ? totalUnreadCount : 0}
                      color="error"
                      sx={{
                        transition: "opacity 0.5s ease-in",
                        opacity: 1,
                        float: "right",
                        mt: "0px",
                      }}
                    />
                  </Link>
                </Box>

                <Box sx={{ display: { xs: "block", md: "none" }, marginRight: 1.5 }}>
                  <Button
                    onClick={toggleDrawer(true)}
                    sx={{ minWidth: "auto", color: "#ffffff" }}
                  >
                    <MenuIcon />
                  </Button>
                  <Drawer open={menuOpen} onClose={toggleDrawer(false)}>
                    {DrawerListAfterLogin}
                  </Drawer>
                </Box>
                <Box className="profile-div-main ">
                  <Avatar
                    alt="Token icon"
                    src={
                      userData?.persona_picture
                        ? userData?.persona_picture
                        : User
                    }
                    sx={{ width: 40, height: 40 }}
                  />
                  <Box
                    className="profileMenu-wrap profile-dropdown-btn-space-less"
                    ml={0}
                  >
                    <Button
                      id="fade-button"
                      aria-controls={openheadermenu ? "fade-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={openheadermenu ? "true" : undefined}
                      onClick={handleClick2}
                      className="profileMenu-btn"
                    >
                      <img
                        src={custoDownArrow}
                        alt="Logo"
                        className="header-image-arrow"
                      />
                    </Button>
                    <Menu
                      id="fade-menu"
                      MenuListProps={{
                        "aria-labelledby": "fade-button",
                      }}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      anchorEl={anchorEl2}
                      open={openheadermenu}
                      onClose={handleClose2}
                      // TransitionComponent={Fade}
                      className="dropdown-menu"
                    >
                      <MenuItem
                        onClick={() => {
                          handleClose2();
                          navigate("/service-proposer-dashboard/overview");
                        }}
                      >
                        <ListItemIcon>
                          <RecentActorsIcon fontSize="small" />
                        </ListItemIcon>
                        My Projects
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          handleLogout();
                        }}
                      >
                        <ListItemIcon>
                          <Logout fontSize="small" />
                        </ListItemIcon>
                        Logout
                      </MenuItem>
                    </Menu>
                  </Box>
                </Box>
              </Box>
            </>
          ) : (
            <>
              <HeaderItem text="Sign In" link={"/login"} />
              <Box onClick={() => handleClickGetStart()}>
                <GetStartedButton />
              </Box>
            </>
          )}
        </Stack>
      </Box>
    </Box>
  );
};

export default Header;
