// payOpenFundDistribution.js

import { writeContract, waitForTransaction, prepareWriteContract } from "@wagmi/core";
import { CONTRACTS, CONTRACT_ABI } from "../../Web3/Contracts";
import { CATEGORY_TYPE_ID, INVESTMENT_TYPE_ID } from "../../constants";
import convertToDecimal from "../Common/convertToDecimal";
import getCurrencyTypeAddress from "../Common/getCurrencyTypeAddress";
import getCurrencyTypeDecimal from "../Common/getCurrencyTypeDecimal";

/**
 * Call repayment method to repay the emi
 * @param {String} address user wallet address
 * @param {Number} assetData asset's details
 * @param {Function} showSnackbar show success/failure message
 * @param {Function} handleActiveStep handle active step
 * @param {Function} handleModalClose handle modal close
 * @param {Function} handleSuccess handle confirmation of transaction success
 * @returns data or error
 */
async function payOpenFundDistribution(address, assetData, showSnackbar, handleActiveStep, handleModalClose, handleSuccess) {
    try {
        // set active step to 2
        handleActiveStep(2)

        let token_address = getCurrencyTypeAddress(assetData?.feeTokenType)
        let amount = getCurrencyTypeDecimal(assetData?.feeTokenType, assetData?.amount)
        // make offer by investor
        const { request: payDividendAmount } = await prepareWriteContract({
            address: CONTRACTS.FUNDS_DISTRIBUTION,
            abi: CONTRACT_ABI[CONTRACTS.FUNDS_DISTRIBUTION],
            account: address,
            chainId: 6806,
            functionName: "distributionAmount",
            args: [
                Number(assetData?.listing_id), // listing id / ipfs code id Integer
                token_address,
                amount // amount
            ]
        })

        const { hash } = await writeContract(payDividendAmount)
        if (hash) {
            const data = await waitForTransaction({
                hash: hash,
            })
            if (data.status === "success") {
                showSnackbar("Transaction Successful", 'success')
                handleActiveStep(3)
                setTimeout(() => {
                    handleSuccess()
                    handleModalClose();
                    handleActiveStep(-1)

                    return { data }
                }, 1000)
            } else {
                handleModalClose();
                handleActiveStep(-1)
                showSnackbar("Transaction Failed", 'error')
                return
            }
        }
    } catch (error) {
        console.error('Error fetching balance:', error);
        throw error; // propagate the error
    }
}

export { payOpenFundDistribution };