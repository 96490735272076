// dicSuggestions.js

import { writeContract, waitForTransaction, prepareWriteContract } from "@wagmi/core";
import { CONTRACTS, CONTRACT_ABI } from "../../Web3/Contracts";
import convertToDecimal from "../Common/convertToDecimal"
import { CATEGORY_TYPE_ID, INVESTMENT_TYPE_ID, CAPITAL_TYPE_ID, SUB_CATEGORY_TYPE_ID } from "../../constants";

/**
 * DIC edit deal term for assets
 * @param {String} address user wallet address
 * @param {Object} suggestedDataPayload edited data by dic
 * @param {Object} assetData asset's details
 * @param {proposalId} proposalId asset's blockchain id
 * @param {Function} showSnackbar show success/failure message
 * @param {Function} handleActiveStep handle active step
 * @param {Function} handleModalClose handle modal close
 * @param {Function} handleSuccess handle confirmation of transaction success
 * @returns data or error
 */

async function dicSuggestions(address, suggestedDataPayload, assetData, proposalId, showSnackbar, handleActiveStep, handleModalClose, handleSuccess) {
    try {

        let contract_address = CONTRACTS.CREATE_PROPOSAL_LOAN
        if (assetData?.category_id === CATEGORY_TYPE_ID.ART && assetData?.investment_type_id === INVESTMENT_TYPE_ID.SALE) {
            contract_address = CONTRACTS.CREATE_PROPOSAL_SALE
        } else if (assetData?.category_id === CATEGORY_TYPE_ID.ART && assetData?.investment_type_id === INVESTMENT_TYPE_ID.PARCEL) {
            contract_address = CONTRACTS.CREATE_PROPOSAL_PARCEL
        } else if (assetData?.investment_type_id === INVESTMENT_TYPE_ID.LOAN) {
            contract_address = CONTRACTS.CREATE_PROPOSAL_LOAN
        } else if (assetData?.category_id === CATEGORY_TYPE_ID.FUNDS && assetData?.asset_sub_category === SUB_CATEGORY_TYPE_ID.CLOSE_FUNDS) {
            contract_address = CONTRACTS.CREATE_PROPOSAL_SPACE_X_FUND
        } else if (assetData?.category_id === CATEGORY_TYPE_ID.FUNDS) {
            contract_address = CONTRACTS.CREATE_PROPOSAL_OPEN_FUND
        }

        /**
         * Common function for all category and investment
         * @param {String} functionName smart contract method name
         * @param {Object} suggestionsPayload payload for method
         * @returns 
         */
        const setSuggestions = async (functionName, suggestionsPayload) => {
            let args = [
                proposalId, // asset's blockchain id
                suggestionsPayload
            ]
            if (functionName === "setSPVSuggestion" || functionName === "setFundSuggestion") {
                args = [
                    proposalId, // asset's blockchain id
                    ...suggestionsPayload
                ]
            }

            const { request } = await prepareWriteContract({
                address: contract_address,
                abi: CONTRACT_ABI[contract_address],
                account: address,
                functionName: functionName,
                args: args
            })

            return request
        }
        if (assetData?.investment_type?.id === INVESTMENT_TYPE_ID.LOAN) {
            let minPercentage = Math.ceil(parseFloat(suggestedDataPayload?.loan_roi_from) * 100)
            let maxPercentage = Math.ceil(parseFloat(suggestedDataPayload?.loan_roi_to) * 100)
            let claim_percentage = Math.ceil(parseFloat(suggestedDataPayload?.claim_percentage * 100))
            const SuggestionCollateral = {
                projectId: Number(assetData?.id),
                assetId: Number(assetData?.assets_category?.blockchain_id), // category id- blockchain
                investmentId: Number(assetData?.investment_type?.blockchain_id), // investment id -blockchain
                tokenName: 'Loan',
                tokenSymbol: 'L',
                minAmount: convertToDecimal(suggestedDataPayload?.loan_amount_from), // from loan amount
                maxAmount: convertToDecimal(suggestedDataPayload?.loan_amount_to), // to loan amount
                minInvestment: convertToDecimal(suggestedDataPayload?.minimum_investment),
                minPercentage: Number(minPercentage), // from rate of interest
                maxPercentage: Number(maxPercentage), // to rate of interest
                minDuration: Number(suggestedDataPayload?.loan_duration_from), // from loan duration
                maxDuration: Number(suggestedDataPayload?.loan_duration_to), // to loan duration
                interestOnlyPeriod: Number(suggestedDataPayload?.loan_iop), // to loan duration
                paymentDate: Number(suggestedDataPayload?.loan_repay_day), // to loan duration
                claimedPercentage: Number(claim_percentage)
            }
            const setCollateralSuggestion = await setSuggestions('setLoanSuggestion', SuggestionCollateral);
            const { hash } = await writeContract(setCollateralSuggestion)
            if (hash) {
                const data = await waitForTransaction({ hash: hash })
                if (data.status === "success") {
                    handleActiveStep(2);
                    showSnackbar("Transaction Successful", 'success')
                    setTimeout(() => {
                        handleSuccess()
                        handleModalClose();
                        handleActiveStep(-1)
                        return { data }
                    }, 1000)
                } else {
                    handleModalClose();
                    handleActiveStep(-1)
                    showSnackbar("Transaction Failed", 'error')
                    return
                }
            }
        } else if (assetData?.investment_type?.id === INVESTMENT_TYPE_ID.SALE) {
            const SuggestionSale = {
                projectId: Number(assetData?.id),
                assetId: Number(assetData?.assets_category?.blockchain_id), // category id- blockchain
                investmentId: Number(assetData?.investment_type?.blockchain_id), // investment id -blockchain
                salePrice: convertToDecimal(suggestedDataPayload?.amount), // sale price
                depositAmount: convertToDecimal(suggestedDataPayload?.deposit_amount) // deposit amount
            }
            const setSaleSuggestion = await setSuggestions('setSaleSuggestion', SuggestionSale);
            const { hash } = await writeContract(setSaleSuggestion)
            if (hash) {
                const data = await waitForTransaction({ hash: hash })
                if (data.status === "success") {
                    handleActiveStep(2);
                    showSnackbar("Transaction Successful", 'success')
                    setTimeout(() => {
                        handleSuccess();
                        handleModalClose();
                        handleActiveStep(-1)
                        return { data }
                    }, 1000)
                } else {
                    handleModalClose();
                    handleActiveStep(-1)
                    showSnackbar("Transaction Failed", 'error')
                    return
                }
            }
        } else if (assetData?.investment_type?.id === INVESTMENT_TYPE_ID.PARCEL) {
            let claim_percentage = Math.ceil(parseFloat(suggestedDataPayload?.claim_percentage * 100))
            const SuggestionParcel = {
                projectId: Number(assetData?.id),
                assetId: Number(assetData?.assets_category?.blockchain_id), // category id- blockchain
                investmentId: Number(assetData?.investment_type?.blockchain_id), // investment id -blockchain
                saleAmount: convertToDecimal((suggestedDataPayload?.amount)), // parcel total sale price
                minInvestment: convertToDecimal((suggestedDataPayload?.minimum_investment)),// minimum investment 
                noOfParcel: Number(parseInt(suggestedDataPayload?.number_of_parcel)),// number of parcels - constant
                claimedPercentage: Number(claim_percentage ? claim_percentage : 0)
            }
            const setParcelSuggestion = await setSuggestions('setParcelSuggestion', SuggestionParcel);
            const { hash } = await writeContract(setParcelSuggestion)
            if (hash) {
                const data = await waitForTransaction({ hash: hash })
                if (data.status === "success") {
                    showSnackbar("Transaction Successful", 'success');
                    handleActiveStep(2);
                    setTimeout(() => {
                        handleSuccess();
                        handleModalClose();
                        handleActiveStep(-1)
                        return { data }
                    }, 1000)
                } else {
                    handleModalClose();
                    handleActiveStep(-1)
                    showSnackbar("Transaction Failed", 'error')
                    return
                }
            }
        } else if (assetData?.investment_type?.id === INVESTMENT_TYPE_ID.FRACTION && assetData?.assets_category?.id === CATEGORY_TYPE_ID.FUNDS) {
            let SuggestionFund = []

            if (assetData?.asset_sub_category === SUB_CATEGORY_TYPE_ID.CLOSE_FUNDS) {
                let claim_percentage = Math.ceil(parseFloat(suggestedDataPayload?.claim_percentage >= 0 ? suggestedDataPayload?.claim_percentage * 100 : assetData?.claim_percentage * 100))
                SuggestionFund = [{
                    projectId: Number(assetData?.id), // Asset id / Project id (Backend)
                    assetId: Number(assetData?.asset_sub_category_info?.blockchain_id), // Asset category id (Asset category id of blockchain)
                    investmentId: Number(assetData?.investment_type?.blockchain_id), // asset investment type id (Asset investment id of blockchain)
                    tokenName: "Space-X Fund",
                    tokenSymbol: "Space-X",

                    fundSize: convertToDecimal(suggestedDataPayload.fractionalize_total_price), // totalRaise
                    initialDuration: Number(yearsInSecond(suggestedDataPayload?.fractionalize_duration_of_project)), // 
                    investmentDuration: Number(daysInSecond(suggestedDataPayload?.investment_duration)), // 
                    managementFees: Number(Math.ceil(suggestedDataPayload?.management_fees * 100)), // 
                    managementAddress: assetData?.fund_manage_address,
                    carriedInterest: Number(Math.ceil(suggestedDataPayload?.carried_interest * 100)), // 
                    minInvestment: convertToDecimal(suggestedDataPayload.fractionalize_minimum_investment), // totalRaise
                    claimedPercentage: Number(claim_percentage),
                    tokenLockPeriod: Number(daysInSecond(suggestedDataPayload?.token_lock_period)),
                }]
                console.log(SuggestionFund);
            } else {
                let classA = '', classB = '';
                let HurdleRate = {
                    isHurdleRate: true,
                    prefferdPercentage: [],
                    LPPercentage: [],
                    GPPercentage: [],
                };
                suggestedDataPayload?.capital_info?.forEach((capitals, index) => {
                    if (index == 0) {
                        HurdleRate?.prefferdPercentage.push(Number(Math.round(parseFloat(capitals?.rate_of_return * 100))));
                        HurdleRate?.LPPercentage.push(6000);
                        HurdleRate?.GPPercentage.push(4000);
                        classA = {
                            tokenName: "Class A",
                            tokenSymbol: 'A',
                            minInvestment: convertToDecimal(capitals?.minimum_investment),
                            totalRaise: convertToDecimal(capitals?.total_raise),
                        }
                    } else if (index == 1) {
                        HurdleRate?.prefferdPercentage.push(Number(Math.round(parseFloat(capitals?.rate_of_return * 100))));
                        HurdleRate?.LPPercentage.push(7000);
                        HurdleRate?.GPPercentage.push(3000);
                        classB = {
                            tokenName: "Class B",
                            tokenSymbol: 'B',
                            minInvestment: convertToDecimal(capitals?.minimum_investment),
                            totalRaise: convertToDecimal(capitals?.total_raise),
                        }
                    }
                })
                let equityParam = [];
                if (classA && classB) {
                    equityParam = [classA, classB]
                } else if (classA) {
                    equityParam = [classA]
                } else if (classA) {
                    equityParam = [classB]
                }
                let claim_percentage = Math.ceil(parseFloat(suggestedDataPayload?.claim_percentage ? suggestedDataPayload?.claim_percentage * 100 : assetData?.claim_percentage * 100))

                SuggestionFund = [{
                    projectId: Number(assetData?.id),
                    assetId: Number(assetData?.assets_category?.blockchain_id), // category id- blockchain
                    investmentId: Number(assetData?.investment_type?.blockchain_id), // investment id -blockchain
                    totalRaise: convertToDecimal(suggestedDataPayload?.fractionalize_total_price), // total raise amount
                    duration: Number(yearsInSecond(suggestedDataPayload?.fractionalize_duration_of_project)), // duration of loan amount
                    claimedPercentage: Number(claim_percentage),
                    tokenLockPeriod: Number(daysInSecond(suggestedDataPayload?.token_lock_period)),
                    fundType: equityParam,
                    hurdleParam: HurdleRate
                }]
            }

            console.log('SuggestionFund', SuggestionFund);
            const setFundSuggestion = await setSuggestions('setFundSuggestion', SuggestionFund);
            const { hash } = await writeContract(setFundSuggestion)
            if (hash) {
                const data = await waitForTransaction({ hash: hash })
                if (data.status === "success") {
                    showSnackbar("Transaction Successful", 'success');
                    handleActiveStep(2);
                    setTimeout(() => {
                        handleSuccess();
                        handleModalClose();
                        handleActiveStep(-1)
                        return { data }
                    }, 1000)
                } else {
                    handleModalClose();
                    handleActiveStep(-1)
                    showSnackbar("Transaction Failed", 'error')
                    return
                }
            }
        } else if (assetData?.investment_type?.id === INVESTMENT_TYPE_ID.FRACTION && assetData?.assets_category?.id === CATEGORY_TYPE_ID.REAL_ESTATE) {
            let senior = suggestedDataPayload?.capital_info?.filter((items) => items?.capital_type?.id === CAPITAL_TYPE_ID.SENIOR_DEBT);
            let junior = suggestedDataPayload?.capital_info?.filter((items) => items?.capital_type?.id === CAPITAL_TYPE_ID.JUNIOR_DEBT);
            let equity = suggestedDataPayload?.capital_info?.filter((items) => items?.capital_type?.id === CAPITAL_TYPE_ID.EQUITY);

            let debtToken = [];
            let equityToken = {};
            let totalDebt = 0;

            if (senior?.length && junior?.length) {
                totalDebt = parseInt(senior[0]?.total_raise) + parseInt(junior[0]?.total_raise)
                debtToken = [
                    {
                        tokenName: "Senior Debt",
                        tokenSymbol: 'S',
                        totalRaise: convertToDecimal(senior[0]?.total_raise),
                        minInvestment: convertToDecimal(senior[0]?.minimum_investment),
                        interestOnlyPeriod: senior[0]?.iop_senior,
                        percentage: Math.ceil(parseFloat(senior[0]?.senior_ror_to * 100)),
                        duration: senior[0]?.senior_duration_to * 12
                    },
                    {
                        tokenName: "Junior Debt",
                        tokenSymbol: 'J',
                        totalRaise: convertToDecimal(junior[0]?.total_raise),
                        minInvestment: convertToDecimal(junior[0]?.minimum_investment),
                        interestOnlyPeriod: junior[0]?.iop_junior,
                        percentage: Math.ceil(parseFloat(junior[0]?.junior_ror_to * 100)),
                        duration: junior[0]?.junior_duration_to * 12
                    }
                ]
            } else if (senior?.length) {
                totalDebt = parseInt(senior[0]?.total_raise)
                debtToken = [
                    {
                        tokenName: "Senior Debt",
                        tokenSymbol: 'S',
                        totalRaise: convertToDecimal(senior[0]?.total_raise),
                        minInvestment: convertToDecimal(senior[0]?.minimum_investment),
                        interestOnlyPeriod: senior[0]?.iop_senior,
                        percentage: Math.ceil(parseFloat(senior[0]?.senior_ror_to * 100)),
                        duration: senior[0]?.senior_duration_to * 12
                    }
                ]
            } else if (junior?.length) {
                totalDebt = parseInt(junior[0]?.total_raise)
                debtToken = [
                    {
                        tokenName: "Junior Debt",
                        tokenSymbol: 'J',
                        totalRaise: convertToDecimal(junior[0]?.total_raise),
                        minInvestment: convertToDecimal(junior[0]?.minimum_investment),
                        interestOnlyPeriod: junior[0]?.iop_junior,
                        percentage: Math.ceil(parseFloat(junior[0]?.junior_ror_to * 100)),
                        duration: junior[0]?.junior_duration_to * 12
                    }
                ]
            }

            if (equity?.length) {
                equityToken =
                {
                    tokenName: "Limited Partner",
                    tokenSymbol: 'L',
                    totalRaise: convertToDecimal(equity[0]?.total_raise),
                    minInvestment: convertToDecimal(equity[0]?.minimum_investment),
                    equityPercentage: Number(Math.ceil(parseFloat(equity[0]?.equity * 100))),
                    cashOnYield: 24
                }
            }

            let HurdleRate = {
                minIRRPercent: [0, 700, 1200],
                maxIRRPercent: [700, 1200, 10000],
                partners: [
                    [10000, 0],
                    [7500, 2500],
                    [6000, 4000]
                ],
                carriedPercentage: 3500,
                gpPercentage: 2000,
                isHurdleRate: true
            }

            const SuggestionSPV = [{
                projectId: Number(assetData?.id),
                assetId: Number(assetData?.assets_category?.blockchain_id), // category id- blockchain
                investmentId: Number(assetData?.investment_type?.blockchain_id), // investment id -blockchain
                totalCapital: convertToDecimal(suggestedDataPayload?.fractionalize_project_size), // project size 
                totalRaise: convertToDecimal(suggestedDataPayload?.fractionalize_total_price), // total raise amount
                totalDebtAmt: convertToDecimal(totalDebt),
                totalEquityAmt: convertToDecimal(equity[0]?.total_raise ? equity[0]?.total_raise : 0),
                proposerPercentage: Number(Math.ceil(parseFloat((100 - equity[0]?.equity) * 100))),
                equityPercentage: Number(Math.ceil(parseFloat((equity[0]?.equity) * 100))),
                paymentDate: Number(assetData?.loan_repay_day),
                claimedPercentage: Number(0 * 100),
                projectDuration: Number(suggestedDataPayload?.fractionalize_duration_of_project * 12), // duration of loan amount,
                debtToken,
                equityToken,
                hurdleParam: HurdleRate
            },
            ]

            const setSPVSuggestion = await setSuggestions('setSPVSuggestion', SuggestionSPV);
            const { hash } = await writeContract(setSPVSuggestion)
            if (hash) {
                const data = await waitForTransaction({ hash: hash })
                if (data.status === "success") {
                    showSnackbar("Transaction Successful", 'success');
                    handleActiveStep(2);
                    setTimeout(() => {
                        handleSuccess();
                        handleModalClose();
                        handleActiveStep(-1)
                        return { data }
                    }, 1000)
                } else {
                    handleModalClose();
                    handleActiveStep(-1)
                    showSnackbar("Transaction Failed", 'error')
                    return
                }
            }
        }
    } catch (error) {
        console.error('Error fetching balance:', error);
        throw error; // propagate the error
    }
}

export { dicSuggestions };

const second = 86400;

const yearsInSecond = (years) => {
    return parseInt(years) * second * 365
}
const monthsInSecond = (month) => {
    return parseInt(month) * second * 30
}
const daysInSecond = (day) => {
    return parseInt(day) * second
}