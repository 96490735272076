import React, { useCallback, useEffect, useState, useRef } from "react";
import { Avatar, Box, Card, CardHeader, Grid, Modal, Typography, Stack, IconButton, useMediaQuery, Backdrop, CircularProgress } from "@mui/material";
import UploadIcon from "@mui/icons-material/Upload";
import DeleteIcon from "@mui/icons-material/Delete";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import AssignmentIcon from "@mui/icons-material/Assignment";
import FileViewer from "react-file-viewer";
import { deleteApi, GetApi, postApi } from "../Api/Api";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { useSnackbar } from "../Contexts/SnackbarContext";
import { useDropzone } from "react-dropzone";
import { ArrowDropDown, Edit } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { GetApiParam, updateApi } from '../Api/Api';
import DropdownBox from '../InvestorOnly/Component/DropdownBox'
import { updateStep } from "../features/auth/tooltipSlice";
import { CATEGORY_TYPE_ID } from "../constants";

export default function Documents({ documentsData, descriptionData, ownerId, assetId, editable, projectStatus, section_title, document_name, investment_type_id, selected_templates, isLoan, isParcel, isSale, isFraction, isFundFraction }) {
  const { showSnackbar } = useSnackbar();
  const [showMore, setShowMore] = useState(false);
  const isXs = useMediaQuery('(max-width:600px)');
  const step = useSelector((state) => {
    if (isLoan) {
      return state.tooltipSlice.loan_step_number
    } else if (isParcel) {
      return state.tooltipSlice.parcel_step_number
    } else if (isSale) {
      return state.tooltipSlice.sale_step_number
    } else if (isFraction) {
      return state.tooltipSlice.re_step_number
    } else if (isFundFraction) {
      return state.tooltipSlice.funds_step_number
    }
  }
  );
  const [tolPosition, setTolPosition] = useState(step ? step : 0)

  const isActive = useSelector((state) => state.tooltipSlice.active_how_it_work);

  const [loading, setLoading] = useState(false);
  const [loadingTooltip, setLoadingToolTip] = useState(false);
  const [loadingApi, setLoadingApi] = useState(false);


  const navigate = useNavigate()
  const dispatch = useDispatch()

  // const localData =
  const localData = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : ""; //Getting  user data from local storage

  // Merge the two arrays
  let mergedArray = documentsData?.length ? documentsData : [];
  if (descriptionData?.length > 0) {
    console.log("descriptionData...", descriptionData)
    const documentOnly = descriptionData.filter(item => item?.images && item?.type === "document" && item?.images?.type !== 'mp4');
    if (documentOnly?.length > 0) {
      mergedArray = mergedArray.concat(documentOnly);
    }
  }

  const [view, setView] = useState(false);
  const [file, setFile] = useState("");
  const [type, setType] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [data, setData] = useState(mergedArray);

  /**
   * onClick function for viewing document
    @param {} documentData
   */
  const viewDoc = async (documentData) => {
    if (documentData?.images?.path) {
      const fileName = await fetch(`${process.env.REACT_APP_IMAGE_URL}${documentData.images.path}`);

      const templateBuffer = await fileName.arrayBuffer();
      const mimeType = 'application/pdf'; // Since viewDoc is only for PDFs

      const templateBlob = new Blob([templateBuffer], { type: mimeType });
      const templateUrl = URL.createObjectURL(templateBlob);

      setFile(templateUrl);
      setType(documentData.images.type);
      setView(true);
      setOpenModal(true);
    }
  };

  const downloadDoc = async (documentData) => {
    if (documentData?.images?.path) {
      try {
        // Fetch the file data
        const response = await fetch(documentData.images.path);
        
        if (!response.ok) {
          throw new Error(`Failed to fetch file: ${response.statusText}`);
        }
  
        // Get the file data as an ArrayBuffer
        const fileBuffer = await response.arrayBuffer();
  
        // Determine the MIME type and file extension based on the file type
        let mimeType = '';
        let extension = '';
  
        switch (documentData.images.type) {
          // Document types
          case 'application/pdf':
            mimeType = 'application/pdf';
            extension = '.pdf';
            break;
          case 'application/msword':
            mimeType = 'application/msword';
            extension = '.doc';
            break;
          case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
          case 'vnd.openxmlformats-officedocument.wordprocessingml.document': // Added case for your MIME type
            mimeType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
            extension = '.docx';
            break;
          case 'application/vnd.ms-excel':
            mimeType = 'application/vnd.ms-excel';
            extension = '.xls';
            break;
          case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
          case 'vnd.openxmlformats-officedocument.spreadsheetml.sheet': // Added case for your MIME type
            mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            extension = '.xlsx';
            break;
          case 'application/vnd.ms-powerpoint':
            mimeType = 'application/vnd.ms-powerpoint';
            extension = '.ppt';
            break;
          case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
            mimeType = 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
            extension = '.pptx';
            break;
          case 'application/rtf':
            mimeType = 'application/rtf';
            extension = '.rtf';
            break;
          case 'application/vnd.oasis.opendocument.text':
            mimeType = 'application/vnd.oasis.opendocument.text';
            extension = '.odt';
            break;
          case 'application/vnd.oasis.opendocument.spreadsheet':
            mimeType = 'application/vnd.oasis.opendocument.spreadsheet';
            extension = '.ods';
            break;
          case 'application/vnd.oasis.opendocument.presentation':
            mimeType = 'application/vnd.oasis.opendocument.presentation';
            extension = '.odp';
  
          // Archive types
          case 'application/zip':
            mimeType = 'application/zip';
            extension = '.zip';
            break;
          case 'application/x-rar-compressed':
            mimeType = 'application/x-rar-compressed';
            extension = '.rar';
            break;
          case 'application/x-tar':
            mimeType = 'application/x-tar';
            extension = '.tar';
            break;
          case 'application/gzip':
            mimeType = 'application/gzip';
            extension = '.gz';
            break;
          case 'application/x-7z-compressed':
            mimeType = 'application/x-7z-compressed';
            extension = '.7z';
  
          default:
            // Debugging: Log unsupported MIME types
            console.error('Unsupported MIME type:', documentData.images.type);
            throw new Error('Unsupported file type');
        }
  
        // Create a Blob from the file data
        const fileBlob = new Blob([fileBuffer], { type: mimeType });
        const fileUrl = URL.createObjectURL(fileBlob);
  
        // Create a link element, set its attributes, and simulate a click to download the file
        const link = document.createElement('a');
        link.href = fileUrl;
        link.download = documentData.images.original_name || `document${extension}`; // Use original name or fallback
        document.body.appendChild(link);
        link.click();
  
        // Cleanup
        document.body.removeChild(link);
        URL.revokeObjectURL(fileUrl);
      } catch (error) {
        console.error('Error downloading file:', error);
      }
    }
  };
  
  
  
  


  useEffect(() => {
    setData(mergedArray);
  }, [documentsData, descriptionData]);

  useEffect(() => {
  }, [data])

  // Delete functionality for the document
  const handleDelete = async (deleteVal) => {
    const res = await deleteApi(`/proposer/assetResources/${deleteVal.id}`);
    if (res?.data.code === 200) {
      showSnackbar("Document deleted successfully", "success");
      try {
        const res = await GetApi(`/proposer/getAssetDoc/${assetId}`);
        if (res.data.data) {
          let signingDoc = res?.data?.data?.asset_document;
          if (document_name === 'asset_signing_document') {
            if (signingDoc?.length) {
              signingDoc = signingDoc?.filter((items) => items.title === 'asset_signing_document')
              setData(signingDoc);
            } else {
              setData([])
            }
          } else {
            if (signingDoc?.length) {
              signingDoc = signingDoc?.filter((items) => items.title !== 'asset_signing_document' && items?.images && items?.type === "document" && items?.images?.type !== 'mp4')
              setData(signingDoc);
            } else {
              setData([])
            }
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  /**
   * Onclose function for Modal
   * @returns void
   */
  const handleClose = () => {
    setOpenModal(false);
    setView(false);
  };

  const styleModal = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };

  // handle uploaded docs
  const onDrop = useCallback(
    async (acceptedFiles) => {
      /**
       * Applying filter for pdf fileType only.
       */
      // const pdfFilesArray = acceptedFiles.filter(
      //   (file) => file.type === "application/pdf"
      // );
      const pdfFilesArray = acceptedFiles.filter(
        (file) => file.type === "application/pdf" ||
          file.type === "application/msword" ||
          file.type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
          file.type === "application/vnd.ms-excel" ||
          file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      );

      /**
       * Uploading documents trough looping (using promise.all)
       */
      const uploadPromises = pdfFilesArray.map(async (file, index) => {
        const uploadDocData = new FormData();
        uploadDocData.append(document_name, file);
        uploadDocData.append("asset_id", assetId);
        uploadDocData.append("user_id", localData?.id);
        uploadDocData.append('type', 'document_updated')
        try {
          return postApi(`/proposer/assetResources/`, uploadDocData);
        } catch (error) {
          console.error("Error uploading document or fetching data:", error);
        }
      });

      /**
       * After full filling all the promise data calling api
       */
      try {
        const results = await Promise.all(uploadPromises);
        if (results) {
          const res = await GetApi(`/proposer/getAssetDoc/${assetId}`);
          // Assuming that successfulResults is an array of data from successful uploads
          /**
           * Setting data into data variable for documents
           */
          showSnackbar("Document added successfully", "success");
          if (res.data.data) {
            let signingDoc = res?.data?.data?.asset_document;
            if (document_name === 'asset_signing_document') {
              if (signingDoc?.length) {
                signingDoc = signingDoc?.filter((items) => items.title === 'asset_signing_document')
                setData(signingDoc);
              } else {
                setData([])
              }
            } else {
              if (signingDoc?.length) {
                signingDoc = signingDoc?.filter((items) => items.title !== 'asset_signing_document' && items?.images && items?.type === "document" && items?.images?.type !== 'mp4')
                setData(signingDoc);
              } else {
                setData([])
              }
            }
          }
        }
      } catch (error) {
        console.error("Error in at least one upload or fetching data:", error);
      }
    },
    [data]
  );

  /**
   * Uploading pdf file document using useDropzone
   */
  const { getRootProps, open, getInputProps } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: {
      "application/pdf": [".pdf"],
      "application/msword": [".doc"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
      "application/vnd.ms-excel": [".xls"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
    },
    onDrop,
  });

  // Helper function to truncate a string and add an ellipsis if it's too long
  const truncateString = (str, maxLength) => {
    if (str.length > maxLength) {
      return str.substring(0, maxLength) + "...";
    }
    return str;
  };

  // handle show more button
  const handleShowMore = () => {
    setShowMore(true);
  }

  const handleDocumentUpload = () => {
    const data = {
      "investment_type_id": investment_type_id,
      "asset_signing_document": documentsData,
      "selected_templates": selected_templates,
      "assetId": assetId,
      // Add more data as needed
    };
    if (isLoan) {
      data.category_id = CATEGORY_TYPE_ID.WATCH
    } else if (isParcel) {
      data.category_id = CATEGORY_TYPE_ID.ART
    } else if (isSale) {
      data.category_id = CATEGORY_TYPE_ID.ART
    } else if (isFraction) {
      data.category_id = CATEGORY_TYPE_ID.REAL_ESTATE
    } else if (isFundFraction) {
      data.category_id = CATEGORY_TYPE_ID.FUNDS
    }

    navigate('/upload-agreement-documents', { state: data })
  }


  useEffect(() => {
    if ((tolPosition > 14 || tolPosition <= 13) && isLoan) {
      setLoadingToolTip(false)
    } else if ((tolPosition > 10 || tolPosition <= 9) && isParcel) {
      setLoadingToolTip(false)
    } else if ((tolPosition > 9 || tolPosition <= 8) && isSale) {
      setLoadingToolTip(false)
    } else if ((tolPosition > 12 || tolPosition <= 11) && isFraction) {
      setLoadingToolTip(false)
    } else if ((tolPosition > 10 || tolPosition <= 9) && isFundFraction) {
      setLoadingToolTip(false)
    }
  }, [tolPosition])

  useEffect(() => {
    async function getStep() {
      try {
        setLoadingApi(true)
        const data = await GetApiParam("/user/currentTooltipPosition", { "user_id": localData.id });
        if (data?.data?.data && isActive) {
          if (data.data?.data?.loan_step_number == 14 && isLoan) {
            setTolPosition(data.data?.data?.loan_step_number == null ? 0 : data.data.data.loan_step_number)
            setLoadingToolTip(true)
            scrollToPosition("scroll-to-document");
          } else if ((data.data?.data?.parcel_step_number == 10) && isParcel) {
            setTolPosition(data.data?.data?.parcel_step_number == null ? 0 : data.data.data.parcel_step_number)
            setLoadingToolTip(true)
            scrollToPosition("scroll-to-document");
          }
          else if ((data.data?.data?.sale_step_number == 9) && isSale) {
            setTolPosition(data.data?.data?.sale_step_number == null ? 0 : data.data.data.sale_step_number)
            setLoadingToolTip(true)
            scrollToPosition("scroll-to-document");
          }
          else if ((data.data?.data?.re_step_number == 12) && isFraction) {
            setTolPosition(data.data?.data?.re_step_number == null ? 0 : data.data.data.re_step_number)
            setLoadingToolTip(true)
            scrollToPosition("scroll-to-document");
          } else if ((data.data?.data?.funds_step_number == 10) && isFundFraction) {
            setTolPosition(data.data?.data?.funds_step_number == null ? 0 : data.data.data.funds_step_number)
            setLoadingToolTip(true)
            scrollToPosition("scroll-to-document");
          } else {
            setLoadingToolTip(false)
            setTolPosition(0)
          }
        }
        setLoadingApi(false)
      } catch (error) {
        console.log('error', error)
        setLoadingApi(false)
      }
    }
    // Call getStep initially
    const timer = setTimeout(() => {
      if (localData && localData?.id && isActive) {
        getStep();
      }
    }, 100);
    return () => clearTimeout(timer)
  }, [step, isActive])

  async function setStep(tolPositionData) {

    try {
      let body = { "loan_step_number": tolPositionData };
      if (isParcel) {
        body = { "parcel_step_number": tolPositionData };
      } else if (isSale) {
        body = { "sale_step_number": tolPositionData };
      } else if (isFraction) {
        body = { "re_step_number": tolPositionData };
      } else if (isFundFraction) {
        body = { "funds_step_number": tolPositionData };
      }
      const data = await updateApi("/user/updateUser/" + localData?.id, body);
      if (data?.data?.data) {
        if (data.data?.data?.loan_step_number == 14 && isLoan) {
          setTolPosition(data.data?.data?.loan_step_number == null ? 0 : data.data.data.loan_step_number)
          setLoadingToolTip(true)
        } else if ((data.data?.data?.parcel_step_number == 10) && isParcel) {
          setTolPosition(data.data?.data?.parcel_step_number == null ? 0 : data.data.data.parcel_step_number)
          setLoadingToolTip(true)
        }
        else if ((data.data?.data?.sale_step_number == 9) && isSale) {
          setTolPosition(data.data?.data?.sale_step_number == null ? 0 : data.data.data.sale_step_number)
          setLoadingToolTip(true)
        }
        else if ((data.data?.data?.re_step_number == 12) && isFraction) {
          setTolPosition(data.data?.data?.re_step_number == null ? 0 : data.data.data.re_step_number)
          setLoadingToolTip(true)
        } else if ((data.data?.data?.funds_step_number == 10) && isFundFraction) {
          console.log(data.data.data);
          setTolPosition(data.data?.data?.funds_step_number == null ? 0 : data.data.data.funds_step_number)
          setLoadingToolTip(true)
        }
      }
    } catch (error) {
      console.log('error', error)
    }
  }
  const scrollToPosition = (findId) => {
    setTimeout(() => {
      const element = document.getElementById(`${findId}`);
      if (element) {
        const offset = 0;
        const bodyRect = document.body.getBoundingClientRect().top;
        const elementRect = element.getBoundingClientRect().top;
        const elementPosition = elementRect - bodyRect;
        const offsetPosition = elementPosition - offset;

        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth"
        });
      }
    }, 200);
  }

  const handleTolPosition = (data) => {
    setStep(tolPosition + data)

    if (isLoan)
      dispatch(updateStep({ key: 'loan_step_number', value: tolPosition + data }));
    if (isSale)
      dispatch(updateStep({ key: 'sale_step_number', value: tolPosition + data }));
    if (isParcel)
      dispatch(updateStep({ key: 'parcel_step_number', value: tolPosition + data }));
    if (isFraction)
      dispatch(updateStep({ key: 're_step_number', value: tolPosition + data }));
    if (isFundFraction)
      dispatch(updateStep({ key: 'funds_step_number', value: tolPosition + data }));

    if (data < 0) {
      scrollToPosition("scroll-to-dealterm");
    } else {
      scrollToPosition("scroll-to-template");
    }
    setTolPosition(pre => pre + data)
  }

  return (
    <>
      {loadingTooltip && document_name !== "asset_signing_document" ?
        <Box className="pd-sidebar-documents" id="scroll-to-document" mb={3} sx={{
          zIndex: 98, position: 'relative', backgroundColor: "#06487E",
          color: "white", borderRadius: "8px", zIndex: 98,
          padding: '4px'
        }}>
          <DropdownBox render={
            <Box >
              <Stack
                className="pd-sidebar-heading"
                direction="row"
                spacing={1}
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography className="title" component={"h4"}>
                  {section_title}
                </Typography>

                {/* Conditionally rendering upload functionality for proposer only*/}
                {(editable && localData?.id && !projectStatus && section_title !== "Agreement Documents") ? (
                  <Box
                    {...getRootProps({
                      className: "dropzone",
                    })}
                  >
                    <input {...getInputProps()} />
                    <Box className="action">
                      <IconButton aria-label="Uplaod" size="large">
                        <UploadIcon className="text-white" onClick={open} />
                      </IconButton>
                    </Box>
                  </Box>
                ) : (editable && localData?.id && !projectStatus && section_title === "Agreement Documents") && (
                  <Box>
                    <Box className="action">
                      <IconButton aria-label="Uplaod" size="large">
                        <Edit className="text-white" onClick={handleDocumentUpload} />
                      </IconButton>
                    </Box>
                  </Box>
                )}
              </Stack>

              {/* Dynamically rendering documents with viewer  */}

              {data && data.length > 0 ? (<>
                {
                  data?.map((val, ind) => {
                    if (ind < 5 || showMore) {
                      const subheaderText = val?.images?.original_name
                        ? val.images.original_name
                        : val.name;
                      const truncatedSubheader = truncateString(subheaderText, 18); // You can change 30 to your desired length
                      return (
                        <Card className="pd-action-card" key={ind}>
                          <CardHeader
                            avatar={
                              <Avatar
                                aria-label="recipe"
                                className="bg-blue-900"
                                variant="square"
                                sx={{
                                  width: "48px",
                                  height: "48px",
                                  borderRadius: "12px",
                                }}
                              >
                                <AssignmentIcon />
                              </Avatar>
                            }
                            action={
                              <>
                                <Box className="btn-wrap">
                                  <Stack
                                    direction="row"
                                    spacing={1.5}
                                    alignItems={"center"}
                                  >
                                    {editable && localData?.id && !projectStatus && section_title !== "Agreement Documents" ?
                                      <IconButton
                                        aria-label="Edit"
                                        size="large"
                                        className="square-icon-btn"
                                        onClick={() => handleDelete(val)}
                                      >
                                        <DeleteIcon />
                                      </IconButton> : ""
                                    }
                                    {val?.images?.type === "pdf" ? (
                                      <IconButton
                                        aria-label="View"
                                        size="large"
                                        className="square-icon-btn"
                                        onClick={() => viewDoc(val)}
                                      >
                                        <RemoveRedEyeIcon />
                                      </IconButton>
                                    ) : (
                                      <IconButton
                                        aria-label="Download"
                                        size="large"
                                        className="square-icon-btn"
                                        onClick={() => downloadDoc(val)}
                                      >
                                        <DownloadForOfflineIcon />
                                      </IconButton>
                                    )}
                                  </Stack>
                                </Box>
                              </>
                            }
                            title={truncatedSubheader}
                            subheader={val.type}
                          />
                        </Card>
                      );
                    } else {
                      return null
                    }
                  })
                }
                {(showMore === false && data?.length > 5) &&
                  <Stack flexDirection={'row'} justifyContent={'flex-end'} mt={2}>
                    <Stack flexDirection={'row'} pt={0.2} >
                      <Typography>More</Typography>
                    </Stack>
                    <Box sx={{ cursor: 'pointer' }} onClick={handleShowMore}>
                      <ArrowDropDown />
                    </Box>
                  </Stack>
                }
              </>
              ) : (
                <Typography textAlign={"center"}>
                  No documents available to view
                </Typography>
              )}

            </Box>
          }
            setState={handleTolPosition} description={"The agreements for the deal are available for preview, RACE strongly advises to read all agreements before making an offer"} position={"left"}
          />
        </Box>
        :

        <Box className="pd-sidebar-documents" id="scroll-to-document" mb={3}>
          <Stack
            className="pd-sidebar-heading"
            direction="row"
            spacing={1}
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography className="title" component={"h4"}>
              {section_title}
            </Typography>

            {/* Conditionally rendering upload functionality for proposer only*/}
            {(editable && localData?.id && !projectStatus && section_title !== "Agreement Documents") ? (
              <Box
                {...getRootProps({
                  className: "dropzone",
                })}
              >
                <input {...getInputProps()} />
                <Box className="action">
                  <IconButton aria-label="Uplaod" size="large">
                    <UploadIcon className="text-white" onClick={open} />
                  </IconButton>
                </Box>
              </Box>
            ) : (editable && localData?.id && !projectStatus && section_title === "Agreement Documents") && (
              <Box>
                <Box className="action">
                  <IconButton aria-label="Uplaod" size="large">
                    <Edit className="text-white" onClick={handleDocumentUpload} />
                  </IconButton>
                </Box>
              </Box>
            )}
          </Stack>

          {/* Dynamically rendering documents with viewer  */}

          {data && data.length > 0 ? (<>
            {
              data?.map((val, ind) => {
                if (ind < 5 || showMore) {
                  const subheaderText = val?.images?.original_name
                    ? val.images.original_name
                    : val.name;
                  const truncatedSubheader = truncateString(subheaderText, 18); // You can change 30 to your desired length
                  return (
                    <Card className="pd-action-card" key={ind}>
                      <CardHeader
                        avatar={
                          <Avatar
                            aria-label="recipe"
                            className="bg-blue-900"
                            variant="square"
                            sx={{
                              width: "48px",
                              height: "48px",
                              borderRadius: "12px",
                            }}
                          >
                            <AssignmentIcon />
                          </Avatar>
                        }
                        action={
                          <>
                            <Box className="btn-wrap">
                              <Stack
                                direction="row"
                                spacing={1.5}
                                alignItems={"center"}
                              >
                                {editable && localData?.id && !projectStatus && section_title !== "Agreement Documents" ?
                                  <IconButton
                                    aria-label="Edit"
                                    size="large"
                                    className="square-icon-btn"
                                    onClick={() => handleDelete(val)}
                                  >
                                    <DeleteIcon />
                                  </IconButton> : ""
                                }
                                {val?.images?.type === "pdf" ? (
                                  <IconButton
                                    aria-label="View"
                                    size="large"
                                    className="square-icon-btn"
                                    onClick={() => viewDoc(val)}
                                  >
                                    <RemoveRedEyeIcon />
                                  </IconButton>
                                ) : (
                                  <IconButton
                                    aria-label="Download"
                                    size="large"
                                    className="square-icon-btn"
                                    onClick={() => downloadDoc(val)}
                                  >
                                    <DownloadForOfflineIcon />
                                  </IconButton>
                                )}
                              </Stack>
                            </Box>
                          </>
                        }
                        title={truncatedSubheader}
                        subheader={val.type}
                      />
                    </Card>
                  );
                } else {
                  return null
                }
              })
            }
            {(showMore === false && data?.length > 5) &&
              <Stack flexDirection={'row'} justifyContent={'flex-end'} mt={2}>
                <Stack flexDirection={'row'} pt={0.2} >
                  <Typography>More</Typography>
                </Stack>
                <Box sx={{ cursor: 'pointer' }} onClick={handleShowMore}>
                  <ArrowDropDown />
                </Box>
              </Stack>
            }
          </>
          ) : (
            <Typography textAlign={"center"}>
              No documents available to view
            </Typography>
          )}
        </Box>
      }

      <Grid>
        {/* {view && (
          <Modal
            className="projectDocumentsModal"
            open={openModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            fullWidth={true}
          >
            <Box sx={styleModal}>
              <OverlayScrollbarsComponent
                defer
                style={{ width: "100%", height: "100%" }}
              >
                <Box className="raj">
                  {<FileViewer fileType={type} filePath={file} />}
                </Box>
              </OverlayScrollbarsComponent>
            </Box>
          </Modal>
        )} */}
        {(view && !loading && type && file) && (
          <Modal
            className="projectDocumentsModal"
            open={openModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            fullWidth={true}
          >
            <Box sx={styleModal}>
              {type?.includes("pdf") ? (
                <object
                  data={file}
                  type="application/pdf"
                  style={{ width: '100%', height: '100%', borderRadius: '12px' }}
                >
                  <p style={{ textAlign: 'center' }}>
                    Your browser does not support viewing PDFs.{' '}
                    <a href={file} target="_blank" style={{ color: 'lightgreen' }}>
                      Download the PDF
                    </a>.
                  </p>
                </object>
              ) : type?.includes("wordprocessingml.document") ? (
                <div style={{ textAlign: 'center', padding: '20px' }}>
                  <p>Your browser does not support viewing Word documents.</p>
                  <a href={URL.createObjectURL(new Blob([file], { type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document" }))} download="document.docx" style={{ color: 'lightgreen' }}>
                    Download the Word document
                  </a>
                </div>
              ) : type?.includes("spreadsheetml.sheet") ? (
                <div style={{ textAlign: 'center', padding: '20px' }}>
                  <p>Your browser does not support viewing Excel documents.</p>
                  <a href={URL.createObjectURL(new Blob([file], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }))} download="document.xlsx" style={{ color: 'lightgreen' }}>
                    Download the Excel document
                  </a>
                </div>
              ) : (
                <p>Unsupported file type.</p>
              )}
            </Box>

          </Modal>
        )}
      </Grid>
      {/* {loading &&
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => 97 }}
          open={loading}
        >
        </Backdrop>
      } */}

      {loadingTooltip &&
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => 97 }}
          open={loadingTooltip}
        >
        </Backdrop>
      }
      {loadingApi &&
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => 97 }}
          open={loadingApi}
        >
          <CircularProgress />
        </Backdrop>
      }
    </>
  );
}
