import React, { useState, useEffect, useRef } from 'react'
import { Chip, Box, Typography, Button, Stack, IconButton, Table, TableBody, TableContainer, TableHead, TableRow, Paper, styled, TableCell, tableCellClasses, Grid, Modal, Backdrop, CircularProgress, Avatar } from '@mui/material';
import { RemoveRedEye, Close, ArrowBack } from '@mui/icons-material';
import { CATEGORY_TYPE_ID, DOCUMENT_SIGN_STATUS, INVESTMENT_TYPE_ID, SUB_CATEGORY_TYPE_ID } from '../../constants.js'
import { GetApi, GetApiParam, postApi } from '../../Api/Api.js';
import { NewLogo } from '../../Components/Images/Images.js';
import { useSnackbar } from '../../Contexts/SnackbarContext.jsx';
import { formatNumber } from '../../Components/Common/USFormat.js';
import convertToOneDecimal from '../../Components/Common/convertToOneDecimal.js';
import "./Investor.css"
import { repaymentConfigDetail } from '../../Components/SmartContract/repaymentConfigDetail.js';

// Styles for table columns
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#05131B",
    color: "#94A3B8",
    borderBottom: '0px',
    '&:first-of-type': {
      borderRadius: '16px 0px 0px 16px',
    },
    '&:last-of-type': {
      borderRadius: '0px 16px 16px 0px',
    },
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: theme.palette.common.white,
    borderBottom: "0px",
  },
}));

// Styles for table rows
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#032744",
    color: "#FFFFFF",
    borderBottom: "0px",
  },
  "&:nth-of-type(even)": {
    backgroundColor: "#032744",
    color: "#FFFFFF",
    borderBottom: "0px",
  },
}));

// setting document signed status
const DOCUMENT_SIGN_STATUS_TEXT = {
  [DOCUMENT_SIGN_STATUS.PENDING]: <Chip sx={{ marginTop: 2, marginBottom: 2, color: "#13202D", bgcolor: "#F6CFB7", borderRadius: "8px", }} label={'Pending Signature'} />,
  [DOCUMENT_SIGN_STATUS.SIGNED]: <Chip sx={{ marginTop: 2, marginBottom: 2, color: "#13202D", bgcolor: "#34D399", borderRadius: "8px", }} label={'Completed'} />,
  [DOCUMENT_SIGN_STATUS.REJECTED]: <Chip sx={{ marginTop: 2, marginBottom: 2, color: "#13202D", bgcolor: "#EF4444", borderRadius: "8px", }} label={'Rejected'} />,
}

// modal styles
const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: '550px ',
  height: '85%',
  maxHeight: '450px'
};


export default function SignDocuments({ amountToPay, loanROI, loanDuration, signDocs, setSignDocs, assetData, handleSignedDoc, offerId, parcel_ids, offerData, paymentMethod }) {

  // fetching data from local storage
  const localData = localStorage.getItem("user_data") ? JSON.parse(localStorage.getItem("user_data")) : "";

  // initialize document with default data
  const [templatesData, setTemplatesData] = useState([]);
  const [envelopeId, setEnvelopeId] = useState();
  const [view, setView] = useState(false);
  const [file, setFile] = useState("");
  const [type, setType] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [openModalView, setOpenModalView] = useState(false);
  const [loading, setLoading] = useState(true)
  const [viewedTemplates, setViewedTemplates] = useState([]);
  const [signingParcelTemplate, setSigningParcelTemplate] = useState([]);
  const { showSnackbar } = useSnackbar();
  const intervalIdRef = useRef(null);

  /**
   * handle back arrow(button) click
   */
  const handleBackBtn = () => {
    handleSignedDoc([{ status: 1 }])
    setSignDocs({ ...signDocs, open: false });
  };

  useEffect(() => {
    if (signDocs?.parcel_buy || signDocs?.equity || signDocs?.class) {
      async function getSigningParcelTemplate() {
        try {
          let param = {}
          if (signDocs?.class) {
            param = { asset_id: assetData?.id, class: signDocs?.class }
          } else if (signDocs?.equity) {
            param = { asset_id: assetData?.id, capital_type_id: signDocs?.capital_type_id }
          } else {
            param = { asset_id: assetData?.id }
          }
          const res = await GetApiParam("/docusign/getAllSigningDocument", param);
          if (res?.data?.data) {
            let data = res.data?.data;
            let templateArr = [];
            for (let i = 0; i < data.length; i++) {
              templateArr.push({ ...data[i], name: assetData?.template_data[0].template_ids[0].name, templateId: assetData?.template_data[0].template_ids[0].templateId, status: data[i]?.proposer_sign && data[i]?.investor_sign ? 2 : 1 })
            }
            setSigningParcelTemplate(templateArr);
            setTemplatesData(templateArr)
            handleSignedDoc(templateArr)
          }
        } catch (error) {
          console.log(error)
        }
      }
      getSigningParcelTemplate()
    } else {
      setTemplatesData(assetData?.template_data?.length ? assetData?.template_data[0].template_ids : [])
    }
  }, [])

  useEffect(() => {
    if (signDocs?.fundsBeforeSinging) {
      handleSignedDoc(assetData?.template_data?.length ? assetData?.template_data[0].template_ids : [])
    }
  }, [])
  /**
  * here we set documents list
  */
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    async function checkTemplatesStatus() {
      let parcel_id = []
      if (parcel_ids) {
        for (let i = 0; i < parcel_ids.length; i++) {
          let parcelNo = parcel_ids[i].title.split('#')[1];
          parcel_id.push(parcelNo)
        }
      }
      try {
        if (assetData?.template_data?.length && assetData?.template_data[0]?.template_ids) {

          let templatesData = assetData?.template_data[0].template_ids;
          for (const templatesItem of templatesData) {
            let queryData = '';
            if (signDocs.navigateBack) {
              queryData = `asset_id=${assetData.id}&proposer_id=${assetData.user_id}&investor_id=${localData?.id}&parcel_ids=${parcel_id}&template_ids=${templatesItem?.templateId}`
            } else if (signDocs?.from === "offer-page") {
              queryData = `proposer_id=${localData?.id}&asset_id=${assetData.id}&offer_id=${offerId}&parcel_ids=${parcel_id}&template_ids=${templatesItem?.templateId}`
            } else if (signDocs?.is_direct_buy) {
              queryData = `proposer_id=${localData?.id}&asset_id=${assetData.id}&direct_buy=1&parcel_ids=${parcel_id}&template_ids=${templatesItem?.templateId}`
            } else if (signDocs?.isEditOffer) {
              queryData = `investor_id=${localData?.id}&asset_id=${assetData.id}&offer_id=${offerId}&parcel_ids=${parcel_id}&template_ids=${templatesItem?.templateId}&is_edit=1`
            } else if (signDocs?.parcel_buy) {
              queryData = `proposer_id=${localData?.id}&asset_id=${assetData.id}&parcel_ids=${parcel_id}&template_ids=${templatesItem?.templateId}`
            } else if (signDocs?.equity) {
              queryData = `investor_id=${localData?.id}&proposer_id=${localData?.id}&asset_id=${assetData.id}&template_ids=${templatesItem?.templateId}&capital_type_id=${signDocs?.capital_type_id}`
            }

            // Make your API call using fetch or Axios
            const response = await GetApi(`/docusign/getSigningDocumentStatus?${queryData}`);
            // Do something with the data
            if (response?.data?.data) {
              if ((signDocs?.from === 'offer-page' || signDocs?.is_direct_buy || signDocs?.isEditOffer) && response.data.data[0]?.proposer_sign) {
                let status = response.data.data[0]?.status === 'completed' ? 2 : 1;
                const updatedArray = templatesData?.map(item => {
                  if (item.templateId === templatesItem?.templateId) {

                    return { ...item, status: status, envelope_id: response?.data?.data[0]?.envelope_id, proposer_sign: response.data.data[0]?.proposer_sign };
                  }
                  return item;
                });
                setTemplatesData(updatedArray)
                handleSignedDoc(updatedArray)
              }
            }
          }
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
      setLoading(false)
    };

    // Call the fetchData function initially
    if (!signDocs?.parcel_buy && !signDocs?.class) {
      checkTemplatesStatus();
    }
  }, [])

  /**
    * open modal to display doc content
    * @param {Array} documentdata 
    * @returns {void}
    */
  const viewDoc = async (documentdata) => {
    setLoading(true);

    try {
      // Check if the PDF has been viewed before
      const isViewed = viewedTemplates?.filter((item) => item?.templateId === documentdata?.templateId && !documentdata?.envelope_id);

      if (isViewed.length) {
        setFile(isViewed[0]?.templateUrl);
        setType('pdf');

        // PDF has been viewed before, no need to fetch again
        setLoading(false);
        setOpenModalView(true);
        return;
      }
      // check for envelope_id if envelope_id exist then
      var callUrl = `${process.env.REACT_APP_BACKEND_URL}/docusign/viewDocument?envelopeId=${documentdata?.envelope_id}`;
      if (!documentdata?.envelope_id) {
        callUrl = `${process.env.REACT_APP_BACKEND_URL}/docusign/viewTemplate?templateId=${documentdata?.templateId}`;
      }

      const response = await fetch(callUrl);

      const templateBuffer = await response.arrayBuffer();
      const templateBlob = new Blob([templateBuffer], { type: 'application/pdf' });
      const templateUrl = URL.createObjectURL(templateBlob);
      setFile(templateUrl);
      setType('pdf');

      // Mark the template as viewed and store in local state
      setViewedTemplates(prevViewedTemplates => [
        ...prevViewedTemplates,
        { templateId: documentdata.templateId, templateUrl }
      ]);

      setOpenModalView(true);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log('error', error);
    }


  };

  /**
   * handle modal close
   */
  const handleClose = () => { setOpenModal(false); setView(false); setEnvelopeId('') };
  const handleCloseView = () => { setOpenModalView(false); };
  /**
   * function to call docusign api 
   */
  const callDocumentSign = async (document, index) => {
    setLoading(true);
    if ((signDocs.navigateBack || signDocs?.from === "offer-accept" || signDocs?.isEditOffer || signDocs?.fundsBeforeSinging) && (assetData?.investment_type_id === INVESTMENT_TYPE_ID.LOAN || assetData?.investment_type_id === INVESTMENT_TYPE_ID.SALE || assetData?.investment_type_id === INVESTMENT_TYPE_ID.PARCEL || assetData?.category_id === CATEGORY_TYPE_ID.REAL_ESTATE || assetData?.category_id === CATEGORY_TYPE_ID.FUNDS)) {
      if (document) {
        try {
          let body = {}
          if (assetData?.investment_type_id === INVESTMENT_TYPE_ID.LOAN) {
            let repaymentData = await repaymentConfigDetail(assetData?.listing_id)

            let loan_amount = assetData?.loan_amount;
            let percentOfLoan = parseFloat(parseFloat(amountToPay) / parseFloat(loan_amount))

            // new parameters
            let loan_number = 0;
            let loan_installment = signDocs?.isEditOffer ? loanDuration : offerData?.loan_duration;
            let loan_to_value = formatNumber((parseFloat(loan_amount) / parseFloat(assetData?.watch_estimated)) * 100);
            let min_prepayment_fees = `$${formatNumber(maxOfTwo((parseFloat(amountToPay) * Number(repaymentData[10]) / 10000), parseFloat(percentOfLoan) * (Number(repaymentData[11]) / 1e18)))}`
            let default_penalty = `$${formatNumber(maxOfTwo((parseFloat(amountToPay) * Number(repaymentData[0]) / 10000), parseFloat(percentOfLoan) * (Number(repaymentData[1]) / 1e18)))}`
            let foreclosure_fees = `$${formatNumber(maxOfTwo((parseFloat(amountToPay) * Number(repaymentData[4]) / 10000), parseFloat(percentOfLoan) * (Number(repaymentData[5]) / 1e18)))}`
            let auction_fees = 0;

            let extraData = { loan_number, loan_installment, loan_to_value, min_prepayment_fees, default_penalty, foreclosure_fees, auction_fees }

            body = {
              proposer_id: assetData?.user_id,      // proposer id borrower
              investor_id: localData.id,            // investor id lender
              template_id: document?.templateId,    // template id
              asset_id: assetData.id,               // asset id
              offer_id: offerId ? offerId : null,   // offer id
              project_title: assetData.asset_title, // project title
              payment_method: assetData.preferred_payment_gateway,  // payment method (crypto for now)
              owner_name: assetData?.owner_information?.owner_name, // proposer or owner name
              investment_type_id: assetData?.investment_type_id,    // investment type id
              amount: `$${convertToOneDecimal(amountToPay)}`,       // offer amount
              loan_rate: `${signDocs?.isEditOffer ? loanROI : offerData?.loan_roi}%`, // loan terms
              loan_duration: `${signDocs?.isEditOffer ? loanDuration : offerData?.loan_duration} ${(signDocs?.isEditOffer ? loanDuration : offerData?.loan_duration) > 1 ? "Months" : "Month"}`, // loan duration
              parcel_ids: [],
              is_edit: signDocs?.isEditOffer ? 1 : 0, // whether it is edit or not
              ...extraData
            }
            console.log(body);
          } else if (assetData?.investment_type_id === INVESTMENT_TYPE_ID.SALE) {
            body = {
              proposer_id: assetData?.user_id,
              investor_id: localData.id,
              template_id: document?.templateId,
              asset_id: assetData.id,
              offer_id: offerId ? offerId : null,
              project_title: assetData.asset_title,
              payment_method: assetData.preferred_payment_gateway,
              owner_name: assetData?.owner_information?.owner_name,
              investment_type_id: assetData?.investment_type_id,
              amount: `$${convertToOneDecimal(amountToPay)}`,
              is_edit: signDocs?.isEditOffer ? 1 : 0
            }
          } else if (assetData?.investment_type_id === INVESTMENT_TYPE_ID.PARCEL) {
            let parcel_id = []
            if (parcel_ids) {
              for (let i = 0; i < parcel_ids.length; i++) {
                let parcelNo = parcel_ids[i].title.split('#')[1];
                parcel_id.push(parcelNo)
              }
            }
            body = {
              proposer_id: assetData?.user_id,
              investor_id: localData.id,
              template_id: document?.templateId,
              asset_id: assetData.id,
              offer_id: offerId ? offerId : null,
              project_title: assetData.asset_title,
              payment_method: assetData.preferred_payment_gateway,
              owner_name: assetData?.owner_information?.owner_name,
              investment_type_id: assetData?.investment_type_id,
              amount: `$${(convertToOneDecimal(amountToPay))}`,
              parcel_ids: parcel_id
            }
            console.log(body);
          } else if (assetData?.category_id === CATEGORY_TYPE_ID.REAL_ESTATE) {
            body = {
              proposer_id: assetData?.user_id,
              investor_id: localData.id,
              template_id: document?.templateId,
              asset_id: assetData.id,
              offer_id: offerId ? offerId : null,
              project_title: assetData.asset_title,
              payment_method: assetData.preferred_payment_gateway,
              owner_name: assetData?.owner_information?.owner_name,
              investment_type_id: assetData?.investment_type_id,
              amount: `$${convertToOneDecimal(amountToPay)}`,
              "capital_type_id": signDocs?.data?.capital_type_id
            }
          } else if (assetData?.category_id === CATEGORY_TYPE_ID.FUNDS && assetData?.asset_sub_category === SUB_CATEGORY_TYPE_ID.CLOSE_FUNDS) {
            let newAmt = formatNumber(parseFloat(amountToPay - (((amountToPay) * 1e18) * (6 / 100)) / 1e18))
            body = {
              proposer_id: assetData?.user_id,
              investor_id: localData.id,
              template_id: document?.templateId,
              asset_id: assetData.id,
              offer_id: offerId ? offerId : null,
              project_title: assetData.asset_title,
              payment_method: paymentMethod ? paymentMethod : assetData.preferred_payment_gateway,
              owner_name: assetData?.owner_information?.owner_name,
              investment_type_id: assetData?.investment_type_id,
              amount: `$${convertToOneDecimal(amountToPay)}`,
              asset_sub_category: assetData?.asset_sub_category,
              // amount: `$${newAmt}`,
              "class": signDocs?.data?.class
            }
          } else if (assetData?.category_id === CATEGORY_TYPE_ID.FUNDS) {
            body = {
              proposer_id: assetData?.user_id,
              investor_id: localData.id,
              template_id: document?.templateId,
              asset_id: assetData.id,
              offer_id: offerId ? offerId : null,
              project_title: assetData.asset_title,
              payment_method: assetData.preferred_payment_gateway,
              owner_name: assetData?.owner_information?.owner_name,
              investment_type_id: assetData?.investment_type_id,
              amount: `$${convertToOneDecimal(amountToPay)}`,
              asset_sub_category: assetData?.asset_sub_category,
              "class": signDocs?.data?.class
            }
          }

          if (envelopeId) {
            const response = await GetApi(`/docusign/getSigningDocumentStatus?envelopeId=${envelopeId}`);

            // Do something with the data
            if (response?.data?.data) {
              if (response.data.data[0]?.status === 'sent' && response.data.data[0]?.proposer_sign) {
                let status = 2;
                const updatedArray = templatesData?.map(item => {
                  if (item.templateId === document.templateId) {
                    return { ...item, status: status, envelope_id: response.data.data[0]?.envelope_id, proposer_sign: response.data.data[0]?.proposer_sign };
                  }
                  return item;
                });
                setTemplatesData(updatedArray)
                handleSignedDoc(updatedArray)
                setLoading(false);
                return;
              }
            }
          }
          let res = await postApi(`/docusign/generateSigningDocument`, body);
          setLoading(false);

          // Checking already signed documents
          if (res?.data.code === 200 && res?.data?.status === 'completed') {
            const updatedArray = templatesData?.map(item => {
              if (item.templateId === document.templateId) {
                return { ...item, status: 2, envelope_id: res?.data?.envelope_id };
              }
              return item;
            });
            setTemplatesData(updatedArray)
            handleSignedDoc(updatedArray)
          } // If not already signed then request document
          else if (res?.data.code === 200 && res.data?.data) {
            // const popup = window.open(res?.data?.data?.redirectUrlSigner1, '_blank');

            // const width = 600;
            // const height = 400;

            // // Calculate the position of the popup
            // const left = (window.innerWidth / 2) - (width / 2);
            // const top = (window.innerHeight / 2) - (height / 2);

            // // Open the popup window in the center
            // const popup = window.open(
            //   res.data.data?.redirectUrlSigner1,
            //   'popupWindow',
            //   `width=${width},height=${height},left=${left},top=${top},resizable=no,scrollbars=no,toolbar=no,menubar=no,location=no,status=no`
            // );

            // Get screen width and height
            const screenWidth = window.innerWidth || document.documentElement.clientWidth;
            const screenHeight = window.innerHeight || document.documentElement.clientHeight;

            // Calculate popup dimensions as a percentage of the screen size (e.g., 60% width and 70% height)
            const popupWidth = Math.floor(screenWidth * 1);
            const popupHeight = Math.floor(screenHeight * 1);

            // Calculate the position to center the popup
            const left = Math.floor((screenWidth - popupWidth) / 2);
            const top = Math.floor((screenHeight - popupHeight) / 2);

            // Open the popup window in the center with dynamic width and height
            const popup = window.open(
              res.data.data?.redirectUrlSigner1,
              'popupWindow',
              `width=${popupWidth},height=${popupHeight},left=${left},top=${top},resizable=yes,scrollbars=yes,toolbar=no,menubar=no,location=no,status=no`
            );

            // const popup = window.open(res.data.data?.redirectUrlSigner1, 'popupWindow', 'width=600,height=400,resizable=no,scrollbars=no,toolbar=no,menubar=no,location=no,status=no');
            // Check if the popup was blocked
            if (!popup || popup.closed || typeof popup.closed === 'undefined') {
              showSnackbar('Popup blocked or not opened.', 'error')
              throw new Error('Popup blocked or not opened.');
            }
            setView(true)
            setOpenModal(true);
            setEnvelopeId(res?.data?.data?.envelopeId)
          } else {
            showSnackbar('There was some error', 'error')
          }
        } catch (error) {
          setLoading(false)
          console.log('error', error)
        }
      } else {
        console.log('document not found');
      }
    } else {
      var payload = `envelopeId=${document.envelope_id}`;

      if (document.envelope_id) {
        const response = await GetApi(`/docusign/getSigningDocumentStatus?${payload}`);
        if (response?.data?.data) {
          if (response.data.data[0]?.status === 'completed') {
            let status = 2;
            if (offerId) {
              const updatedArray = templatesData?.map(item => {
                if (item.templateId === document.templateId) {
                  return { ...item, status: status, envelope_id: response.data.data[0]?.envelope_id, proposer_sign: response.data.data[0]?.proposer_sign };
                }
                return item;
              });
              setTemplatesData(updatedArray)
              handleSignedDoc(updatedArray)
            } else {
              const updatedArray = templatesData?.map(item => {
                if (item.templateId === document.templateId) {
                  return { ...item, status: status, envelope_id: response.data.data[0]?.envelope_id, investor_sign: response.data.data[0]?.investor_sign };
                }
                return item;
              });
              setTemplatesData(updatedArray)
              handleSignedDoc(updatedArray)
            }
            setLoading(false);
            return;
          }
          var investUrlPayload = { envId: document.envelope_id, proposer_id: localData?.id };
          if (assetData?.investment_type_id === INVESTMENT_TYPE_ID.SALE && !offerId) {
            investUrlPayload = { envId: document.envelope_id, proposer_id: localData?.id }
          }
          const responseData = await postApi(`/docusign/getInvestorSignUrl`, investUrlPayload);
          if (responseData?.data?.data && responseData.data.data?.redirectUrlSigner2) {
            // const popup = window.open(responseData.data.data?.redirectUrlSigner2);

            // const width = 600;
            // const height = 400;

            // // Calculate the position of the popup
            // const left = (window.innerWidth / 2) - (width / 2);
            // const top = (window.innerHeight / 2) - (height / 2);

            // // Open the popup window in the center
            // const popup = window.open(
            //   responseData.data.data?.redirectUrlSigner2,
            //   'popupWindow',
            //   `width=${width},height=${height},left=${left},top=${top},resizable=no,scrollbars=no,toolbar=no,menubar=no,location=no,status=no`
            // );

            // Get screen width and height
            const screenWidth = window.innerWidth || document.documentElement.clientWidth;
            const screenHeight = window.innerHeight || document.documentElement.clientHeight;

            // Calculate popup dimensions as a percentage of the screen size (e.g., 60% width and 70% height)
            const popupWidth = Math.floor(screenWidth * 1);
            const popupHeight = Math.floor(screenHeight * 1);

            // Calculate the position to center the popup
            const left = Math.floor((screenWidth - popupWidth) / 2);
            const top = Math.floor((screenHeight - popupHeight) / 2);

            // Open the popup window in the center with dynamic width and height
            const popup = window.open(
              responseData.data.data?.redirectUrlSigner2,
              'popupWindow',
              `width=${popupWidth},height=${popupHeight},left=${left},top=${top},resizable=yes,scrollbars=yes,toolbar=no,menubar=no,location=no,status=no`
            );

            // const popup = window.open(responseData.data.data?.redirectUrlSigner2, 'popupWindow', 'width=600,height=400,resizable=no,scrollbars=no,toolbar=no,menubar=no,location=no,status=no');
            // Check if the popup was blocked
            if (!popup || popup.closed || typeof popup.closed === 'undefined') {
              showSnackbar('Popup blocked or not opened.', 'error')
              throw new Error('Popup blocked or not opened.');
            }
            setView(true)
            setOpenModal(true);
            setEnvelopeId(document.envelope_id)
          } else {
            setLoading(false)
            showSnackbar('Signing url is not found', 'error')
          }
        } else {
          setLoading(false)
          showSnackbar('Signing url is not found', 'error')
        }
      } else {
        console.log('envelope id not found');
      }

    }
  }

  useEffect(() => {
    if (!view) {
      clearInterval(intervalIdRef.current);
      setLoading(false)
    }
  }, [view])

  useEffect(() => {
    if (view && envelopeId) {
      const fetchData = async () => {
        try {
          // Make your API call using fetch or Axios
          var url = `/docusign/getSigningDocumentStatus?envelopeId=${envelopeId}`
          if (offerId) {
            url = `/docusign/getSigningDocumentStatus?envelopeId=${envelopeId}&offerId=${offerId}`
          }
          if (signDocs?.isEditOffer && offerId) {
            url = `/docusign/getSigningDocumentStatus?envelopeId=${envelopeId}&offerId=${offerId}&is_edit=1`
          }
          if (signDocs?.equity) {
            url = `/docusign/getSigningDocumentStatus?envelopeId=${envelopeId}&capital_type_id=${signDocs?.capital_type_id}`
          }
          const response = await GetApi(url);
          // Do something with the data
          if (response?.data?.data[0]) {
            if (signDocs?.isEditOffer && offerId && response.data.data[0]?.status === 'sent' && response.data.data[0]?.proposer_sign || offerId && response.data.data[0]?.status === 'completed' || ((signDocs?.parcel_buy || signDocs?.equity) && response.data.data[0]?.proposer_sign && response.data.data[0]?.investor_sign) || !signDocs?.equity && !signDocs?.parcel_buy && !offerId && response.data.data[0]?.status === 'sent' && response.data.data[0]?.proposer_sign) {
              setView(false);
              setOpenModal(false);
              if (offerId && response.data.data[0]?.investor_sign || signDocs?.isEditOffer && offerId && response.data.data[0]?.proposer_sign) {
                const updatedArray = templatesData?.map(item => {
                  if (item.templateId === response.data.data[0].template_id) {
                    return { ...item, status: 2, envelope_id: response.data.data[0]?.envelope_id, proposer_sign: response.data.data[0]?.proposer_sign };
                  }
                  return item;
                });
                setTemplatesData(updatedArray)
                handleSignedDoc(updatedArray)
              } else {
                const updatedArray = templatesData?.map(item => {
                  if (signDocs?.parcel_buy || signDocs?.equity) {
                    if (item.envelope_id === response.data.data[0].envelope_id) {
                      return { ...item, status: 2, envelope_id: response.data.data[0]?.envelope_id, investor_sign: response.data.data[0]?.investor_sign };
                    }
                  } else {
                    if (item.templateId === response.data.data[0].template_id) {
                      return { ...item, status: 2, envelope_id: response.data.data[0]?.envelope_id, investor_sign: response.data.data[0]?.investor_sign };
                    }
                  }
                  return item;
                });



                setTemplatesData(updatedArray)
                handleSignedDoc(updatedArray)
              }
              // Clean up the interval when the component is unmounted
              clearInterval(intervalIdRef.current);
            }
          } else {
            setView(false);
            setOpenModal(false);
            // Clean up the interval when the component is unmounted
            clearInterval(intervalIdRef.current);
          }
        } catch (error) {
          setView(false);
          setOpenModal(false);
          // Clean up the interval when the component is unmounted
          clearInterval(intervalIdRef.current);
          console.error('Error fetching data:', error);
        }
      };

      // Set up the interval to call the fetchData function every 2 seconds
      const intervalId = setInterval(fetchData, 2000);
      intervalIdRef.current = intervalId;

      return () => {
        clearInterval(intervalIdRef.current);
      };
    }
  }, [view, envelopeId])

  // Find max of two elements
  const maxOfTwo = (a, b) => {
    if (a > b)
      return parseFloat(a);
    else
      return parseFloat(b);
  }
  return (
    <>

      {loading &&
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="primary" />
        </Backdrop>
      }
      <Paper elevation={3} sx={{ p: 2 }} className='rc-basic-container' >
        <Stack direction={'row'} alignItems={'center'} justifyContent={'flex-start'}>
          {signDocs.navigateBack ?
            <IconButton onClick={handleBackBtn} sx={{ color: '#ffffff' }}>
              <ArrowBack />
            </IconButton> : null
          }
          <Typography variant='h6' sx={{ flexGrow: 1 }}>Sign Documents</Typography>
        </Stack>
        <Box component={'hr'} className='rc-hr' />
        {templatesData && templatesData.length > 0
          ? <TableContainer component={Paper} className='TableContainer' style={{ boxShadow: 'none', paddingTop: '16px' }}>
            <Table aria-label="customized table">
              <TableHead className='TableHeader'>
                <TableRow>
                  <StyledTableCell>Document Name</StyledTableCell>
                  {signDocs?.parcel_buy ? <StyledTableCell>Parcel Id(s)</StyledTableCell> : null}
                  <StyledTableCell align="center">Status</StyledTableCell>
                  <StyledTableCell align="center">Action</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {templatesData?.map((templates, i) => (
                  <StyledTableRow className='TableRow' key={`${templates.name}`}>
                    <StyledTableCell component="th" scope="row">{templates?.name}</StyledTableCell>
                    {signDocs?.parcel_buy ? <StyledTableCell component="th" scope="row">{templates?.parcel_ids}</StyledTableCell> : null}
                    <StyledTableCell align="center">
                      {DOCUMENT_SIGN_STATUS_TEXT[templates.status ? templates.status : 1]}
                    </StyledTableCell>
                    <StyledTableCell align='center'>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={{ sx: 0, md: 0, lg: 1 }}
                        width={'120px'}
                        margin={'auto'}
                      >
                        <IconButton>
                          <RemoveRedEye onClick={() => viewDoc(templates, 'view-only', 0)} style={{
                            width: "32px", height: "32px", color: "#FFFFFF"
                          }} />
                        </IconButton>
                        {/* <IconButton disabled={templates.status === 2 ? true : false}>
                     <EditNote onClick={() => callDocumentSign(templates)} style={{ width: "32px", height: "32px", color: templates.status === 2 ? "#053863" : "#FFFFFF" }} />
                   </IconButton> */}
                        <Button onClick={() => callDocumentSign(templates, i)} disabled={(templates.status === 2 || (!amountToPay || amountToPay == 0) && assetData?.investment_type_id != INVESTMENT_TYPE_ID.PARCEL) ? true : false} className='btn-rounded' style={{ height: '28px', backgroundColor: templates.status === 2 ? "#34D399" : '#1771bd' }}>
                          <Typography style={{ fontSize: '12px', padding: '4px', color: templates.status === 2 ? 'black' : 'white' }}>{templates.status === 2 ? "Signed" : "Sign Here"}</Typography>
                        </Button>
                        {/* <IconButton>
                     <Download style={{ color: "#FFFFFF" }} onClick={() => handleDownloadDoc(templatesData)} />
                   </IconButton> */}
                      </Stack>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          :
          <Typography>
            Agreement is not updated by admin.
          </Typography>
        }

        {/* modal code start from here */}
        <Grid>
          {view &&
            <Modal
              className="projectDocumentsModal"
              open={openModal}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              fullWidth={true}
            >
              <Box className="modal-body" sx={styleModal}>
                <Box className="headContent">
                  {/* <Link to="/"> */}
                  <Box className="logo">
                    <Avatar
                      alt="Logo"
                      src={NewLogo}
                      variant="square"
                      sx={{ width: 39, height: 90, objectFit: "contain" }}
                    />
                  </Box>
                  {/* </Link> */}
                  <Close className="close-btn" onClick={handleClose} />
                </Box>
                <Box className="modalContentDone " >
                  <Box className="main-content loan " >
                    <Box className="votingContent">
                      <Typography style={{ fontSize: '20px', textAlign: 'center', marginTop: '70px' }}>
                        You can now sign your document in the new window.
                      </Typography>
                      <Typography style={{ fontSize: '14px', textAlign: 'center', width: '100%' }}>
                        Please do not close this modal until you sign the document. Thank you for your patience.
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Modal>
          }
        </Grid>
        {/* modal code */}
        <Grid>
          {(openModalView) && (
            <Modal
              className="projectDocumentsModal"
              open={openModalView}
              onClose={handleCloseView}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              fullWidth={true}
            >
              <Box sx={styleModal}>
                {/* <iframe
                title="PDF Viewer"
                src={file}
                style={{ width: "100%", height: "100%" }}
                className="pdf-viewer-modal"
              ></iframe> */}
                <object
                  data={file}
                  type="application/pdf"
                  style={{ width: '100%', height: '100%', borderRadius: '12px' }}
                >
                  <p style={{ textAlign: 'center' }}>
                    Your browser does not support PDFs. <a href={file} target="_blank" style={{ color: 'lightgreen' }}>Download the PDF</a>.
                  </p>
                </object>
              </Box>
            </Modal>
          )}
        </Grid>
        {/* modal code end from here */}
      </Paper>
    </>
  )
}