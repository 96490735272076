import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  Typography, TableContainer, Table, TableHead, TableRow, TableCell, Stack,
  Modal, IconButton, TextField, Paper, TableBody
} from "@mui/material";
import { useSnackbar } from "../../../Contexts/SnackbarContext";
import { GetApi, updateApi, postApi } from "../../../Api/Api";
import { useParams, Link, useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from "@mui/icons-material/Error";
import MessageIcon from '@mui/icons-material/Message';
import EditIcon from '@mui/icons-material/Edit';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import audiList from "../../../Assets/Images/audi-img.png";
import "./ViewOfferContract.css";
import config from '../../../Config/Config';
import ConfirmModal from "../../../Components/Modal/Confirm/Confirm";
import { formatDate, validAmountCheck, ccyFormat } from "../../../Utils/utils";
import { getUserDataFromLocalStorage } from "../../../Services/localstorage.service";
import ContactSupportModal from "./ContactSupportModal/ContactSupportModal";
import SpTransactionProgressModal from "../../SmartContracts/SpTransactionProgressModal";
import StakeTokenServiceProvider from "../../Components/StakeModals/StakeTokenServiceProvider";
import CommonBackdropLoader from "../../../Components/Common/CommonBackdropLoader";

const offerModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "var(--blue-900)",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderWidth: "0px",
  borderRadius: "16px",
  "@media (max-width: 600px)": {
    width: "350px", // Set a different height for small screens
  },
  // Additional media queries for other screen sizes if needed
  "@media (min-width: 601px) and (max-width: 900px)": {
    // Custom styles for devices between 601px and 900px width
    width: "580px",
  },
  "@media (min-width: 901px) and (max-width: 1200px)": {
    // Custom styles for devices between 901px and 1200px width
    width: "750px",
    maxHeight: "300px",
    overflow: "auto",
  },
  // Specify the maximum width for large screens
  "@media (min-width: 1201px)": {
    maxWidth: "1043px",
    maxHeight: "525px",
    overflow: "auto",
  },
};

const ViewOfferContract = () => {
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const { contractId } = useParams();
  const [contractData, setContractData] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [isOpenContactSupport, setIsOpenContactSupport] = useState(false);
  const localData = getUserDataFromLocalStorage();

  const [openOfferModal, setOpenOfferModal] = useState(false);
  const [requestAmountError, setRequestAmountError] = useState('');
  const [requestAmount, setRequestAmount] = useState(0);
  const [stakeAmount, setStakeAmount] = useState(0)
  const [loading, setLoading] = useState(false);
  const [openTransactionModal, setOpenTransactionModal] = useState(false);
  const [openRejectOfferTransactionModal, setOpenRejectOfferTransactionModal] = useState(false);
  const [openRTs, setOpenRTs] = useState(false)

  useEffect(() => {
    async function getServiceFeeConfiguration() {
      try {
        if(contractData && contractData?.project_fees){
          const response = await GetApi("/admin/serviceProviderConfig");
          if (response?.data?.code === 200) {
            const amt = (Number(contractData?.project_fees ?? 0)*Number(response?.data?.data?.spStakingAmount ?? 0))/100
            setStakeAmount(amt.toFixed(2))
          }
        }
      } catch (error) {
        console.error("There was an error fetching the data!", error);
      }
    }
    getServiceFeeConfiguration();
  }, [contractData])

  const handleCloseStakeModal = () => {
    setOpenRTs(false)
  }

  const handleStakeAmount = (amount) => {
    setStakeAmount(amount);
    setOpenTransactionModal(true);
    handleCloseStakeModal();
  }

  // Contact Support Modal
  const handleContactSupport = () => {
    handleOpenContactModal()
  }

  /**
   * Function for confirm staking
   * @param {*} data 
   */
  const confirmStake = (data) => {
    setOpenModal(false);
    const payload = {
      user_id: localData?.id,
      contract_id: contractId,
      debit_amount: stakeAmount,
      transaction_type: 'STAKING_SPV_CONTRACTACCEPT',
      status: 0 // for debit
    }
    postApi(`/services/insertSpvTransaction`, payload).then(async (res) => {
      navigate(`/service-provider-dashboard/my-jobs?tab=1`);
    });
  }

  /**
   * Function for confirm reject Original offet
   * @param {*} data 
   */
  const confirmStakeReject = (data) => {
    setOpenModal(false);
    navigate(`/service-provider-dashboard/my-jobs?tab=5`);
  }

  /**
    * Function to open Contact Support Modal
    */
  const handleOpenContactModal = () => {
    setIsOpenContactSupport(true);
  };

  /**
   * Function to close Transection Modal
   */
  const handleModalClose = (data) => {
    setOpenTransactionModal(false);
  }

    /**
   * Function to close Transection Modal
   */
    const handleRejectModalModalClose = (data) => {
      setOpenRejectOfferTransactionModal(false);
    }

  /**
 * Function to close Contact Support Modal
 */
  const handleCloseContactModal = () => {
    setIsOpenContactSupport(false);
  };

  const fetchData = useCallback(() => {
    const url = `/services/getContract?id=${contractId}`;
    GetApi(url).then((res) => {
      if (res?.status === 200) {
        if (res.data && res.data.data && res.data.data.length > 0) {
          setContractData(res.data.data[0]);
          setRequestAmount(res.data.data[0]?.project_fees);
        }
      }
    })
  }, [contractId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleAction = async (action) => {
    if (action === 'confirm') {
      setOpenRTs(true);
      // setOpenTransactionModal(true);
      setOpenModal(false);
    } else {
      setOpenRejectOfferTransactionModal(true);
      setOpenModal(false);
      // updateApi(`/services/contractStatusUpdate/${contractId}`, { status: 7, spv_id: localData?.id }).then((res) => {
      //   if (res?.status === 200) {
      //     if (res.data.code === 200) {
      //       showSnackbar(res?.data?.message, 'success');
      //       setOpenModal(false);
      //       navigate(`/service-provider-dashboard/my-job`);
      //     } else {
      //       showSnackbar(res?.data?.message, 'error');
      //     }
      //   }
      // })
    }
  }

  const handleChat = async () => {
    try {
      const payload = {
        userID: localData?.id,
        toUserID: contractData?.client_id,
        contract_id: contractId,
        chatName: contractData?.title
      }
      const res = await postApi(`/chat/spAddChat`, payload);
      if (res?.data && res?.data?.data) {
        navigate("/service-provider-dashboard/chats", {
          state: {
            user_id: contractData?.client_id,
            chat_id: res?.data?.data?.id
          },
        });
      }
    } catch (error) {
      console.log("dddd", error);
    }
  };

  const modifyOffer = () => {
    const err = validAmountCheck(requestAmount);
    if (err) {
      setRequestAmountError(err);
      return;
    }
    const payload = {
      requested_amount: requestAmount,
      spv_id: contractData?.spv_id
    }
    setLoading(true);
    postApi(`/services/modifyRequestForContractOfferAmount/${contractId}`, payload).then((res) => {
      if (res?.status === 200) {
        if (res?.data?.code === 200) {
          setLoading(false);
          showSnackbar(res?.data?.message, "success");
          setOpenOfferModal(false);
          setRequestAmount('');
          setRequestAmountError('');
          fetchData();
        } else if (res?.data?.code === 201) {
          setLoading(false);
          showSnackbar(res?.data?.message, "warning");
          setOpenOfferModal(false);
          setRequestAmount('');
          setRequestAmountError('');
        } else {
          setLoading(false);
          showSnackbar(res?.data?.message, "error");
          setOpenOfferModal(false);
          setRequestAmount('');
          setRequestAmountError('');
        }
      } else { // something went wrong user or backend side
        setLoading(false);
        showSnackbar("Something went wrong", "error");
      }
    })
  }

  return (
    <>
      <Box className="offer-view-contract">
        <Box className="individual-job-activity-container">
          <CommonBackdropLoader loading={loading} />
          <Box mt={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={8} sm={12} pr={{ md: 2, xs: 0 }}>
                <Box className="post-project-heading">
                  <Typography component="h4" className="title">
                    View Offer
                  </Typography>
                </Box>
                <Box className="post-project-container">
                  <Box className="card-container" >
                    <Grid container spacing={2} justifyContent={'space-between'}>
                      <Grid item xs={12} md={'auto'}>
                        <Typography className="text-blueGray-400">Status</Typography>
                        <Typography sx={{ fontWeight: 500 }}>{config.CONTRACT_STATUS[contractData.status]}</Typography>
                      </Grid>
                      <Grid item xs={12} md={'auto'}>
                        <Typography className="text-blueGray-400">Offer Date</Typography>
                        <Typography sx={{ fontWeight: 500 }}>{contractData?.createdAt ? formatDate(contractData?.createdAt, 'dd/MM/yy - hh:mm a') : ''}</Typography>
                      </Grid>
                      <Grid item xs={12} md={'auto'}>
                        <Typography className="text-blueGray-400">Client / From</Typography>
                        <Typography sx={{ fontWeight: 500 }}>{contractData?.clientData?.first_name} {contractData?.clientData?.last_name}</Typography>
                      </Grid>
                      <Grid item xs={12} md={'auto'}>
                        <Button className="btn-rounded btn-grey"> Contract ID: {contractData?.contract_id}</Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
                <Box sx={{ width: "100%" }}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  </Box>
                  <Box className="details-job-container">
                    <Box className="details-job-main">
                      <Grid container spacing={2}>
                        <Grid item xs={6} md={6} sm={6}>
                          <Box className="left-section">
                            <Typography className="font-16">
                              Auditing For {contractData?.title}
                            </Typography>
                            <Typography className="font-12 text-blueGray-300">
                              Service To <span className="usman-text">{contractData?.clientData?.first_name} {contractData?.clientData?.last_name}</span> |
                              Delivery date{" "}
                              <span className="date-time-text">{contractData?.delivery_date ? formatDate(contractData?.delivery_date, 'MMM d, yyyy') : ''}</span>
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={6} md={6} sm={6}>
                          <Box className="right-section">
                            <Typography className="font-14 text-blueGray-400">
                              TOTAL PRICE
                            </Typography>
                            <Typography className="text-white font-22">
                              $ {contractData?.project_fees}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                      <Box mt={2}>
                        <Typography className="font-12 text-blueGray-300">
                          {contractData?.describe_project}
                        </Typography>
                      </Box>
                      <Box className="table-view-container">
                        <Grid container spacing={2}>
                          <Grid item xs={6} md={6} sm={6}>
                            <Box className="your-order-div">
                              <Typography className="text-white font-16" ml={2}>
                                Client Messages
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={6} md={6} sm={6} sx={{ textAlign: "end" }}>
                            <Link className="text-golden font-12 text-underline view-invoice-text">
                              Learn more about contracts and offers
                            </Link>
                          </Grid>
                        </Grid>

                        <Box mt={1} className="item-table-main">
                          <TableContainer sx={{ overflowY: "scroll", width: "100%", height: "190px" }} >
                            <Table aria-label="spanning table">
                              <TableHead>
                                <TableRow>
                                  <TableCell>{contractData?.client_message}</TableCell>
                                </TableRow>
                              </TableHead>
                            </Table>
                          </TableContainer>
                        </Box>
                      </Box>
                      {contractData?.milestoneData?.length > 0 &&
                        <Box mt={2} className="table-view-container">
                          <Grid container spacing={2}>
                            <Grid item xs={6} md={6} sm={6}>
                              <Box className="your-order-div">
                                <Typography className="text-white font-16">
                                  Milestone Details
                                </Typography>
                              </Box>
                            </Grid>
                            {/* <Grid item xs={6} md={6} sm={6} sx={{textAlign: "end"}}>
                            <Link className="text-golden font-12 text-underline view-invoice-text">
                              Learn more about contracts and offers
                            </Link>
                          </Grid> */}
                          </Grid>
                          <Box mt={1} className="item-table-main">
                            <TableContainer component={Paper}>
                              <Table sx={{ width: '100%' }} aria-label="spanning table">
                                <TableHead>
                                  <TableRow>
                                    <TableCell>ITEM</TableCell>
                                    <TableCell align="center">Due Date</TableCell>
                                    <TableCell align="right">PRICE</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {contractData?.milestoneData?.length > 0 && contractData?.milestoneData.map((i) =>
                                    <TableRow>
                                      <TableCell>{i?.description}</TableCell>
                                      <TableCell align="center">{i?.dueDate ? formatDate(i?.dueDate, 'MMM d, yyyy') : ''}</TableCell>
                                      <TableCell align="right">
                                        {/* project fee (project fee)*/}
                                        ${ccyFormat(i?.Amount)}
                                      </TableCell>
                                    </TableRow>
                                  )}
                                  <TableRow className="sub-total-div">
                                    <TableCell>SUBTOTAL</TableCell>
                                    <TableCell></TableCell>
                                    <TableCell align="right">
                                      ${ccyFormat(contractData?.project_fees)}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow className="sub-total-div">
                                    <TableCell>SERVICE FEE</TableCell>
                                    <TableCell align="right"></TableCell>
                                    <TableCell align="right">
                                      - ${ccyFormat(contractData?.spv_service_fees ?? 0)}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow className="sub-total-div">
                                    <TableCell className="text-white">
                                      TOTAL
                                      <Typography className="text-blueGray-300 font-12">(You will get)</Typography>
                                    </TableCell>
                                    <TableCell></TableCell>
                                    <TableCell align="right" className="text-white">
                                      ${ccyFormat(contractData?.project_fees) - ccyFormat(contractData?.spv_service_fees ?? 0)}
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Box>
                        </Box>}
                      <Box>
                        <Typography className="text-blueGray-400 font-12 footer-text" textAlign={'center'} pt={2}>
                          If something appears to be missing or incorrect, please contact
                          our <spap className="text-underline">Customer Support Specialists</spap>.
                        </Typography>
                      </Box>
                    </Box>
                    <Box></Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={4} sm={12}>
                <Box className="activity-right-section">
                  <Box>
                    <Typography className="font-16">Job Details</Typography>
                  </Box>
                  <Box className="job-offer-details-box">
                    <Box>
                      <img src={audiList} />
                    </Box>
                    <Box className="right-section">
                      <Typography className="font-16">
                        Auditing For {contractData?.projectDetails?.project_title ? contractData?.projectDetails?.project_title : contractData?.title}
                      </Typography>
                      <Typography className="font-12 text-blueGray-300">
                        {config.CONTRACT_STATUS[contractData.status]}
                      </Typography>
                    </Box>
                  </Box>
                  <Box className="service-box">
                    <Box className="service-container">
                      <Typography className="font-12">Service To</Typography>
                      <Typography className="font-12 text-blueGray-300">
                        {contractData?.clientData?.first_name} {contractData?.clientData?.last_name}
                      </Typography>
                    </Box>
                    <Box className="service-container">
                      <Typography className="font-12">Delivery Date</Typography>
                      <Typography className="font-12 text-blueGray-300">
                        {contractData?.delivery_date ? formatDate(contractData?.delivery_date, 'MMM d, yyyy') : ''}
                      </Typography>
                    </Box>
                    <Box className="service-container">
                      <Typography className="font-12">Total Price</Typography>
                      <Typography className="font-12 text-blueGray-300">
                        $ {contractData?.project_fees}
                      </Typography>
                    </Box>
                    <Box className="service-container">
                      <Typography className="font-12">Job Category</Typography>
                      <Typography className="font-12 text-blueGray-300">
                        {contractData?.projectDetails?.categoryDetails?.title ? contractData?.projectDetails?.categoryDetails?.title : ''}
                      </Typography>
                    </Box>
                    <Box className="service-container">
                      <Typography className="font-12">Project Term</Typography>
                      <Typography className="font-12 text-blueGray-300">
                        {contractData?.project_term === 'milestone' && 'By milestone'}
                        {contractData?.project_term === 'project' && 'By project'}
                        {contractData?.project_term === 'hourly' && 'Hourly'}
                      </Typography>
                    </Box>
                  </Box>
                  <Box className="action-div">
                    <Typography className="font-16">Actions</Typography>
                    <Box>
                      <ErrorIcon />
                    </Box>
                  </Box>
                  {(contractData?.status === 1 || contractData?.status === 8) && (
                    <Box className="deliver-order-box" onClick={() => setOpenModal(true)}>
                      <Typography className="font-14">Accept Offer</Typography>
                      <CheckCircleIcon />
                    </Box>
                  )}
                  {contractData && contractData.status === 2 && (
                    <Box className="deliver-order-box" >
                      <Typography className="font-14">Accepted</Typography>
                      <CheckCircleIcon />
                    </Box>
                  )}
                  <Box className="upload-files-box cursor-pointer" onClick={() => handleChat()}>
                    <Typography className="font-14">Messages</Typography>
                    <MessageIcon />
                  </Box>
                  {(contractData?.status === 1) &&
                    <Box className="upload-files-box cursor-pointer" onClick={() => {
                      setOpenOfferModal(true);
                      setRequestAmount(contractData?.project_fees);
                    }}>
                      <Typography className="font-14">Modify Offer</Typography>
                      <EditIcon />
                    </Box>
                  }
                  {/** 
                  {contractData && contractData.status === 2 && (
                    <Box className="upload-files-box cursor-pointer">
                      <Typography className="font-14">Modify Offer</Typography>
                    </Box>
                  )}
                */}
                  <Box className="action-div">
                    <Typography className="font-16">Support</Typography>
                  </Box>
                  <Link to={`/service-provider-dashboard/faq`}>
                    <Box className="faq-support-box">
                      <Stack direction={'row'}>
                        <ContactSupportIcon />&nbsp;&nbsp;&nbsp;
                        <Stack direction={'row'}>
                          <Box>
                            <Typography className="font-14">FAQ</Typography>
                            <Typography className="font-12">Have a Question?</Typography>
                          </Box>
                        </Stack>
                      </Stack>
                      <ChevronRightIcon />
                    </Box>
                  </Link>
                  <Box className="upload-files-box" onClick={handleContactSupport}>
                    <Stack direction={'row'}>
                      <SupportAgentIcon />&nbsp;&nbsp;&nbsp;
                      <Box >
                        <Typography className="font-14">Contact Support</Typography>
                        <Typography className="font-12">Resolve Order issues?</Typography>
                      </Box>
                    </Stack>
                    <ChevronRightIcon />
                  </Box>
                  {isOpenContactSupport && <ContactSupportModal onOpen={isOpenContactSupport} handleClose={handleCloseContactModal} contractId={contractId} />}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      <ConfirmModal title={'Accept'} description={'Are You Sure Want To Accept It!'} isOpen={openModal} setIsOpenModal={setOpenModal} handleAction={handleAction} />

      {openTransactionModal ? <SpTransactionProgressModal identity={'accept-original-offer'} confirmStake={confirmStake} propData={{ ...contractData, stakeAmt: stakeAmount }} handleModalClose={handleModalClose} openTransactionModal={openTransactionModal} /> : null}
      {openRejectOfferTransactionModal ? <SpTransactionProgressModal identity={'reject-original-offer'} confirmStake={confirmStakeReject} propData={contractData} handleModalClose={handleRejectModalModalClose} openTransactionModal={openRejectOfferTransactionModal} /> : null}
      <StakeTokenServiceProvider openRTs={openRTs} handleClose={handleCloseStakeModal} handleStakeAmount={handleStakeAmount} stakeAmount={stakeAmount} />
      
      <Modal
        open={openOfferModal}
        onClose={() => {
          setOpenOfferModal(false);
          setRequestAmount('');
          setRequestAmountError('');
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="send-custom-offer-container send-offer"
      >
        <Box sx={offerModalStyle}>
          <Box className="headContent cross-view-single">
            <IconButton className='cross-icon-modal' onClick={() => {
              setOpenOfferModal(false);
              setRequestAmount('');
              setRequestAmountError('');
            }}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Typography id="modal-modal-title" component="h2" mb={4}>
            Request For Modify Contract Offer Amount
          </Typography>
          <Box className="step1-container ">
            <Grid container spacing={2}>
              <Grid item md={12} sm={12}>
                <Box className="left-div">
                  <Box className="input-box" mb={3}>
                    <TextField
                      id="outlined-basic"
                      label="Amount"
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                      placeholder="Amount"
                      value={"$" + requestAmount}
                      onChange={(e) => {
                        setRequestAmount(
                          e.target.value.substring(1)
                        )
                        const err = validAmountCheck(e.target.value.substring(1));
                        if (err) setRequestAmountError(err);
                        else setRequestAmountError('');
                      }
                      }
                    />
                    {requestAmountError && <p style={{ color: 'red' }}>{requestAmountError}</p>}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box className="send-offer" textAlign={'center'}>
            <LoadingButton
              onClick={modifyOffer}
              loading={loading}
              loadingPosition="start"
              variant="contained"
              type="submit"
              className="submit-btn"
            >
              Send Request
            </LoadingButton>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default ViewOfferContract;