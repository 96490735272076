import Documents from "../Documents";
import AssetDetails from "../AssetDetails";
import PriceOverview from "../PriceOverview";
import GallerySlider from "../GallerySlider";
import Description from "../Description";
import OwnerInformation from "../OwnerInformation";
import BaseDetailsAsset from "./BaseDetailsClass";
import CheckReview from "../CheckReview";
import Templates from "../Templates";
import { CATEGORY_TYPE_ID, INVESTMENT_TYPE_ID } from "../../../constants";
export default class Art extends BaseDetailsAsset {
  rightPanel = [];
  leftPanel = [];
  isParcel = false;

  constructor(_data) {

    super(_data);
    if (!_data) {
      throw new Error(
        "Custom error: Art data is required in Art Details class"
      );
    }

    this.offersData = [
      {
        user_name: "John Doe",
        offer_amount: "12850.98",
      },
      {
        user_name: "John Doe",
        offer_amount: "12850.98",
      },
      {
        user_name: "John Doe",
        offer_amount: "12850.98",
      },
    ];

    this.assetDetails = [
      {
        title: "Investment Type",
        value: _data?.investment_type?.investment_name ?? "",
      },
      {
        title: "Art Type",
        value: _data.asset_sub_category_info?.title ?? "",
      },
      {
        title: "Art Title",
        value: _data.asset_title ?? "",
      },
      {
        title: "Artist",
        value: _data.artsData[0]?.artist ?? "",
      },
      {
        title: "Year",
        value: _data.artsData[0]?.year ?? ""
      },
      {
        title: "Medium",
        value: _data.artsData[0]?.medium ?? ""
      },
      {
        title: "Owned By",
        value: _data.owner_information?.owner_type ?? ""
      },
    ];

    this.checkReview = [
      {
        title: "All required documents uploaded",
        value: false,
      },
      {
        title: "Deal terms reviewed",
        value: false,
      },
      {
        title: "Asset Details provided are accurate",
        value: false,
      },
      {
        title: "Project Description and Title reviewed",
        value: false,
      },
      {
        title: "Images uploaded are clear and accurate",
        value: false,
      },
    ];

    this.docs = _data.asset_document;
    this.data = _data;
    this.blockchain_id = _data.blockchain_id
    this.location = _data?.asset_location?.split(",");
    this.description_document = _data.description_document
    this.asset_signing_document = _data.asset_signing_document
    this.selected_templates = _data?.template_data ? _data?.template_data[0]?.template_ids : []
    this.galleryImages = _data?.asset_gallery;
    this.member_id = _data?.user_details?.member_id


    if (_data.description_document?.length > 0) {
      const documentOnly = _data.description_document.filter(item => item.images && (item.images.type === "mp4"));
      if (documentOnly?.length > 0) {
        this.galleryImages = _data?.asset_gallery.concat(documentOnly);
      }
    }
    this.isParcel = _data?.investment_type?.investment_name === "Parcelling" ? true : false;
    this.coverphoto = _data?.asset_coverphoto;
    this.descriptionData = {
      title: _data?.artsData[0]?.art_name,
      subtitle: _data?.artsData[0]?.art_address,
      investmentType: _data?.investment_type?.investment_name,
      assetType: _data?.assets_category?.title,
      assetSubtype: _data?.asset_sub_category_info?.title,
      description: _data?.asset_description,
      image: _data?.asset_coverphoto?.path ? _data?.asset_coverphoto?.path : "",
    };

    this.parcelCardsData = _data?.parcelData;
    this.setLeftPanelComponents();
    this.setRightPanelComponents();
  }

  setLeftPanelComponents = () => {
    // if (this.isParcel === true) {
    //   this.leftPanel.push(
    //     <ParcelDescription
    //       description={this.data.asset_description}
    //       parcelImage={this.descriptionData.image}
    //     />
    //   );
    //   this.leftPanel.push(<ParcelCardGrid cardsData={this.parcelCardsData} />);
    // } else {
    this.leftPanel.push(<GallerySlider images={this.galleryImages} coverPhoto={this.coverphoto} />);
    this.leftPanel.push(
      <Description
        description={this.descriptionData.description}
        investmentType={this.descriptionData.investmentType}
        title={this.descriptionData.title}
        subtitle={this.descriptionData.subtitle}
        assetType={this.descriptionData.assetType}
        assetSubtype={this.descriptionData.assetSubtype}
        parcelCardsData={this.parcelCardsData}
        userId={this.user_id}
        assetId={this.asset_Id}
      />
    );
    // }
  };


  setRightPanelComponents = () => {
    this.rightPanel.push(<OwnerInformation owner={this.ownerInfo} totalProject={this.totalProjects} member_id={this.member_id} />);
    this.rightPanel.push(<PriceOverview prices={this.prices} />);
    this.rightPanel.push(<AssetDetails details={this.assetDetails} editable={this.isOwner} />);
    if (this.isSale) {
      this.rightPanel.push(<Documents documentsData={this.docs} descriptionData={this.description_document} ownerId={this.ownerInfo} assetId={this.asset_Id} editable={this.isOwner} section_title="Asset Documents" />);
    }
    // this.rightPanel.push(<Documents documentsData={this.asset_signing_document} descriptionData={[]} ownerId={this.ownerInfo} assetId={this.asset_Id} editable={this.isOwner} section_title="Agreement Documents" />);
    this.rightPanel.push(<Templates selected_templates={this.selected_templates} />);
    this.rightPanel.push(<CheckReview data={this.data} blockchain_id={this.blockchain_id} assetId={this.asset_Id} details={this.checkReview} editable={this.isOwner} reviewList={this.reviewList} readOnly={this.readOnly} />);
  };

}
