// variable to switch between testnet and polygon
var isMainnetProduction = false;
/**
* @file overview This file contains the contract addresses and ABI's
*/

/**
* @constant {object} SP_CONTRACTS - The contract addresses
*/
export const SP_CONTRACTS = {
    ADMIN: isMainnetProduction ? "" : '0x9D469313498a7bAc49c27f2d393B7E5cDc6F575b',
    SERVICE_PROVIDER_REGISTRY: isMainnetProduction ? "" : '0x266a44c3C32284165445e31A9593cE6733919Da6',
    SERVICE_MARKETPLACE_CLIENT: isMainnetProduction ? "" : '0x14A09020B8f8d2564B073dA55646798EF5b0C817',
    SERVICE_MARKETPLACE_PROVIDER: isMainnetProduction ? "" : '0x5cf780E6A46B22B3196591F787BB0666213BCdea',
    SERVICE_STAKING_CONTRACT: isMainnetProduction ? "" : '0x1363D5b69295e36484c2CA14E12130A9329CD5f1',
    SERVICE_ESCROW_WALLET: isMainnetProduction ? "" : '0x69E3104ba1e29636BaeCaaC8605ABB7180c578d5',
}

/**
 * @constant {object} CONTRACT_ABI - The contract ABI's
 */
export const SP_CONTRACT_ABI = {
    [SP_CONTRACTS.ADMIN]: isMainnetProduction ? require("./Abi/AdminRT.json") : require("./Abi/Admin.json"),
    [SP_CONTRACTS.SERVICE_PROVIDER_REGISTRY]: isMainnetProduction ? require("./Abi/registrationRT.json") : require("./Abi/registration.json"),
    [SP_CONTRACTS.SERVICE_MARKETPLACE_CLIENT]: isMainnetProduction ? require("./Abi/servicemarketplaceRT.json") : require("./Abi/servicemarketplace.json"),
    [SP_CONTRACTS.SERVICE_MARKETPLACE_PROVIDER]: isMainnetProduction ? require("./Abi/serviceMarketPlaceServiceProviderRT.json") : require("./Abi/serviceMarketPlaceServiceProvider.json"),
    [SP_CONTRACTS.SERVICE_STAKING_CONTRACT]: isMainnetProduction ? require("./Abi/serviceStakingRT.json") : require("./Abi/serviceStaking.json"),
    [SP_CONTRACTS.SERVICE_ESCROW_WALLET]: isMainnetProduction ? require("./Abi/ServiceEscrowWalletRT.json") : require("./Abi/ServiceEscrowWallet.json"),
}