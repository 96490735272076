import { Box, Grid } from "@mui/material";
import React, { useMemo, useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import RaceProjectCard from "../RaceProjectCard/RaceProjectCard";
import { RightArrow, LeftArrow } from "../../../../Components/Images/Images";


const SlickSlider = ({ projects, selectedProject, projectData }) => {
  const sliderRef = useRef(null);
  const [focusedIndex, setFocusedIndex] = useState(null);

  const handleProjectId = (project) => {
    projectData(project);
  };

  useEffect(() => {
    if (projects && projects?.length > 0 && selectedProject) {
      // Find the index of the object with the specified id
      const idx = projects?.findIndex(item => item.id === selectedProject?.id);
      // sliderRef.current.slickGoTo(idx);
      setFocusedIndex(idx);
    }
  }, [projects, selectedProject]);

  const NextArrow = ({ onClick }) => {
    return <img src={RightArrow} alt="RightArrow" className="arrow-right" onClick={onClick} style={{ cursor: 'pointer' }} />;
  };

  const PrevArrow = ({ onClick }) => {
    return <img src={LeftArrow} alt="LeftArrow" className="arrow-left" onClick={onClick} style={{ cursor: 'pointer' }} />;
  };
  const settings = {
    slidesToShow: 1,
    rows: 1,
    slidesPerRow: 4,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    className: "center",
    centerPadding: "0px",
    centerMode: true,
    speed: 500,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          rows: 1,
          slidesPerRow: 4,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          rows: 1,
          slidesPerRow: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          rows: 1,
          slidesPerRow: 1
        }
      }
    ]
  };

  return (
    <Box className="cat-slider">
      <div className="slider-container" style={{ paddingLeft: "15px", cursor: "pointer" }} >
        <Slider ref={sliderRef} {...settings}>
          {projects?.length > 0 && projects?.map((project, index) => (
            <div key={index} onClick={() => handleProjectId(project)}>
              <RaceProjectCard project={project} focused={index === focusedIndex ? 'focused' : 'unfocused'} navigate={false} />
            </div>
          ))}
          {/* {projects?.data?.length === 2 && 
              <div key={2}>
                &nbsp;
              </div>
            } */}
        </Slider>
      </div>
    </Box>
  );
};

export default SlickSlider;
