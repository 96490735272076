import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { NewLogo } from '../../../Components/Images/Images'
import { Box, Grid, Button, Checkbox, Accordion, AccordionSummary, AccordionDetails, Typography, Tooltip } from "@mui/material";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import LinearProgressCompo from "./Components/LinearProgressCompo";
import { GetApi, GetApiParam } from "../../../Api/Api";
import "./Components/Questionnaire.css"

const label = { inputProps: { "aria-label": "Checkbox demo" } };

export default function AcceptPaymentTerms(props) {

  const [termList, setTermList] = useState()
  // Destructure props to obtain necessary functions and data
  const { handleNext, handleback, getProposalData, updateProposalData, saveProposal, getProgress } = props;

  // Getting current proposal and progress data from parent
  let propData = getProposalData();
  let currentProgress = getProgress();

  // fill on or off for n length
  const generateString = (length, string) => {
    return Array.from({ length }, () => string);
  };

  // State for the checked payment terms
  const [checked, setChecked] = useState([]);

  useEffect(() => {
    if (propData?.payment_terms) {
      setChecked(generateString(termList?.length, "on"))
    } else {
      setChecked(generateString(termList?.length, "off"))
    }
  }, [propData?.payment_terms, termList])


  /**
   * Handles the change in payment terms.
   * @param {number} num - The index of the payment term.
   */
  const handleChange = (num) => (e) => {
    setChecked(prev => {
      let result = [...prev];
      result[num] = result[num] === "on" ? "off" : e.target.value;
      return result;
    })
  }

  /**
   * Handles the Next button click, updating payment terms and proceeding to the next step.
   */
  const handleClick = () => {
    propData.payment_terms = 1;
    updateProposalData(propData);
    handleNext();
  }

  /**
   * Getting Dynamic payment terms
   */

  useEffect(() => {
    async function getTermList() {
      try {

        const res = await GetApiParam("/proposer/Payment-terms", {
          investment_type_id: propData?.investment_type_id, category_id: propData?.category_id
        });
        if (res?.data?.data && res?.data?.data?.length) {
          const flattenedData = res.data.data.flatMap((item) =>
            item?.terms_description?.map((faq) => ({
              main_id: item.id,  // Main id
              id: faq.id,        // Term id
              title: faq.title,
              description: faq.description
            }))
          );
          setTermList(flattenedData);
          // setTermList(res?.data?.data ? res?.data?.data : [])
        } else {
          setTermList([])
        }
      } catch (error) {
        console.log(error)
      }
    }
    setTimeout(() => {
      getTermList()
    }, 500);
  }, [])



  /**
   * Handles the Save and Exit action, updating payment terms and saving the proposal.
   */
  const SaveAndExit = () => {
    propData.payment_terms = 1;
    updateProposalData(propData);
    saveProposal();
  }

  return (
    <Box className="projectProposer questionnaire-page">
      <Box className="questionnaire-section">
        <Grid container spacing={0}>
          <Grid item xs={12} sm={6} md={6}>
            <Box className="auth-left" >
              {/* left-title */}
              <Box className="quests-left-title">
                <Typography component="h1" className="title">
                  Please read and accept our payment terms
                </Typography>
              </Box>
              {/* Logo */}
              <Box className="auth-logo">
                <Link to="/">
                  <Box component="img" src={NewLogo} alt="Race logo" />
                </Link>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Box className="auth-right quests-right-contents-box">
              <Box textAlign={"right"}>
                <Box component="span" className="icon" ml={1}>
                  {/* <Link to='/project-proposer' className="btn-rounded btn-text-white btn-blue-600">
                    Save and Exit
                  </Link> */}
                  <Button onClick={SaveAndExit} disabled={checked.every(element => element === 'on') ? false : true} className="btn-rounded btn-text-white btn-blue-600">
                    Save and Exit
                  </Button>
                </Box>
              </Box>
              <Box className="questionnaire5 qsn-middle-content" sx={{ pt: 4, mb: 5 }}>
                <Box className="paymentTerms-accordions">
                  {termList?.length > 0 ? termList?.map((term, index) => {
                    return (
                      <React.Fragment key={index}>
                        {/* <Box className="NS-payment-terms" my={2} key={index}>
                          <Box color={"black"}>
                            {term.title}
                          </Box>
                          <Box>
                            <Checkbox {...label} onChange={handleChange(index)} defaultChecked={propData?.payment_terms ? true : false} />
                          </Box>
                        </Box> */}

                        <Box className="accordion-items" position={'relative'} mb={2.5} key={index}>
                          <Box className="checkbox-wrap">
                            <Checkbox {...label} onChange={handleChange(index)} defaultChecked={propData?.payment_terms ? true : false} />
                          </Box>
                          <Accordion>
                            <AccordionSummary
                              // expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <Typography className="title">{term.title}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography>
                                {term.description}
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                        </Box>
                      </React.Fragment>
                    )
                  }) :
                    termList?.length == 0 &&
                    <Box className="accordion-items" position={'relative'} mb={2.5} key={0}>
                      <Typography className="font-18 text-black" textAlign={'center'}>Currently, no terms are available. You can proceed.</Typography>
                    </Box>
                  }
                </Box>
              </Box>
              <Box>
                <Box className="questionnaire-progress">
                  <LinearProgressCompo value={currentProgress} />
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }} mt={3}>
                  <Box textAlign={"left"} className='quests6-btn-box'>
                    <Button className='btn-rounded back-btn' onClick={handleback}>
                      Back
                    </Button>
                  </Box>
                  <Box textAlign={"right"}
                    className='quests-btn-box'

                  >
                    <Tooltip title={checked.every(element => element === 'on') ? "" : "You need to accept payment terms to continue."} className="p-flow-next-btn-tooltip">
                      <span>
                        <Button onClick={handleClick} disabled={checked.every(element => element === 'on') ? false : true} endIcon={<KeyboardArrowRightIcon />}
                          className="btn-rounded btn-text-white btn-blue-600">
                          Next
                        </Button>
                      </span>
                    </Tooltip>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box >
  );
}
