import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Backdrop, Box, CircularProgress, Typography } from '@mui/material';
import { useSnackbar } from "../../Contexts/SnackbarContext";
import { CATEGORY_TYPE_ID, INVESTMENT_TYPE_ID, PROJECT_STATUS, SUB_CATEGORY_TYPE_ID } from "../../constants"
import { deleteApi, postApi, assetUpdateParcelApi, GetApi, GetApiParam } from '../../Api/Api';

// Importing components for the Questionnaire flow
import SelectAssetType from './Questionnaire/SelectAssetType'; // Component for selecting the asset type
import SelectListingType from './Questionnaire/SelectListingType'; // Component for selecting the listing type
import SelectOwnerType from './Questionnaire/SelectOwnerType'; // Component for selecting the owner type
import OwnerInformation from './Questionnaire/OwnerInformation'; // Component for filling in owner information
import SelectAssetSubType from './Questionnaire/SelectAssetSubType'; // Component for selecting the asset subtype
import AddAssetLocation from './Questionnaire/AddAssetLocation'; // Component for adding asset location
import AddAssetTitle from "./Questionnaire/AddAssetTitle"; // Component for adding asset title
import AddAssetPhotos from "./Questionnaire/AddAssetPhotos"; // Component for adding asset photos
import AddAssetDescription from "./Questionnaire/AddAssetDescription"; // Component for adding asset description
import EditParcelPrices from "./Questionnaire/EditParcelPrices"; // Component for editing parcel prices
import DepositAmount from "./Questionnaire/DepositAmount"; // Component for setting sale deposit amount

import ArtDetails from "./Questionnaire/ArtDetails"; // Component for filling in art details
import GoldDetails from './Questionnaire/GoldDetails'; // Component for filling in gold details
import LuxuryCarDetails from './Questionnaire/LuxuryCarDetails'; // Component for filling in car details
// import WatchDetails from './Questionnaire/WatchDetails'; // Component for filling in watch details (page old)
import WatchDetails1 from './Questionnaire/WatchDetails1'; // Component for filling in watch details (page 1)
import WatchDetails2 from './Questionnaire/WatchDetails2'; // Component for filling in watch details (page 2)

import SetSaleDetails from "./Questionnaire/SetSaleDetails"; // Component for setting sale price details
import SetParcelDetails from './Questionnaire/SetParcelDetails'; // Component for setting parcel price details
import SetLoanDetails from './Questionnaire/SetLoanDetails'; // Component for setting loan price details
import RealEstateFractionDetails from "./Questionnaire/RealEstateFractionDetails"; // Component for setting real-estate fraction price details
import FundsFractionDetails from './Questionnaire/FundsFractionDetails' // Component for setting funds fraction price details

import RealEstateCapitalDetails from './Questionnaire/RealEstateCapitalDetails'; // Component for real-estate capital details
import FundsCapitalDetails from './Questionnaire/FundsCapitalDetails'; // Component for funds capital details

import UploadDocuments from "./Questionnaire/UploadAssetDocuments"; // Component for uploading documents
import UploadSigningDocuments from "./Questionnaire/UploadSigningDocuments"; // Component for uploading signing documents
import SelectPaymentType from "./Questionnaire/SelectPaymentType"; // Component for selecting payment type
import AcceptPaymentTerms from "./Questionnaire/AcceptPaymentTerms"; // Component for checking payment terms
import PreviewListing from "./Questionnaire/PreviewListing"; // Component for previewing the listing
import ListingSubmitted from './Questionnaire/ListingSubmitted'; // Component for listing submission confirmation
import PreviewListing2 from './Questionnaire/PreviewListing2'; // Component for proposer pay stack and creating a proposal

// Variable to hold all flow
var steFlow = {
    'default': ['select_category', 'select_listing'],
    'real-estate': {
        'fraction': ['select_category', 'select_listing', 'select_entity', 'owner_information', 'selection_category', 'select_location', 'select_title', 'add_photo', 'description', 'fraction_details', 'capital_type_details', 'upload_documents', "upload_signing_documents", "payment_type", "payment_term", 'review_listing', 'pay_stake', "listing_submitted"],
    },
    "art": {
        'parcel': ['select_category', 'select_listing', 'select_entity', 'owner_information', 'selection_category', 'art_project_details', 'add_photo', 'description', 'parcel_details', "edit_prices", "upload_signing_documents", "payment_type", "payment_term", 'review_listing', 'pay_stake', "listing_submitted"],

        'sale': ['select_category', 'select_listing', 'select_entity', 'owner_information', 'selection_category', 'art_project_details', 'add_photo', 'description', 'sales_details', "deposit_amount", 'upload_documents', "upload_signing_documents", "payment_type", "payment_term", 'review_listing', 'pay_stake', "listing_submitted"]
    },
    "watch": {
        "loan": ['select_category', 'select_listing', 'select_entity', "owner_information", 'watch_details1', 'watch_details2', 'add_photo', 'description', "loan_details", "upload_documents", "upload_signing_documents", "payment_type", "payment_term", 'review_listing', 'pay_stake', "listing_submitted"],

        // 'sale': ['select_category', 'select_listing', 'select_entity', 'owner_information', 'watch_details1', 'watch_details2', 'add_photo', 'description', 'sales_details', "deposit_amount", 'upload_documents', "upload_signing_documents", "payment_type", "payment_term", 'review_listing', 'pay_stake', "listing_submitted"]
    },
    "car": {
        "loan": ['select_category', 'select_listing', 'select_entity', "owner_information", 'asset_car_details', 'add_photo', 'description', "loan_details", "upload_documents", "upload_signing_documents", "payment_type", "payment_term", 'review_listing', 'pay_stake', "listing_submitted"],
    },
    "gold": {
        "loan": ['select_category', 'select_listing', 'select_entity', "owner_information", "gold_details", 'add_photo', 'description', "loan_details", "upload_documents", "upload_signing_documents", "payment_type", "payment_term", 'review_listing', 'pay_stake', "listing_submitted"],
    },
    "funds": {
        'fraction': ['select_category', 'select_listing', 'select_entity', 'owner_information', 'select_title', 'add_photo', 'description', 'fraction_details_fund', 'capital_type_details_fund', 'upload_documents', "upload_signing_documents", "payment_type", "payment_term", 'review_listing', 'pay_stake', "listing_submitted"]
    },
}

// Component to display warning to user when he reload the page in Questionnaire flow
const CustomDialog = ({ isOpen, onClose }) => {
    return (
        <>
            {isOpen && (
                <div className="custom-dialog-overlay">
                    <div className="custom-dialog">
                        <h2>Are you sure you want to leave?</h2>
                        <p>Your changes may not be saved.</p>
                        <div className="button-container">
                            <button onClick={onClose}>Stay</button>
                            <button onClick={() => { window.location.reload(); }}>Leave</button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

const Stepper = () => {
    // Initializing navigation functions for page routing
    const navigate = useNavigate();
    const { showSnackbar } = useSnackbar();

    // Extracting data from the current page's location
    const location = useLocation();
    const state = location?.state;
    const EditMode = state?.EditMode === true ? true : false;

    // Retrieving user data from localStorage
    const userData = localStorage.getItem("user_data") ? JSON.parse(localStorage.getItem("user_data")) : { id: '' };

    // Initializing state variables using the useState hook
    const [assetData, setAssetData] = useState("");
    const [assetCoverImage, setCoverImage] = useState("");
    const [loading, setLoading] = useState(false);
    const [capitalTypeList, setCapitalTypeList] = useState([]);
    const [stepCount, setCurrentStepper] = useState(state?.selectedCategory ? 1 : 0);
    const [totalSteps, setTotalSteps] = useState(15);
    const [assetDeleted, setAssetDeleted] = useState(false);
    const [activeStep, setActiveStep] = useState(state?.from === "/project-proposer" ? 'select_listing' : 'select_category');  // Handling navigation steps based on user's origin
    const [prevAsset, setPrevAsset] = useState({ category_id: state?.category_id, investment_type_id: state?.investment_type_id });  // Handling asset change or listing type change 

    const [showDialog, setShowDialog] = useState(false);
    const [shouldShowDefaultDialog, setShouldShowDefaultDialog] = useState(true);
    const [dataSaved, setDataSaved] = useState(false);

    // State to check the token status
    const [TokenStatus, SetTokenStatus] = useState('')

    // Initializing proposer object for non-edit mode 
    const [proposalObj, setProposalData] = useState({
        "category_id": state?.category_id ? state?.category_id : '',
        "selectedCategory": state?.selectedCategory ? state?.selectedCategory : "",
        "selectedListingType": "",
        "user_id": userData?.id,
        "parcelsDetails": [],
        "persona_picture": userData?.persona_picture,
        "project_proposer": userData?.first_name && userData?.last_name ? `${userData?.first_name} ${userData?.last_name}` : ''
    });

    // functionality for preventing user to reload the page
    useEffect(() => {
        if (TokenStatus) {
            if (TokenStatus?.status) {
                const handleBeforeUnload = (event) => {
                    if (shouldShowDefaultDialog) {
                        const confirmationMessage = 'Are you sure you want to leave?';
                        event.returnValue = confirmationMessage;
                        return confirmationMessage;
                    }
                };

                window.addEventListener('beforeunload', handleBeforeUnload);

                return () => {
                    window.removeEventListener('beforeunload', handleBeforeUnload);
                };
            }
        }
    }, [shouldShowDefaultDialog, TokenStatus]);

    // Custom Dialog for the Reload Model
    const handleCloseDialog = () => {
        setShowDialog(false);
        setShouldShowDefaultDialog(false);
    };

    // Calling Api to check the Token Current Status
    useEffect(() => {
        async function CheckToken() {
            try {
                const checkTokenRes = await GetApi("/user/checkToken")
                if (checkTokenRes?.data?.code === 200 && checkTokenRes?.data?.status) {
                    SetTokenStatus(checkTokenRes?.data)
                }
            } catch (error) {
                console.log(error);
            }
        }
        setTimeout(() => {
            CheckToken();
        }, 100);
    }, []);

    // Call Handle Close if the token expire
    useEffect(() => {
        if (TokenStatus) {
            if (TokenStatus?.status) {
                handleCloseDialog()
            }
        }
    }, [TokenStatus])


    // Fetching asset data in Edit Mode
    useEffect(() => {
        if (EditMode && state?.id) {
            // fetch the range config for selected category and investment type
            // let stakeData = ''
            // async function fetchRangeConfig() {
            //     try {
            //         // getting response from api
            //         const res = await GetApiParam(`/proposer/getStackAmount`, {
            //             category_id: state?.category_id,
            //             investment_type_id: state?.investment_type_id
            //         });
            //         // setting data
            //         if (res?.data?.data) {
            //             // setStakeAmtData(res.data.data)
            //             stakeData = res.data.data;
            //         }
            //     } catch (error) {
            //         console.log(error)
            //     }
            // }
            // setTimeout(() => {
            //     fetchRangeConfig();
            // }, 100);

            // fetch the asset details by asset id
            async function fetchData() {
                try {
                    setLoading(true)
                    // getting response from api
                    const res = await postApi("/proposer/assetsDetails", { "asset_id": state?.id, "user_id": userData?.id });
                    let assetCover = ''
                    if (res?.data?.data && res?.data?.data?.length && res?.data?.data[0]?.asset_gallery[0]) {
                        // getting image url from server
                        // const response = await fetch(`${process.env.REACT_APP_IMAGE_URL}${res?.data?.data[0]?.asset_gallery[0]?.images?.path}`);
                        const response = await fetch(`${process.env.REACT_APP_IMAGE_URL}${res?.data?.data[0]?.asset_gallery[0]?.images?.path}`, { cache: 'no-store' });
                        if (response) {
                            const blob = await response.blob(); // converting to blob
                            const blobUrl = URL.createObjectURL(blob); // converting to object url
                            assetCover = blobUrl
                            setCoverImage(blobUrl) // setting url
                        }
                        setTimeout(() => {
                            setLoading(false)
                        }, 500);
                    }

                    // getting response from api
                    const stakeData = await GetApiParam(`/proposer/getStackAmount`, {
                        category_id: state?.category_id,
                        investment_type_id: state?.investment_type_id
                    });
                    const capitalDetails = []
                    if (state?.category_id === CATEGORY_TYPE_ID.REAL_ESTATE) {
                        capitalDetails = await GetApi("/proposer/getTypeOfCapital");
                    }

                    console.log("stakeData?.data?.data...", stakeData?.data?.data)

                    // setting data
                    if (res?.data?.data && stakeData?.data?.data) {
                        let assetDetails = res?.data?.data[0];
                        if (assetDetails) {
                            if (assetDetails?.active_step === 'review_listing') {
                                setActiveStep('pay_stake');
                                setCurrentStepper(parseInt(assetDetails?.current_step) + 1);
                            } else {
                                setActiveStep(assetDetails?.active_step);
                                setCurrentStepper(parseInt(assetDetails?.current_step));
                            }
                            setTotalSteps(parseInt(assetDetails?.total_steps))
                            // handling listing type
                            let selectedListingType = ""
                            switch (assetDetails?.investment_type_id) {
                                case 1:
                                    selectedListingType = "sale"
                                    break
                                case 2:
                                    selectedListingType = "loan"
                                    break
                                case 3:
                                    selectedListingType = "parcel"
                                    break
                                case 4:
                                    selectedListingType = "fraction"
                                    break
                            }
                            // handling location
                            let locationArr = assetDetails?.asset_location ? assetDetails?.asset_location?.split(",") : ""
                            let location = assetDetails?.asset_location ? [parseFloat(locationArr[0]), parseFloat(locationArr[1])] : ''
                            // handling gallery images
                            let gallery = []
                            let gallery_photo_ids = []
                            for (let i = 0; i < assetDetails?.asset_gallery?.length; i++) {
                                gallery.push(assetDetails?.asset_gallery[i]?.images?.path)
                                gallery_photo_ids.push(assetDetails?.asset_gallery[i]?.id)
                            }
                            // handling asset document
                            let asset_document = []
                            let asset_document_file = []
                            for (let i = 0; i < assetDetails?.asset_document?.length; i++) {
                                asset_document.push(assetDetails?.asset_document[i]?.images)
                                asset_document_file.push(assetDetails?.asset_document[i])
                            }
                            // handling asset document
                            let asset_signing_document = []
                            let asset_signing_document_file = []
                            for (let i = 0; i < assetDetails?.asset_signing_document?.length; i++) {
                                asset_signing_document.push(assetDetails?.asset_signing_document[i]?.images)
                                asset_signing_document_file.push(assetDetails?.asset_signing_document[i])
                            }
                            // handling description document
                            let description_document = []
                            let description_document_file = []
                            for (let i = 0; i < assetDetails?.description_document?.length; i++) {
                                description_document.push(assetDetails?.description_document[i]?.images)
                                description_document_file.push(assetDetails?.description_document[i])
                            }
                            // handling parcel data
                            let parcelData = [];
                            for (let i = 0; i < assetDetails?.parcelData?.length; i++) {
                                let oneParcel = assetDetails?.parcelData[i];
                                parcelData.push({ parcel_id: oneParcel?.id, id: i + 1, data: oneParcel.price, image: oneParcel.imagess?.path, image_view: oneParcel.imagess?.path });
                            }
                            // getting array of capital type names
                            let capital_type = JSON.parse(assetDetails?.capital_type ? assetDetails?.capital_type : "[]");
                            // capital type details real-estates
                            let capitalTypeDetails = []
                            if (assetDetails?.category_id === CATEGORY_TYPE_ID.REAL_ESTATE) {
                                for (let i = 0; i < capital_type?.length && assetDetails?.capital_info?.length; i++) {
                                    const capital_name = capital_type[i];
                                    let newData = assetDetails?.capital_info?.filter((data) => data?.capital_type?.capital_name === capital_name)
                                    capitalTypeDetails.push({ [capital_name]: newData[0] })
                                }
                            }
                            // capital type details funds
                            let sharedClassesType = []
                            if (assetDetails?.category_id === CATEGORY_TYPE_ID.FUNDS) {
                                for (let i = 0; i < assetDetails?.capital_info?.length; i++) {
                                    sharedClassesType.push({ 'total_raise': assetDetails?.capital_info[i]?.total_raise, 'minimum_investment': assetDetails?.capital_info[i]?.minimum_investment, 'hurdle_rate': assetDetails?.capital_info[i]?.rate_of_return })
                                }
                            }
                            // get watch data
                            let watchData = assetDetails?.watchData;
                            // get car data
                            let carData = assetDetails?.assetInformation;

                            let proposalDataObj = {
                                // extra start
                                "selectedCategory": assetDetails?.assets_category?.url,
                                "selectedListingType": selectedListingType,
                                "asset_coverphoto_view": assetCover,
                                "gellary_view": gallery,
                                "gallery_photo_id": gallery_photo_ids,
                                "deleted_photo_id": null,
                                "asset_document_file": asset_document_file,
                                "asset_signing_document_file": asset_signing_document_file,
                                "description_document_file": description_document_file,
                                'owner': userData?.type ? userData?.type : '',
                                "blockchain_category_id": assetDetails?.assets_category?.blockchain_id,
                                "blockchain_investment_type_id": assetDetails?.investment_type?.blockchain_id,
                                "blockchain_asset_category_id": assetDetails?.asset_sub_category_info?.blockchain_id,
                                'stackAmtData': stakeData?.data?.data,
                                // extra end

                                "owner_id": assetDetails?.owner_id,
                                "asset_id": assetDetails?.id,
                                "user_id": assetDetails?.user_id,
                                "category_id": assetDetails?.category_id,
                                "investment_type_id": assetDetails?.investment_type_id,
                                "other_category": (assetDetails?.other_category !== "null" && assetDetails?.other_category) ? assetDetails?.other_category : null,
                                "owner_type": assetDetails?.owner_information?.owner_type === "Individual" ? "Individual" : assetDetails?.owner_information?.owner_type === "Entity" ? "Entity" : '',
                                "ownerInformation": {
                                    "owner_name": assetDetails?.owner_information ? assetDetails?.owner_information?.owner_name : '',
                                    "owner_email": assetDetails?.owner_information ? assetDetails?.owner_information?.owner_email : '',
                                    "owner_phone_number": assetDetails?.owner_information ? assetDetails?.owner_information?.owner_phone_number : '',
                                    "owner_address": assetDetails?.owner_information ? assetDetails?.owner_information?.owner_address : '',
                                    "owner_address2": assetDetails?.owner_information ? assetDetails?.owner_information?.owner_address2 : '',
                                    "owner_title": assetDetails?.owner_information ? assetDetails?.owner_information?.owner_title : '',
                                    "organization_address": assetDetails?.owner_information ? assetDetails?.owner_information?.organization_address : '',
                                    "organization_name": assetDetails?.owner_information ? assetDetails?.owner_information?.organization_name : '',
                                    "organization_phone_number": assetDetails?.owner_information ? assetDetails?.owner_information?.organization_phone_number : ''
                                },
                                "asset_sub_category": assetDetails?.asset_sub_category,
                                "asset_sub_category_url": assetDetails?.asset_sub_category_info?.url,
                                "asset_location": location ? location : null,
                                "asset_address": assetDetails?.asset_address ? assetDetails?.asset_address : '',
                                "asset_title": assetDetails?.asset_title,
                                "asset_coverphoto": gallery[0] ? gallery[0] : '',
                                "gellary": gallery,
                                "asset_description": JSON.parse(assetDetails?.asset_description),
                                "description_document": description_document,
                                // sale
                                "sale_price": assetDetails?.sale_price,
                                "sale_deposit_amount": assetDetails?.sale_deposit_amount,
                                // loan
                                "loan_amount": assetDetails?.loan_amount ? assetDetails?.loan_amount : null,
                                "loan_roi": assetDetails?.loan_roi ? assetDetails?.loan_roi : null,
                                "loan_duration_month": assetDetails?.loan_duration_month ? assetDetails?.loan_duration_month : null,
                                // fraction
                                "fractionalize_project_size": assetDetails?.fractionalize_project_size ? assetDetails?.fractionalize_project_size : null,
                                "fractionalize_total_price": assetDetails?.fractionalize_total_price,
                                "fractionalize_rate_of_return": assetDetails?.fractionalize_rate_of_return,
                                "fractionalize_duration_of_project": assetDetails?.fractionalize_duration_of_project,
                                "number_of_shared": assetDetails?.number_of_shared ? parseInt(assetDetails?.number_of_shared) : null,
                                "capital_type": capital_type ? capital_type : [],
                                "shared_data": sharedClassesType ? sharedClassesType : [],
                                // space-x-fund
                                "fractionalize_minimum_investment": assetDetails?.fractionalize_minimum_investment,
                                "investment_duration": assetDetails?.investment_duration,
                                "management_fees": assetDetails?.management_fees,
                                "per_year_fees": assetDetails?.per_year_fees,
                                "carried_interest": assetDetails?.carried_interest,
                                // parcel
                                "parcel_total_price": assetDetails?.parcel_total_price ? assetDetails?.parcel_total_price : null,
                                "number_of_parcel": assetDetails?.number_of_parcel ? assetDetails?.number_of_parcel : null,
                                "parcel_duration_of_project": null,
                                "minimun_investment_per_parcel": assetDetails?.minimun_investment_per_parcel ? assetDetails?.minimun_investment_per_parcel : null,
                                "number_of_columns": assetDetails?.number_of_columns ? assetDetails?.number_of_columns : null,
                                "number_of_rows": assetDetails?.number_of_rows ? assetDetails?.number_of_rows : null,
                                "parcelsDetails": parcelData.length ? parcelData : [],

                                "asset_document": asset_document,
                                "asset_signing_document": asset_signing_document,
                                "preferred_payment_gateway": assetDetails?.preferred_payment_gateway ? assetDetails?.preferred_payment_gateway : '',
                                "payment_terms": assetDetails?.payment_terms == 1 ? 1 : 0,
                                "art_regular_shape": assetDetails?.art_regular_shape == 0 || assetDetails?.art_regular_shape == '0' ? 0 : 1,
                                "capitalTypeDetails": capitalTypeDetails ? capitalTypeDetails : [],
                                "capital_info": assetDetails?.capital_info ? assetDetails?.capital_info : [],
                                "isParcelCreated": false,
                                "capital_type_ids": JSON.parse(assetDetails?.capital_type_ids),
                                "project_proposer": assetDetails?.user_details?.first_name && assetDetails?.user_details?.last_name ? `${assetDetails?.user_details?.first_name} ${assetDetails?.user_details?.last_name}` : '',
                                'capital_type_list': capitalDetails?.data?.data,
                                'selected_templates': assetDetails?.template_data?.length ? assetDetails?.template_data[0]?.template_ids : [],
                                // new 
                                'loan_iop': assetDetails?.loan_iop ? assetDetails?.loan_iop : 0,
                                'loan_minimum_investment': assetDetails?.loan_minimum_investment,
                                'loan_repay_day': assetDetails?.loan_repay_day,
                            }
                            if (assetDetails.gold_quality) {
                                proposalDataObj.goldInformation =
                                {
                                    "gold_title": assetDetails?.gold_title ? assetDetails?.gold_title : "",
                                    "gold_quality": assetDetails?.gold_quality ? assetDetails?.gold_quality : "",
                                    "gold_weight": assetDetails?.gold_weight ? assetDetails?.gold_weight : "",
                                    "gold_estimated": assetDetails?.gold_estimated ? assetDetails?.gold_estimated : "",
                                    "gold_condition": assetDetails?.gold_condition ? assetDetails?.gold_condition : "",
                                    "gold_address": assetDetails?.gold_address ? assetDetails?.gold_address : "",
                                    "gold_floor": assetDetails?.gold_floor ? assetDetails?.gold_floor : "",
                                    "gold_city": assetDetails?.gold_city ? assetDetails?.gold_city : "",
                                    "gold_state": assetDetails?.gold_state ? assetDetails?.gold_state : "",
                                    "gold_country": assetDetails?.gold_country ? assetDetails?.gold_country : "",
                                    "gold_zipcode": assetDetails?.gold_zipcode ? assetDetails?.gold_zipcode : "",
                                }
                            }
                            if ((assetDetails?.artsData && assetDetails?.artsData[0] && assetDetails?.artsData[0]?.art_name)) {
                                proposalDataObj.artProjectDetails = {
                                    art_name: (assetDetails?.artsData && assetDetails?.artsData[0]) ? assetDetails?.artsData[0]?.art_name : '',
                                    artist: (assetDetails?.artsData && assetDetails?.artsData[0]) ? assetDetails?.artsData[0]?.artist : '',
                                    year: (assetDetails?.artsData && assetDetails?.artsData[0]) ? assetDetails?.artsData[0]?.year : '',
                                    medium: (assetDetails?.artsData && assetDetails?.artsData[0]) ? assetDetails?.artsData[0]?.medium : '',
                                    art_address: (assetDetails?.artsData && assetDetails?.artsData[0]) ? assetDetails?.artsData[0]?.art_address : '',
                                    art_floor: (assetDetails?.artsData && assetDetails?.artsData[0]) ? assetDetails?.artsData[0]?.art_floor : '',
                                    art_city: (assetDetails?.artsData && assetDetails?.artsData[0]) ? assetDetails?.artsData[0]?.art_city : '',
                                    art_state: (assetDetails?.artsData && assetDetails?.artsData[0]) ? assetDetails?.artsData[0]?.art_state : '',
                                    art_zip: (assetDetails?.artsData && assetDetails?.artsData[0]) ? assetDetails?.artsData[0]?.art_zip : '',
                                    art_country: (assetDetails?.artsData && assetDetails?.artsData[0]) ? assetDetails?.artsData[0]?.art_country : '',
                                }
                            }
                            if (carData?.type_of_car) {
                                proposalDataObj.carInformation =
                                {
                                    "type_of_car": carData?.type_of_car ? carData?.type_of_car : "",
                                    "car_brand": carData?.car_brand ? carData?.car_brand : "",
                                    "car_estimated": carData?.car_estimated ? carData?.car_estimated : "",
                                    "car_model": carData?.car_model ? carData?.car_model : "",
                                    "car_vin_number": carData?.car_vin_number ? carData?.car_vin_number : "",
                                    "car_condition": carData?.car_condition ? carData?.car_condition : "",
                                }
                            }
                            if (watchData?.watch_model) {
                                proposalDataObj.watchInformation = {
                                    "watch_model": watchData?.watch_model ? watchData?.watch_model : "",
                                    "watch_brand": watchData?.watch_brand ? watchData?.watch_brand : "",
                                    "watch_estimated": watchData?.watch_estimated ? watchData?.watch_estimated : "",
                                    "watch_year": watchData?.watch_year ? watchData?.watch_year : "",
                                    "watch_condition": watchData?.watch_condition ? watchData?.watch_condition : "",
                                    "type_of_watch": watchData?.type_of_watch ? watchData?.type_of_watch : "",
                                    "watch_gender": watchData?.watch_gender ? watchData?.watch_gender : "",
                                    "watch_movement": watchData?.watch_movement ? watchData?.watch_movement : "",
                                    "watch_crystal": watchData?.watch_crystal ? watchData?.watch_crystal : "",
                                    "watch_resistance": watchData?.watch_resistance ? watchData?.watch_resistance : "",
                                    "watch_strap_material": watchData?.watch_strap_material ? watchData?.watch_strap_material : "",
                                    "watch_dial_numeral": watchData?.watch_dial_numeral ? watchData?.watch_dial_numeral : "",
                                    "watch_dial": watchData?.watch_dial ? watchData?.watch_dial : "",
                                    "watch_case_material": watchData?.watch_case_material ? watchData?.watch_case_material : "",
                                    "watch_case_diameter": watchData?.watch_case_diameter ? watchData?.watch_case_diameter : "",
                                    "watch_bezel_material": watchData?.watch_bezel_material ? watchData?.watch_bezel_material : "",
                                    "watch_thickness": watchData?.watch_thickness ? watchData?.watch_thickness : "",
                                    "watch_strap_colour": watchData?.watch_strap_colour ? watchData?.watch_strap_colour : "",
                                    "watch_serial_number": watchData?.watch_serial_number ? watchData?.watch_serial_number : "",
                                    "watch_scope_of_delivery": watchData?.watch_scope_of_delivery ? watchData?.watch_scope_of_delivery : "",
                                }
                            }
                            setProposalData(proposalDataObj)
                        }
                        setAssetData({ ...assetDetails, stackAmtData: stakeData })
                    }

                    setLoading(false)
                } catch (error) {
                    setLoading(false)
                    console.log(error);
                    showSnackbar('There was some error', 'error')
                }
            }
            setTimeout(() => {
                fetchData();
            }, 100);
        }
    }, [EditMode, state?.id, dataSaved])

    // Removing location data on page reload (coming from project proposer with selected asset)
    useEffect(() => {
        if (state?.from === "/project-proposer") {
            setActiveStep(state?.from === "/project-proposer" ? 'select_listing' : 'select_category')
            navigate(location.pathname, { replace: true });
        }
    }, [])

    /**
    * Update or set current asset type selected in Edit Mode
    * @param {asset id} data 
    */
    const updateAsset = (data) => {
        setPrevAsset(data)
    }

    /**
    * get previous asset type selected in Edit Mode
    */
    const getPrevAsset = () => {
        return prevAsset;
    };

    /**
    * Handle asset delete in Edit Mode
    * @param {status} data 
    */
    const updateAssetDeleted = (data) => {
        setAssetDeleted(data)
    }

    /**
    * Function to get current progress based on asset type selected
    */
    const getProgress = () => {
        var progress = (stepCount / totalSteps) * 100
        return progress;
    };

    /**
    * Function to handle navigation to the next page in the project creation process.
    */
    const handleNext = async () => {
        console.log('proposalObj -> next', proposalObj)
        console.log('progress...', stepCount + 1, totalSteps, parseFloat((stepCount + 1) * 100 / totalSteps).toFixed(2));

        var steps = stepManagement(stepCount)
        var stepper = stepCount
        stepper++
        setCurrentStepper(stepper);

        // handling 
        if (steps.next === "select_entity") {
            if (proposalObj.selectedListingType === 'loan' || proposalObj.selectedListingType === 'sale') {
                switch (proposalObj.selectedCategory) {
                    case "art":
                        setTotalSteps(16)
                        break
                    case "gold":
                        setTotalSteps(13)
                        break
                    case "car":
                        setTotalSteps(13)
                        break
                    case "watch":
                        proposalObj.selectedListingType === 'sale' ? setTotalSteps(15) : setTotalSteps(14)
                        break
                    default:
                        setTotalSteps(16)
                        break
                }
            } else if (proposalObj.selectedListingType === 'fraction') {
                switch (proposalObj.selectedCategory) {
                    case 'real-estate':
                        setTotalSteps(16)
                        break
                    case "funds":
                        proposalObj.asset_sub_category === SUB_CATEGORY_TYPE_ID.CLOSE_FUNDS ? setTotalSteps(14) : setTotalSteps(14)
                        break
                    default:
                        setTotalSteps(16)
                        break
                }
            } else if (proposalObj.selectedListingType === 'parcel') {
                switch (proposalObj.selectedCategory) {
                    case "art":
                        setTotalSteps(14)
                        break
                    default:
                        setTotalSteps(16)
                        break
                }
            }
        }

        if (steps.next === "listing_submitted") {
            let res = await saveProposal(PROJECT_STATUS.SUBMITTED);
            if (!res) {
                stepper--
                setCurrentStepper(stepper);
                return
            }
        } else if (steps.next === "pay_stake") {
            let res = await saveProposal(PROJECT_STATUS.DRAFT);
            if (!res) {
                stepper--
                setCurrentStepper(stepper);
                return
            }
        }
        if (steps.next === 'capital_type_details_fund' && steps.next !== "pay_stake") {
            if (proposalObj?.asset_sub_category === SUB_CATEGORY_TYPE_ID.CLOSE_FUNDS) {
                setCurrentStepper(stepper + 1);
                var stepsNew = stepManagement(stepper)
                console.log(stepsNew.next);
                setActiveStep((prevActiveStep) => stepsNew.next);
            } else {
                console.log(steps.next);
                setActiveStep((prevActiveStep) => steps.next);
            }
        } else if (steps.next !== "pay_stake") {
            console.log(steps.next);
            setActiveStep((prevActiveStep) => steps.next);
        }
    };

    /**
    * Function to handle navigation to the previous page in the project creation process.
    */
    const handleBack = () => {
        // debugger
        var stepper = stepCount
        if (stepper > 0) {
            stepper--
        }
        setCurrentStepper(stepper);
        console.log('proposalObj -> back', proposalObj)
        var steps = stepManagement(stepper)
        if (steps.prev === 'capital_type_details_fund') {
            if (proposalObj?.asset_sub_category === SUB_CATEGORY_TYPE_ID.CLOSE_FUNDS) {
                var stepsNew = stepManagement(stepper - 1)
                setCurrentStepper(stepper - 1);
                console.log('back', stepsNew, stepper);
                setActiveStep((prevActiveStep) => stepsNew.prev);
            } else {
                setActiveStep((prevActiveStep) => steps.prev);
            }
        } else {
            setActiveStep((prevActiveStep) => steps.prev);
        }
    };

    /**
    * Function to handling save and exit, submit project.
    * @param {String} status
    */
    const saveProposal = async (status) => {
        let formData = new FormData();
        setLoading(true)
        if (status === PROJECT_STATUS.SUBMITTED) {
            formData.append("status", PROJECT_STATUS.SUBMITTED);
            formData.append("project_process", 100);
            formData.append("is_draft", 0);
        } else {
            formData.append("is_draft", 1);
            formData.append("status", PROJECT_STATUS.DRAFT);
            formData.append("project_process", getProgress());
            formData.append("current_step", stepCount)
            formData.append("total_steps", totalSteps);
            formData.append("active_step", activeStep)
        }
        for (const key in proposalObj) {
            if (key === "ownerInformation") {
                for (const owner_key in proposalObj?.ownerInformation) {
                    if (proposalObj?.ownerInformation[owner_key]) {
                        formData.append(`${owner_key}`, `${proposalObj?.ownerInformation[owner_key]}`);
                    }
                }
            } else if (key === "watchInformation" && proposalObj?.selectedCategory === "watch") {
                for (const watch_key in proposalObj?.watchInformation) {
                    if (proposalObj?.watchInformation[watch_key]) {
                        formData.append(`${watch_key}`, proposalObj?.watchInformation[watch_key]);
                    }
                }
            } else if (key === "carInformation" && proposalObj?.selectedCategory === "car") {
                for (const car_key in proposalObj?.carInformation) {
                    if (proposalObj?.carInformation[car_key] || proposalObj?.carInformation[car_key] == 0) {
                        formData.append(`${car_key}`, proposalObj?.carInformation[car_key]);
                    }
                }
            } else if (key === "goldInformation" && proposalObj?.selectedCategory === "gold") {
                for (const gold_key in proposalObj?.goldInformation) {
                    if (proposalObj?.goldInformation[gold_key] || proposalObj?.goldInformation[gold_key] == 0) {
                        formData.append(`${gold_key}`, proposalObj?.goldInformation[gold_key]);
                    }
                }
            } else if (key === "artProjectDetails" && proposalObj?.selectedCategory === "art") {
                for (const art_key in proposalObj?.artProjectDetails) {
                    if (proposalObj?.artProjectDetails[art_key]) {
                        formData.append(`${art_key}`, proposalObj?.artProjectDetails[art_key]);
                    }
                }
            } else if (key === "selectedCategory" || key === "artProjectDetails" || key === "watchInformation" || key === "carInformation" || key === "goldInformation" || key === "ownerInformation" || key === "selectedListingType" || key === "asset_coverphoto_view" || key === "gellary_view" || key === "gellary" || key === 'asset_document' || key === "gallery_photo_id" || key === "deleted_photo_id" || key === "asset_sub_category_url" || key === "parcelsDetails" || key === "description_document" || key === "current_step" || key === "total_steps" || key === "CapitalTypeChecked" || (key === "isParcelCreated" && EditMode == false) || key === "asset_coverphoto" || (key === "capitalTypeDetails" && proposalObj?.selectedCategory == "funds") || key == 'capital_info' || key === "asset_document_file" || key === "asset_document_ids" || key === "description_document_file" || key === "description_document_ids" || key === 'asset_signing_document' || key === 'asset_signing_document_file' || key === 'asset_signing_document_ids' || key === "cover_photo_changed" || key === "project_proposer" || key === "persona_picture" || key === 'capital_type_list' || key === "blockchain_category_id" || key === 'blockchain_asset_category_id' || key === "blockchain_investment_type_id" || key === 'stackAmtData') {
                continue;
            } else {
                if (proposalObj[key] == 0 || proposalObj[key] && proposalObj[key] !== undefined) {
                    if (key === "capital_type") { // real-estate capital type selected by user
                        formData.append("capital_type", JSON.stringify(proposalObj['capital_type']));
                    } else if (key === "capital_type_ids") { // real-estate capital type selected by user
                        formData.append("capital_type_ids", JSON.stringify(proposalObj['capital_type_ids']));
                    } else if (key === "capitalTypeDetails" && proposalObj?.selectedCategory != "funds") {
                        // formData.append("capital_type_details", JSON.stringify(proposalObj[key]));
                    } else if (key === "shared_data") {
                        formData.append("shared_data", JSON.stringify(proposalObj['shared_data']));
                    } else if (key === "asset_description") {
                        formData.append(`${key}`, JSON.stringify(proposalObj[key]));
                    } else if (key === "other_category") {
                        if (proposalObj[key] != '') { formData.append("other_category", proposalObj[key]) }
                    } else if (key === "selected_templates" && proposalObj[key]) {
                        formData.append(`${key}`, JSON.stringify(proposalObj[key]));
                    } else {
                        formData.append(`${key}`, proposalObj[key]);
                    }
                }
            }
        }
        const apiUrl = (EditMode && !assetDeleted && proposalObj.asset_id) ? '/proposer/assetUpdate/' : '/proposer/assetCreate/';

        // creating or updating asset
        const res = await postApi(apiUrl, formData);
        // once asset created , sending parcel data, asset resources and capital types data
        if (res?.data?.code === 200) {
            var steps = stepManagement(stepCount);
            try {
                // getting asset id
                let assetId = res?.data?.data[0]?.id;

                // only for edit mode
                if (EditMode && (proposalObj?.deleted_photo_id || proposalObj?.asset_document_ids || proposalObj?.description_document_ids || proposalObj?.asset_signing_document_ids)) {
                    if (proposalObj?.deleted_photo_id?.length) {
                        for (const photo_id of proposalObj?.deleted_photo_id) {
                            await deleteApi(`/proposer/assetResources/${photo_id}`)
                        }
                    } else if (proposalObj?.asset_document_ids?.length) {
                        for (const photo_id of proposalObj?.asset_document_ids) {
                            await deleteApi(`/proposer/assetResources/${photo_id}`)
                        }
                    } else if (proposalObj?.asset_signing_document_ids?.length) {
                        for (const photo_id of proposalObj?.asset_signing_document_ids) {
                            await deleteApi(`/proposer/assetResources/${photo_id}`)
                        }
                    } else if (proposalObj?.description_document_ids?.length) {
                        for (const photo_id of proposalObj?.description_document_ids) {
                            await deleteApi(`/proposer/assetResources/${photo_id}`)
                        }
                    }
                }

                // for asset document, asset description and asset images
                for (const key in proposalObj) {
                    let data = "";
                    let name = ""
                    if (key === "gellary") {
                        data = proposalObj?.gellary;
                        name = "gellary"
                    }
                    else if (key === "asset_document") {
                        data = proposalObj?.asset_document;
                        name = "asset_document"
                    } else if (key === 'description_document') {
                        data = proposalObj?.description_document
                        name = "description_document"
                    } else if (key === 'asset_signing_document') {
                        data = proposalObj?.asset_signing_document
                        name = "asset_signing_document"
                    }

                    if (data !== "" && name !== "" && data?.length) {
                        for (let [i, file] of data?.entries()) {
                            if (!file?.id && !file?.original_name) {
                                let galleryData = new FormData()
                                galleryData.append(name, file);
                                if (name === "gellary") {
                                    galleryData.append("image_order", i + 1);
                                }
                                if (EditMode && name === "gellary" && proposalObj?.gallery_photo_id && proposalObj?.gallery_photo_id[i]) {
                                    galleryData.append("resource_id", proposalObj?.gallery_photo_id[i]);
                                }
                                galleryData.append("asset_id", assetId);
                                galleryData.append("user_id", proposalObj?.user_id);
                                await postApi(`/proposer/assetResources/`, galleryData)
                            }
                        };
                    } else {
                        continue;
                    }
                }

                // only for parcel create
                if (proposalObj?.selectedListingType === 'parcel' && proposalObj?.parcelsDetails?.length && proposalObj?.isParcelCreated === true) {
                    for (let i of proposalObj?.parcelsDetails) {
                        if (i.image) {
                            let formDataParcel = new FormData();
                            formDataParcel.append("image", i.image, `Parcel_${i.id}.png`);
                            formDataParcel.append("price", i.data);
                            formDataParcel.append("title", `Parcel #${i.id}`);
                            formDataParcel.append("asset_id", res?.data?.data[0]?.id);
                            formDataParcel.append("user_id", proposalObj?.user_id);

                            await postApi(`/proposer/parcelCreate/`, formDataParcel)
                        }
                    }
                } // only for parcel update
                else if (proposalObj?.selectedListingType === 'parcel' && proposalObj?.parcelsDetails?.length && proposalObj?.isParcelCreated == false) {
                    for (let i of proposalObj?.parcelsDetails) {
                        if (i.image) {
                            await assetUpdateParcelApi(`/proposer/parcelUpdate/${i.parcel_id}`, { "price": i.data })
                        }
                    }
                }

                // only for real-estate capital types data 
                if (proposalObj?.selectedCategory === 'real-estate' && proposalObj?.capitalTypeDetails?.length) {
                    let capitalDataPayload = []
                    for (let i of proposalObj?.capitalTypeDetails) {
                        // getting capital name or title
                        const capital_name = Object.keys(i);
                        // getting filter data of capital type matching
                        const capital_type = proposalObj?.capital_type_list?.filter((item) => item.capital_name === capital_name[0])
                        // json for every capital type
                        let capitalData = {
                            "total_raise": i[capital_name].total_raise,
                            "equity": i[capital_name].equity,
                            "senior_duration": i[capital_name].senior_duration,
                            "junior_duration": i[capital_name].junior_duration,
                            "iop_senior": i[capital_name].iop_senior,
                            "iop_junior": i[capital_name].iop_junior,
                            "minimum_investment": i[capital_name].minimum_investment,
                            "rate_of_return": i[capital_name].rate_of_return,
                            "asset_id": assetId,
                            'capital_type_id': capital_type[0].id
                        }
                        // creating array of json
                        capitalDataPayload.push(capitalData)
                    }
                    if (capitalDataPayload?.length && !EditMode) {
                        // sending capital type data 
                        await postApi(`/proposer/capitalInfoAdd`, { 'capital_info': capitalDataPayload })
                    } else if (capitalDataPayload?.length && EditMode) {
                        await postApi(`/proposer/capitalInfoUpdate`, { 'capital_info': capitalDataPayload, "asset_id": assetId, 'capital_type_ids': proposalObj['capital_type_ids'] })
                    }
                }

                // only for funds class types data 
                if (proposalObj?.selectedCategory === 'funds' && proposalObj?.shared_data?.length) {
                    let capitalDataPayload = []
                    for (const [index, i] of proposalObj?.shared_data?.entries()) {
                        // json for every capital type
                        let capitalData = {
                            "class": index + 1,
                            "total_raise": i.total_raise,
                            "minimum_investment": i.minimum_investment,
                            "rate_of_return": i.hurdle_rate,
                            "asset_id": assetId,
                            // 'capital_type_id': proposalObj?.capital_type_ids[0]
                        }
                        // creating array of json
                        capitalDataPayload.push(capitalData)
                    }
                    if (capitalDataPayload?.length) {
                        // sending capital type data 
                        await postApi(`/proposer/sharedClassAdd`, { 'shared_class': capitalDataPayload, "asset_id": assetId })
                    } else if (capitalDataPayload?.length) {
                        await postApi(`/proposer/sharedClassAdd`, { 'shared_class': capitalDataPayload, "asset_id": assetId })
                    }
                }

                if (status === PROJECT_STATUS.SUBMITTED) {
                    setLoading(false);
                    setActiveStep((prevActiveStep) => steps.next);
                } else {
                    console.log("steps.next....", steps.next)
                    console.log("status....", status)
                    if (steps.next === 'pay_stake' && status) {
                        setTimeout(() => {
                            setActiveStep((prevActiveStep) => steps.next);
                            setLoading(false);
                            setDataSaved(true);
                            navigate("/questionnaire-flow", {
                                state: {
                                    category_id: res?.data?.data[0]?.category_id,
                                    investment_type_id: res?.data?.data[0]?.investment_type_id,
                                    id: assetId,
                                    EditMode: true,
                                },
                                replace: true, // This will replace the current entry in the history stack
                            });
                        }, 1000)
                    } else {
                        setLoading(false);
                        navigate("/user/projects", { replace: true })
                    }
                }

                return true
            } catch (error) {
                setLoading(false);
                showSnackbar(error?.message, "error")
                return false
            }
        } else {
            setLoading(false);
            showSnackbar(res?.data?.message, "error")
            return false
        }
    }

    /**
     * Function to manage navigation between previous and next pages based on the current step.
     * @param {Integer} stepper - The status of the current step.
     * @returns {Object} - An object containing the next and previous steps.
     */
    function stepManagement(stepper) {
        let count = stepper
        var my_arr = steFlow['default']

        if (proposalObj?.selectedCategory && proposalObj?.selectedListingType) {
            my_arr = steFlow[proposalObj?.selectedCategory][proposalObj?.selectedListingType]
        }
        var previousStep = my_arr[count]
        var nextActiveStep = my_arr[parseInt(count) + 1]
        if (previousStep === "selection_category" && proposalObj?.other_category) {
            nextActiveStep = "upload_documents"
            previousStep = "selection_category"
        }
        if (previousStep === "select_location" && proposalObj?.other_category) {
            nextActiveStep = "listing_submitted"
            previousStep = "upload_documents"
        }
        return { 'next': nextActiveStep, 'prev': previousStep }
    }

    /**
    * Function to update the current proposal data.
    * @param {Object} data - The new data to be set as the updated proposal data.
    */
    const updateProposalData = (data) => {
        setProposalData(data);
    };

    /**
    * Function to retrieve the current proposal data.
    * @returns {Object} - The current proposal data.
    */
    const getProposalData = () => {
        return proposalObj;
    };

    /**
    * Function to determine the form content component based on the current step in the project proposer flow.
    * @param {String} step - The current step in the project proposer flow.
    * @returns {JSX.Element} - The component corresponding to the current step.
    */
    const formContent = (step) => {
        switch (step) {
            case 'select_category':
                return <SelectAssetType handleNext={handleNext} getProposalData={getProposalData} updateProposalData={updateProposalData} saveProposal={saveProposal} getPrevAsset={getPrevAsset} updateAsset={updateAsset} getProgress={getProgress} EditMode={EditMode} updateAssetDeleted={updateAssetDeleted} />;
            case 'select_listing':
                return <SelectListingType handleNext={handleNext} handleback={handleBack} getProposalData={getProposalData} updateProposalData={updateProposalData} saveProposal={saveProposal} getPrevAsset={getPrevAsset} updateAsset={updateAsset} getProgress={getProgress} updateAssetDeleted={updateAssetDeleted} EditMode={EditMode} />;
            case 'select_entity':
                return <SelectOwnerType handleNext={handleNext} handleback={handleBack} getProposalData={getProposalData} updateProposalData={updateProposalData} saveProposal={saveProposal} getProgress={getProgress} />
            case 'owner_information':
                return <OwnerInformation handleNext={handleNext} handleback={handleBack} getProposalData={getProposalData} updateProposalData={updateProposalData} saveProposal={saveProposal} getProgress={getProgress} />
            case 'selection_category':
                return <SelectAssetSubType handleNext={handleNext} handleback={handleBack} getProposalData={getProposalData} updateProposalData={updateProposalData} saveProposal={saveProposal} getProgress={getProgress} />
            case 'select_location':
                return <AddAssetLocation handleNext={handleNext} handleback={handleBack} getProposalData={getProposalData} updateProposalData={updateProposalData} saveProposal={saveProposal} getProgress={getProgress} />
            case 'select_title':
                return <AddAssetTitle handleNext={handleNext} handleback={handleBack} getProposalData={getProposalData} updateProposalData={updateProposalData} saveProposal={saveProposal} getProgress={getProgress} />
            case 'add_photo':
                return <AddAssetPhotos handleNext={handleNext} handleback={handleBack} getProposalData={getProposalData} updateProposalData={updateProposalData} saveProposal={saveProposal} EditMode={EditMode} getProgress={getProgress} />
            case 'description':
                return <AddAssetDescription handleNext={handleNext} handleback={handleBack} getProposalData={getProposalData} updateProposalData={updateProposalData} saveProposal={saveProposal} getProgress={getProgress} EditMode={EditMode} />
            case 'fraction_details':
                return <RealEstateFractionDetails handleNext={handleNext} handleback={handleBack} getProposalData={getProposalData} updateProposalData={updateProposalData} saveProposal={saveProposal} getProgress={getProgress} />
            case 'fraction_details_fund':
                return <FundsFractionDetails handleNext={handleNext} handleback={handleBack} getProposalData={getProposalData} updateProposalData={updateProposalData} saveProposal={saveProposal} getProgress={getProgress} />
            case 'capital_type_details':
                return <RealEstateCapitalDetails handleNext={handleNext} handleback={handleBack} getProposalData={getProposalData} updateProposalData={updateProposalData} saveProposal={saveProposal} getProgress={getProgress} />
            case 'capital_type_details_fund':
                return <FundsCapitalDetails handleNext={handleNext} handleback={handleBack} getProposalData={getProposalData} updateProposalData={updateProposalData} saveProposal={saveProposal} getProgress={getProgress} />
            case 'upload_documents':
                return <UploadDocuments handleNext={handleNext} handleback={handleBack} getProposalData={getProposalData} updateProposalData={updateProposalData} saveProposal={saveProposal} EditMode={EditMode} getProgress={getProgress} />
            case 'upload_signing_documents':
                return <UploadSigningDocuments handleNext={handleNext} handleback={handleBack} getProposalData={getProposalData} updateProposalData={updateProposalData} saveProposal={saveProposal} EditMode={EditMode} getProgress={getProgress} />
            case 'payment_type':
                return <SelectPaymentType handleNext={handleNext} handleback={handleBack} getProposalData={getProposalData} updateProposalData={updateProposalData} saveProposal={saveProposal} getProgress={getProgress} />
            case 'payment_term':
                return <AcceptPaymentTerms handleNext={handleNext} handleback={handleBack} getProposalData={getProposalData} updateProposalData={updateProposalData} saveProposal={saveProposal} getProgress={getProgress} />
            case 'review_listing':
                return <PreviewListing handleNext={handleNext} handleback={handleBack} getProposalData={getProposalData} updateProposalData={updateProposalData} saveProposal={saveProposal} getProgress={getProgress} />
            case 'pay_stake':
                return <PreviewListing2 handleNext={handleNext} handleback={handleBack} getProposalData={getProposalData} updateProposalData={updateProposalData} saveProposal={saveProposal} getProgress={getProgress} />
            case 'deposit_amount':
                return <DepositAmount handleNext={handleNext} handleback={handleBack} getProposalData={getProposalData} updateProposalData={updateProposalData} saveProposal={saveProposal} getProgress={getProgress} />
            case 'sales_details':
                return <SetSaleDetails handleNext={handleNext} handleback={handleBack} getProposalData={getProposalData} updateProposalData={updateProposalData} saveProposal={saveProposal} getProgress={getProgress} />
            case 'loan_details':
                return <SetLoanDetails handleNext={handleNext} handleback={handleBack} getProposalData={getProposalData} updateProposalData={updateProposalData} saveProposal={saveProposal} getProgress={getProgress} />
            case 'art_project_details':
                return <ArtDetails handleNext={handleNext} handleback={handleBack} getProposalData={getProposalData} updateProposalData={updateProposalData} saveProposal={saveProposal} getProgress={getProgress} />
            case 'edit_prices':
                return <EditParcelPrices handleNext={handleNext} handleback={handleBack} getProposalData={getProposalData} updateProposalData={updateProposalData} saveProposal={saveProposal} getProgress={getProgress} />
            case 'gold_details':
                return <GoldDetails handleNext={handleNext} handleback={handleBack} getProposalData={getProposalData} updateProposalData={updateProposalData} saveProposal={saveProposal} getProgress={getProgress} />
            // case 'watch_details':
            //     return <WatchDetails handleNext={handleNext} handleback={handleBack} getProposalData={getProposalData} updateProposalData={updateProposalData} saveProposal={saveProposal} getProgress={getProgress} />
            case 'asset_car_details':
                return <LuxuryCarDetails handleNext={handleNext} handleback={handleBack} getProposalData={getProposalData} updateProposalData={updateProposalData} saveProposal={saveProposal} getProgress={getProgress} />
            case 'watch_details1':
                return <WatchDetails1 handleNext={handleNext} handleback={handleBack} getProposalData={getProposalData} updateProposalData={updateProposalData} saveProposal={saveProposal} getProgress={getProgress} />
            case 'watch_details2':
                return <WatchDetails2 handleNext={handleNext} handleback={handleBack} getProposalData={getProposalData} updateProposalData={updateProposalData} saveProposal={saveProposal} getProgress={getProgress} />
            case 'parcel_details':
                return <SetParcelDetails handleNext={handleNext} handleback={handleBack} getProposalData={getProposalData} updateProposalData={updateProposalData} saveProposal={saveProposal} getProgress={getProgress} />
            case 'listing_submitted':
                return <ListingSubmitted handleNext={handleNext} handleback={handleBack} getProposalData={getProposalData} updateProposalData={updateProposalData} saveProposal={saveProposal} />
            default:
                return <div>404 : Not Found</div>;
        }
    }

    return (
        <Box>
            {(assetData?.id && EditMode) ? formContent(activeStep) : (!EditMode) ? formContent(activeStep) : ''}
            {loading && <Backdrop open={loading} className='stepper backdrop-content'>
                <CircularProgress color='primary' />
                <Typography variant="body1" color="primary">
                    Wait a moment...
                </Typography>
            </Backdrop>
            }
            <CustomDialog isOpen={showDialog} onClose={handleCloseDialog} />
        </Box>
    );
};

export default Stepper;