// variable to switch between testnet and polygon
var isMainnetProduction = false;
/**
* @file overview This file contains the contract addresses and ABI's
*/

/**
* @constant {object} CONTRACTS - The contract addresses
*/
export const CONTRACTS = {
    // Token Contract
    TOKEN: isMainnetProduction ? "0xdc66010a5558473Fb91b21666B56E4D13Fd7254A" : '0xd020b1566d4E351D0cF6F3f8111602F337C94dD2',
    USDT: isMainnetProduction ? '0x05d032ac25d322df992303dca074ee7392c117b9' : '0x84ff23aad7520F2970F43EB1B567e9A1e0B5a902',
    USDC: isMainnetProduction ? '0xb62f35b9546a908d11c5803ecbba735abc3e3eae' : '0xa06019820a5FC8799Ff37AA5c73D4564C88fd4fA',
    DAI: isMainnetProduction ? '0x6c851f501a3f24e29a8e39a29591cddf09369080' : '0x9c7e9B3BEa668a22ed4c80240bCc81892aFcAa87',

    // NFT
    NFT_ADDRESS: isMainnetProduction ? '0x93779819c6CaC7Cc625B619B614F639433c9C615' : "0xcAdD888d1D6eC948BFE46F556523C584686Ee1E3",

    // NFT BluPrint
    BLUPRINT: isMainnetProduction ? "" : "0xB90D691383b303e9e1ba40611C5fF04447b45b32",

    // Admin, User Management and DIC Management (Only used at admin side)
    USER_MANAGE: isMainnetProduction ? "0xCf6A32dB8b3313b3d439CE6909511c2c3415fa32" : '0x94c263D4f58611547f6E195A2cd1D7504bd10a08',
    DIC_MANAGE: isMainnetProduction ? "0xf54B2AEF894cfF5511A5722Acaac0409F2F2d89" : '0x33af4F67eD502632BE6EcD19aFE471E3B14FdCa6',
    ADMIN: isMainnetProduction ? "" : '0x6fE0D242062D0f1e10D1eA87073Ed7D2aFff57b6',

    // Staking Contract
    STAKE_CONTRACT_SALE: isMainnetProduction ? "" : "0x5D06b8F0a062A560938361060B611a5c1f574c5F",
    STAKE_CONTRACT_PARCEL: isMainnetProduction ? "" : "0x2EDE5eB4fD02301Ae13714a3541874bE5295Db64",
    STAKE_CONTRACT_LOAN: isMainnetProduction ? "" : "0xd73bd5b48801C3590C5CbD2753655d97b0A8b473",
    STAKE_CONTRACT_OPEN_FUND: isMainnetProduction ? "" : "0x21D5Af3287c1C7E8827c39828f86d0A99ebcc1c7",
    STAKE_CONTRACT_SPACE_X_FUND: isMainnetProduction ? "" : "0xB98E02161ea2b05eeab5C4A5b23447B4548fBA76",

    // Create Project, Complete Review, Complete Vote, Suggestion term and Approve Suggestions
    // Create Proposal
    CREATE_PROPOSAL_SALE: isMainnetProduction ? "" : '0x9eb324de45986BFb9eAAA56147C4d144899d8AFB',
    CREATE_PROPOSAL_PARCEL: isMainnetProduction ? "" : '0x5FE4A3E09D74442531F350ca94090f4110107799',
    CREATE_PROPOSAL_LOAN: isMainnetProduction ? "" : '0x4aA92cED812E194b349e64C57ccE6E7730EAa820',
    CREATE_PROPOSAL_OPEN_FUND: isMainnetProduction ? "" : '0xB667e0e5ab83b5564E07C54248ff306dad3A1C10',
    CREATE_PROPOSAL_SPACE_X_FUND: isMainnetProduction ? "" : '0x5724f4Bec8581E35bf6Bfa13400bDF74614a7249',
    // DIC Proposal
    DIC_PROPOSAL_SALE: isMainnetProduction ? "" : '0xE0d272aa44fbFf8D990da1B7Cf1B0e24FE4496C6',
    DIC_PROPOSAL_PARCEL: isMainnetProduction ? "" : '0xBf3b0Ad8a1cBc9123bEd3BC9B334CcE703b792FF',
    DIC_PROPOSAL_LOAN: isMainnetProduction ? "" : '0x76e0F8c5D88d2f280394a536Ed4F9aE387dE0C0F',
    DIC_PROPOSAL_OPEN_FUND: isMainnetProduction ? "" : '0x0c3Dd78eB3e2144424db1c5981Fb1d6Ab454dE10',
    DIC_PROPOSAL_SPACE_X_FUND: isMainnetProduction ? "" : '0x374407C4a7b89A37826CbE9156083F79F532c9dB',

    // Watch, GOLD, ASSET
    WATCH_MARKETPLACE: isMainnetProduction ? '0xC5e2063FBb77977a50703fCFFb54f06B4645e6cf' : "0x778BC2c9D5D394F7eD9bce67a0f7b41d1E590DFb",
    WATCH_DEBT_OFFER_ADDRESS: isMainnetProduction ? '0xBFE15ae2D2a468c320d0075B79d7Bf1e5f9163BE' : "0x89E101247Bb40E1f8376d883FF2eBD2f05eB9730",
    WATCH_REPAYMENT_ADDRESS: isMainnetProduction ? '0x36eF8d1C6b536C08095ABaAE8a4D3EFCD3653825' : "0xa7c8e50f9D6bE0FD9E368028E83c4a4C6D9C4123",

    // Art - Sale
    ART_SALE_MARKETPLACE: isMainnetProduction ? '0x0eeb25c56F44a854F3cA9Ffe06272194c2069A19' : "0xF4264b84BEb59270754F4650735Efa64f16da44F",

    // Art - Parcel
    ART_PARCEL: isMainnetProduction ? '0x1483879E808b01F56AFb8C0E23fAe991FEE32dAd' : "0xD8F3050174A570518334c8977EeceEa236e22126",

    // SPV
    SPV_MARKETPLACE: isMainnetProduction ? '0xa81Ec35dF8DB8AebcB56228803Fa278dd7d7155F' : "0xA5C38ED1D4c90d097cBdD39bD9a15Cfb5Caa5bf9",
    SPV_DEBT_OFFER_ADDRESS: isMainnetProduction ? '0x4C40EeC05dD43078E7419f21537dA3C9A0BE0CDD' : "0x97191FBCd916f554C2530FC056494119Bb26C02e",
    SPV_DEBT_REPAYMENT_ADDRESS: isMainnetProduction ? '0x004905e36FC9E194a14493F5AE183f867fe19da0' : "0x1E712fE86f32889B34D6A107636535fD37B487F8",
    SPV_EQUITY_OFFER_ADDRESS: isMainnetProduction ? '0xa66012bDb82585C5697DCff9EFde5662116A2bf1' : "0xD3E808de1CB16D727C0213A0d0cc592df50DA3b9",
    SPV_EQUITY_REPAYMENT_ADDRESS: isMainnetProduction ? '0x66F51B60b8bF6A11B9F27266ac2a35131E61D5Ae' : "0x4a32407Ab6939216ac8A11Fdc5C3Ee88D7b54147",

    // Funds
    FUNDS_MARKETPLACE: isMainnetProduction ? '0x81C0a590450a64409e6651eb13A0fC7ec8FD4B89' : '0x238563BEF35708009E04DE56d04C5Dda03d426d1',
    FUNDS_INVESTMENT: isMainnetProduction ? '0xA08ce7651404d48ad068ba057E1bFD97Bb96163E' : '0x8AfDEd7BbE2E68Bba4ef52857cFA8367D013a0E6',
    FUNDS_DISTRIBUTION: isMainnetProduction ? '0xB8e7d039706bd5415d0509e0e6E40A0F3C655643' : '0x8694eeEFf38EdA180e92E5ebb4C94217fb1eD3E1',

    // Funds (space-x)
    FUNDS_SPACE_X_MARKETPLACE: isMainnetProduction ? '' : '0xA0d830Eb48660eE9c73c517099826f82119B3998',
    FUNDS_SPACE_X_INVESTMENT: isMainnetProduction ? '' : '0x09e6204a098eCE0783B3784C46836D516B257c84',
    FUNDS_SPACE_X_DISTRIBUTION: isMainnetProduction ? '' : '0xA0322dc60CE4112368A6F0d2aB24503213911B19',

    // Helper module and calculate emi
    HELPER_MODULE: isMainnetProduction ? '0xB9b8858eD6898e68b55cC1E9766674C9D9802675' : "0x1A1A5d1DCFE9A9E44df0A3f00dB170e97AC02256",
    CALCULATE_EMI: isMainnetProduction ? '0xACC3FA34498373eaE9Dc92409D2C67E04F876619' : "0xdEA0453Fc71738ab0C61F735aD5eb3FaD97E87fA",
}


/**
 * @constant {object} CONTRACT_ABI - The contract ABI's
 */
export const CONTRACT_ABI = {
    [CONTRACTS.NFT_ADDRESS]: isMainnetProduction ? require("./abi/NFTRT.json") : require("./abi/NFT.json"),
    [CONTRACTS.TOKEN]: isMainnetProduction ? require("./abi/TokenRT.json") : require("./abi/Token.json"),
    [CONTRACTS.USDT]: isMainnetProduction ? require("./abi/TokenUSDTRT.json") : require("./abi/TokenUSDT.json"),
    [CONTRACTS.USDC]: isMainnetProduction ? require("./abi/TokenUSDCRT.json") : require("./abi/TokenUSDC.json"),
    [CONTRACTS.DAI]: isMainnetProduction ? require("./abi/TokenDIART.json") : require("./abi/TokenDIA.json"),
    [CONTRACTS.BLUPRINT]: isMainnetProduction ? require("./abi/BluprintNft.json") : require("./abi/BluprintNftRT.json"),

    [CONTRACTS.USER_MANAGE]: isMainnetProduction ? require("./abi/UserRT.json") : require("./abi/User.json"),
    [CONTRACTS.DIC_MANAGE]: isMainnetProduction ? require("./abi/DicManageRT.json") : require("./abi/DicManage.json"),
    [CONTRACTS.ADMIN]: isMainnetProduction ? require("./abi/AdminRT.json") : require("./abi/Admin.json"),

    [CONTRACTS.STAKE_CONTRACT_SALE]: isMainnetProduction ? require("./abi/StakeContractSaleRT.json") : require("./abi/StakeContractSale.json"),
    [CONTRACTS.STAKE_CONTRACT_PARCEL]: isMainnetProduction ? require("./abi/StakeContractParcelRT.json") : require("./abi/StakeContractParcel.json"),
    [CONTRACTS.STAKE_CONTRACT_LOAN]: isMainnetProduction ? require("./abi/StakeContractLoanRT.json") : require("./abi/StakeContractLoan.json"),
    [CONTRACTS.CREATE_PROPOSAL_SALE]: isMainnetProduction ? require("./abi/CreateProposalSaleRT.json") : require("./abi/CreateProposalSale.json"),
    [CONTRACTS.DIC_PROPOSAL_SALE]: isMainnetProduction ? require("./abi/DicProposalSaleRT.json") : require("./abi/DicProposalSale.json"),
    [CONTRACTS.CREATE_PROPOSAL_PARCEL]: isMainnetProduction ? require("./abi/CreateProposalParcelRT.json") : require("./abi/CreateProposalParcel.json"),
    [CONTRACTS.DIC_PROPOSAL_PARCEL]: isMainnetProduction ? require("./abi/DicProposalParcelRT.json") : require("./abi/DicProposalParcel.json"),
    [CONTRACTS.CREATE_PROPOSAL_LOAN]: isMainnetProduction ? require("./abi/CreateProposalLoanRT.json") : require("./abi/CreateProposalLoan.json"),
    [CONTRACTS.DIC_PROPOSAL_LOAN]: isMainnetProduction ? require("./abi/DicProposalLoanRT.json") : require("./abi/DicProposalLoan.json"),
    [CONTRACTS.WATCH_MARKETPLACE]: isMainnetProduction ? require("./abi/WatchMarketplaceRT.json") : require("./abi/WatchMarketplace.json"),
    [CONTRACTS.WATCH_DEBT_OFFER_ADDRESS]: isMainnetProduction ? require("./abi/WatchLoanOfferRT.json") : require("./abi/WatchLoanOffer.json"),
    [CONTRACTS.WATCH_REPAYMENT_ADDRESS]: isMainnetProduction ? require("./abi/WatchLoanRepaymentRT.json") : require("./abi/WatchLoanRepayment.json"),
    [CONTRACTS.ART_SALE_MARKETPLACE]: isMainnetProduction ? require("./abi/ArtSaleMarketplaceRT.json") : require("./abi/ArtSaleMarketplace.json"),
    [CONTRACTS.ART_PARCEL]: isMainnetProduction ? require("./abi/ArtParcelMarketplaceRT.json") : require("./abi/ArtParcelMarketplace.json"),
    [CONTRACTS.SPV_MARKETPLACE]: isMainnetProduction ? require("./abi/SPVMarketplaceRT.json") : require("./abi/SPVMarketplace.json"),
    [CONTRACTS.SPV_DEBT_OFFER_ADDRESS]: isMainnetProduction ? require("./abi/SPVDebtOfferRT.json") : require("./abi/SPVDebtOffer.json"),
    [CONTRACTS.SPV_DEBT_REPAYMENT_ADDRESS]: isMainnetProduction ? require("./abi/SPVDebtRepaymentRT.json") : require("./abi/SPVDebtRepayment.json"),
    [CONTRACTS.SPV_EQUITY_OFFER_ADDRESS]: isMainnetProduction ? require("./abi/SPVEquityInvestmentRT.json") : require("./abi/SPVEquityInvestment.json"),
    [CONTRACTS.SPV_EQUITY_REPAYMENT_ADDRESS]: isMainnetProduction ? require("./abi/SPVEquityRepaymentRT.json") : require("./abi/SPVEquityRepayment.json"),

    // For Funds
    [CONTRACTS.FUNDS_MARKETPLACE]: isMainnetProduction ? require("./abi/FundsMarketplaceRT.json") : require("./abi/FundsMarketplace.json"),
    [CONTRACTS.FUNDS_INVESTMENT]: isMainnetProduction ? require("./abi/FundsInvestmentRT.json") : require("./abi/FundsInvestment.json"),
    [CONTRACTS.FUNDS_DISTRIBUTION]: isMainnetProduction ? require("./abi/FundsDistributionRT.json") : require("./abi/FundsDistribution.json"),

    // For Funds space-x
    [CONTRACTS.FUNDS_SPACE_X_MARKETPLACE]: isMainnetProduction ? require("./abi/FundSpaceXMarketplaceRT.json") : require("./abi/FundSpaceXMarketplace.json"),
    [CONTRACTS.FUNDS_SPACE_X_INVESTMENT]: isMainnetProduction ? require("./abi/FundSpaceXInvestmentRT.json") : require("./abi/FundSpaceXInvestment.json"),
    [CONTRACTS.FUNDS_SPACE_X_DISTRIBUTION]: isMainnetProduction ? require("./abi/FundSpaceXDistributionRT.json") : require("./abi/FundSpaceXDistribution.json"),

    // For Funds
    [CONTRACTS.STAKE_CONTRACT_OPEN_FUND]: isMainnetProduction ? require("./abi/FundStakingContractRT.json") : require("./abi/FundStakingContract.json"),
    [CONTRACTS.CREATE_PROPOSAL_OPEN_FUND]: isMainnetProduction ? require("./abi/FundCreateProposalRT.json") : require("./abi/FundCreateProposal.json"),
    [CONTRACTS.DIC_PROPOSAL_OPEN_FUND]: isMainnetProduction ? require("./abi/FundDicProposalRT.json") : require("./abi/FundDicProposal.json"),

    // For Funds space-x
    [CONTRACTS.STAKE_CONTRACT_SPACE_X_FUND]: isMainnetProduction ? require("./abi/FundSpaceXStakingContractRT.json") : require("./abi/FundSpaceXStakingContract.json"),
    [CONTRACTS.CREATE_PROPOSAL_SPACE_X_FUND]: isMainnetProduction ? require("./abi/FundSpaceXCreateProposalRT.json") : require("./abi/FundSpaceXCreateProposal.json"),
    [CONTRACTS.DIC_PROPOSAL_SPACE_X_FUND]: isMainnetProduction ? require("./abi/FundSpaceXDicProposalRT.json") : require("./abi/FundSpaceXDicProposal.json"),

    // For Space X Fund token get
    [CONTRACTS['SPACE_X_FUND_TOKEN']]: isMainnetProduction ? require("./abi/SpaceXFundToken.json") : require("./abi/SpaceXFundToken.json"),

    [CONTRACTS.HELPER_MODULE]: isMainnetProduction ? require("./abi/HelperModuleRT.json") : require("./abi/HelperModule.json"),
    [CONTRACTS.CALCULATE_EMI]: isMainnetProduction ? require("./abi/CalculateEMIRT.json") : require("./abi/CalculateEMI.json"),
}