import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Box, Button, Typography, Grid, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import AddIcon from "@mui/icons-material/Add";
import { GetApiParam } from "../../../Api/Api";
import { getUserDataFromLocalStorage } from "../../../Services/localstorage.service";
import "./MyprojectPosting.css";
import CommonBackdropLoader from "../../../Components/Common/CommonBackdropLoader";

const theme = createTheme({
  palette: {
    close: {
      main: '#94a3b8',
    },
    posted: {
      main: '#10b981'
    }
  },
});

const MyprojectPosting = () => {
  const [projectServiceDetails, setProjectServiceDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const localData = getUserDataFromLocalStorage();

  /**
   * UseEffect Function To call api for getting service details
   */

  useEffect(() => {
    async function getProjectServicesDetail() {
      try {
        const res = await GetApiParam("/services/sp_projectRequirement/", {
          user_id: localData && localData?.id,
          user_include: true,
          login_user_id: localData.id,
        });
        setProjectServiceDetails(res?.data?.data ? res.data.data : []);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
    getProjectServicesDetail();
    setLoading(true);
  }, []);

  /**
   * Function to handle serviceProviderPostinfo
   * @param {*} serviceProviderPostinfo
   */
  const handleEditButon = (serviceProviderPostinfo) => {
    const previewData = {
      Title: serviceProviderPostinfo?.project_title,
      Skills: serviceProviderPostinfo?.skills ? serviceProviderPostinfo?.skills : serviceProviderPostinfo?.skillData,
      ProjectSize: serviceProviderPostinfo?.project_size,
      ProjectTime: serviceProviderPostinfo?.work_time,
      ExperienceLevel: serviceProviderPostinfo?.level_of_experience,
      HourlyRateFrom:
        serviceProviderPostinfo?.budget === "Hourly Rate"
          ? serviceProviderPostinfo?.budget_from
          : "",
      HourlyRateTo:
        serviceProviderPostinfo?.budget === "Hourly Rate"
          ? serviceProviderPostinfo?.budget_to
          : "",
      ProjectBudgetFrom:
        serviceProviderPostinfo?.budget === "Project Budget"
          ? serviceProviderPostinfo?.budget_from
          : "",
      ProjectBudgetTo:
        serviceProviderPostinfo?.budget === "Project Budget"
          ? serviceProviderPostinfo?.budget_to
          : "",
      projectBudget: serviceProviderPostinfo?.budget,
      Description: serviceProviderPostinfo?.describe_services,
      Category: serviceProviderPostinfo?.categoryDetails?.title,
      CategoryId: serviceProviderPostinfo?.categoryDetails?.id,
      Document: serviceProviderPostinfo?.sp_media_tables?.map((documents) => {
        return documents;
      }),
      newprojectId: serviceProviderPostinfo?.raceProject ? serviceProviderPostinfo?.raceProject : null
    };

    navigate("/service-proposer-dashboard/postProject", {
      state: {
        previewData,
        form: "MyPorjectPosting",
        project_id: serviceProviderPostinfo.id,
      },
    });
  };

  const handleClick = (servicedetail) => {
    navigate(`/service-proposer-dashboard/viewprojectposting/${servicedetail.id}`, {
      state: {
        servicedetail
      },
    });
  };

  return (
    <>
      <Box className="main-container service-proposer-myproject">
        <Box sx={{ margin: "-10px 0px 30px 0px" }}>
          <Typography component="h4" className="title font-28">
            My Projects
          </Typography>
        </Box>
        <Grid container spacing={2}>
          {projectServiceDetails?.length ? (
            projectServiceDetails?.map((servicedetail) => {
              return (
                <>
                  <Grid xs={12} md={6} sm={12}>
                    <Box className="post-projct-main" mt={2}>
                      {servicedetail?.status === 1 || servicedetail?.status === 2 ? (
                        <Box className="posting-project-btn" justifyContent="space-between" alignItems="center" display="flex">
                          {servicedetail?.status === 1 &&
                            <ThemeProvider theme={theme}>
                              <Button variant="contained" color="posted">Posted</Button>
                            </ThemeProvider>
                          }
                          {servicedetail?.status === 2 &&
                            <ThemeProvider theme={theme}>
                              <Button variant="contained" color="close">Closed</Button>
                            </ThemeProvider>
                          }
                          <Box>
                            <IconButton
                              className="service-provider-draft-posting-button"
                              onClick={() => handleClick(servicedetail)}
                            >
                              <RemoveRedEyeIcon style={{ color: "#798686" }} />
                            </IconButton>
                          </Box>
                        </Box>
                      ) : (
                        <Box
                          className="draft-section"
                          display="flex"
                          alignItems="center"
                        >
                          <Box flex="1" className="draft-project-btn">
                            <Button variant="contained">Draft</Button>
                          </Box>
                          <Box>
                            <IconButton
                              className="service-provider-draft-posting-button"
                              onClick={() => handleEditButon(servicedetail)}
                            >
                              <EditIcon />
                            </IconButton>
                          </Box>
                        </Box>
                      )}
                      <Box sx={{ cursor: "pointer" }} onClick={() => handleClick(servicedetail)}>
                        <Box className="project-title">
                          <Typography component="h4" className="title font-16">
                            {servicedetail?.project_title}
                          </Typography>
                          <Typography
                            component="h5"
                            className="sub-title font-12"
                          >
                            {servicedetail?.describe_services}
                          </Typography>
                        </Box>
                        <Box className="post-project-category">
                          <Typography component="h4" className="title font-16">
                            Category
                          </Typography>
                          <Typography
                            component="h5"
                            className="sub-title font-12"
                          >
                            {servicedetail?.categoryDetails?.title}
                          </Typography>
                        </Box>
                        <Box className="post-project-skill">
                          <Typography component="h4" className="title font-16">
                            Skills
                          </Typography>
                          <Box className="button-div">
                            {servicedetail?.skills ? servicedetail?.skills?.map((skill) => {
                              return (
                                <Button
                                  variant="outlined"
                                  startIcon={<AddIcon />}
                                >
                                  {skill?.skill_name}
                                </Button>
                              );
                            })
                              : servicedetail?.skillData?.map((skill) => {
                                return (
                                  <Button
                                    variant="outlined"
                                    startIcon={<AddIcon />}
                                  >
                                    {skill.skill_name}
                                  </Button>
                                );
                              })}
                          </Box>
                        </Box>
                        <Box className="post-project-scope">
                          <Typography component="h4" className="title font-16">
                            Scope
                          </Typography>
                          <Typography
                            component="h5"
                            className="sub-title font-12"
                          >
                            {servicedetail?.project_size},{" "}
                            {servicedetail?.work_time} months,{" "}
                            {servicedetail?.level_of_experience}
                          </Typography>
                        </Box>
                        <Box className="post-project-budget">
                          <Typography component="h4" className="title font-16">
                            Budget
                          </Typography>
                          {servicedetail?.budget === "Hourly Rate" ? (
                            <Typography
                              component="h5"
                              className="sub-title font-12"
                            >
                              ${servicedetail?.budget_from} - $
                              {servicedetail?.budget_to} /hr
                            </Typography>
                          ) : (
                            <Typography
                              component="h5"
                              className="sub-title font-12"
                            >
                              ${servicedetail?.budget_from} - $
                              {servicedetail?.budget_to}
                            </Typography>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </>
              );
            })
          ) : (
            <Typography className="font-14 " ml={2}>
              No Projects Found.
            </Typography>
          )}
        </Grid>
      </Box>
      <CommonBackdropLoader loading={loading} />
    </>
  );
};

export default MyprojectPosting;
