import React, { useEffect, useState } from "react";
import { Box, Button, Chip, Grid, Table, TableBody, TableContainer, TableHead, TableRow, Typography, TableCell, tableCellClasses, Paper, Menu, Tooltip, MenuItem, IconButton } from "@mui/material";
import { SwapHorizontalCircle, Download, Upload, AccountBalanceWallet, MoreHoriz, HttpsOutlined, Payments, Insights, Logout, LockOutlined } from "@mui/icons-material";
import Pagination from '@mui/material/Pagination';
import styled from "@emotion/styled";
import { useAccount, useDisconnect } from "wagmi";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "../../Contexts/SnackbarContext";
// import ConnectWallet from "../../Web3/ConnectWallet";
import ConnectWalletInvestor from "../../Web3/ConnectWalletInvestor";
import { formatNumber } from "../../Components/Common/USFormat";
import { checkBalance } from "../../Components/SmartContract/checkBalance";
import { getUserDataFromLocalStorage } from "../../Services/localstorage.service";
import { GetApi } from "../../Api/Api";
import { formatDate } from "../../Utils/utils";


// Styles for table columns
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#05131B',
    color: '#94A3B8',
    borderBottom: '0px',
    '&:first-of-type': {
      borderRadius: '16px 0px 0px 16px',
    },
    '&:last-of-type': {
      borderRadius: '0px 16px 16px 0px',
    },
  },

  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: theme.palette.common.white,
    borderBottom: '0px'
  },
}));

// Styles for table rows
const StyledTableRow = styled(TableRow)(({ }) => ({
}));

const SpvWallet = () => {
  const navigate = useNavigate();
  const { address } = useAccount();
  const { showSnackbar } = useSnackbar();
  const [walletBalance, setWalletBalance] = useState(0);
  const { disconnect } = useDisconnect();
  const userData = getUserDataFromLocalStorage();
  const [jobList, setJobList] = useState([]);
  const [thirtyDaysCreditAmount, setThirtyDaysCreditAmount] = useState(0);
  const [thirtyDaysDebitAmount, setThirtyDaysDebitAmount] = useState(0);
  const [totalStakingAmount, setTotalStakingAmount] = useState(0);
  const [totalDabitAmount, setTotalDabitAmount] = useState(0);
  const [totalCreditAmount, setTotalCreditAmount] = useState(0);
  const [totalWithdrawableAmount, setTotalWithdrawableAmount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [paginationData, setPaginationData] = useState({
    "page": 1,
    "pageSize": 0,
    "totalCount": 0,
    "totalPages": 0
  });

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const selected = '';

  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const open1 = Boolean(anchorEl1);

  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };

  const handleClose1 = () => {
    setAnchorEl1(null);
  };

  // getting user wallet balance
  useEffect(() => {
    if (address) {
      async function getBalance() {
        const balanceOf = await checkBalance(address, 'DAI');
        setWalletBalance(formatNumber(parseFloat(Number(balanceOf) / 1e18)))
      }
      getBalance();
    }
  }, [address])

  //Wallet connection code start
  const [walletDialogOpen, setWalletDialogOpen] = useState(false);

  const handleClickOpen = () => {
    setWalletDialogOpen(true);
  };

  const handleCloseDialog = (value) => {
    setWalletDialogOpen(false);
  };

  /**
   * gets called when a wallet is connect successfully 
   * @param {string} acc 
   */
  const walletLogin = async (acc) => {
    if (acc) {
      // dispatch(update({ wallet_address: acc?.toLowerCase() }));
      showSnackbar('Wallet connected successfully', 'success');
    }
  }

  const handleWalletDisconnect = () => {
    disconnect();
    handleClose1();
    showSnackbar("Wallet disconnected", "success");
  };
  //Wallet connection code end

  const fetchData = async () => {
    let url = `/services/spvTransaction?user_id=${userData.id}&page=${currentPage}&rowperpage=${itemsPerPage}`;
    GetApi(url).then((res) => {
      if (res?.status === 200) {
        if (res.data && res.data.data && res.data.data.length > 0) {
          setJobList(res.data.data);
          setPaginationData(res.data.pagination)
          if (res.data.statisticsData) {
            // Set thirty days credit amount
            setThirtyDaysCreditAmount(res.data.statisticsData?.totalCreditAmountThirtyDaysAgo);
            // Set thirty days debit amount
            setThirtyDaysDebitAmount(res.data.statisticsData?.totalDebitAmountThirtyDaysAgo);
            // Set total staking amount
            setTotalStakingAmount(res.data.statisticsData?.totalStakingAmount);
            // Set total credit amount
            setTotalCreditAmount(res.data.statisticsData?.totalCreditAmount);
            // Set total debit amount
            setTotalDabitAmount(res.data.statisticsData?.totalDabitAmount);
            // Set total withdrawable amount
            setTotalWithdrawableAmount(res.data.statisticsData?.totalWithdrawableAmount);
          }
        } else {
          setJobList([]);
          setPaginationData({
            "page": 1,
            "pageSize": 0,
            "totalCount": 0,
            "totalPages": 0
          })
        }
      }
    })
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    if (userData.id)
      fetchData();
  }, [currentPage]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  return (
    <Box className=" dashboard-post-project-container service-proposer-wallet">
      <Box className="dashboard-overview-right">
        <Box className="wallet-container">
          <Box className="wallet-heading">
            <Typography component="h4" className="title">
              Member ID: {userData?.member_id}
            </Typography>
            <Typography component="p" className="sub-title">
              Hi, {userData?.first_name} {userData?.last_name} 👋🏼
            </Typography>
          </Box>
          <Box sx={{ flexGrow: 1, mt: 2 }} className="">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={8}>
                <Box className="connect-wallet-div">
                  <Box className="wallet-div-left">
                    <AccountBalanceWallet className="account-balance" />
                    <Typography component="p" className="title">
                      {address ? address : ""}
                      {/* "0x71C7656EC7ab88b098defB751B7401B5f6d8976F" */}
                    </Typography>
                  </Box>
                  {address ?
                    <Box className="wallet-div-right service-provider">
                      <Button
                        className="wallet-btn-service-provider"
                        sx={{ ml: 1.5 }}
                        id="fade-button"
                        aria-controls={open1 ? "fade-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open1 ? "true" : undefined}
                        onClick={handleClick1
                        }
                      >Connected</Button>
                      <Menu
                        id="fade-menu"
                        MenuListProps={{
                          "aria-labelledby": "fade-button",
                        }}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                        anchorEl={anchorEl1}
                        open={open1}
                        onClose={handleClose1}
                        // TransitionComponent={Fade}
                        className="dropdown-menu dropdown-menu-sp"
                      >
                        <Button
                          onClick={() => {
                            handleWalletDisconnect();
                          }}
                          className=""
                          style={{
                            width: "186px",
                            display: "flex",
                            gap: "20px",
                            color: "white",
                          }}
                        >
                          <Logout fontSize="small" />
                          Disconnect
                        </Button>
                      </Menu>
                    </Box>
                    :
                    <Button className="sp-wallet-connect-btn btn-rounded" onClick={handleClickOpen}>
                      Connect Wallet
                    </Button>
                  }
                </Box>
                <Typography className="sp-wallet-balance-text">
                  Balance: {walletBalance} RT
                </Typography>
                <Box className="your-rewards-container">
                  <Box className="top-view">
                    <Typography className="title" component="h4">
                      Your Rewards
                    </Typography>
                    <Box className=''>
                      <IconButton onClick={handleClick}>
                        <MoreHoriz className="text-white" />
                      </IconButton>
                    </Box>
                    <Menu
                      id="fade-menu"
                      MenuListProps={{
                        "aria-labelledby": "fade-button",
                      }}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      className="dropdown-dic-menu dic-wallet-dropdown"
                    >
                      <MenuItem
                        onClick={handleClose}
                        className={
                          selected === "withdraw"
                            ? "active"
                            : ""
                        }
                      >
                        <Upload />
                        <Typography ml={'12px'} fontWeight={'500'}> Withdraw</Typography>
                      </MenuItem>
                      <MenuItem
                        onClick={handleClose}
                        className={
                          selected === "stack"
                            ? "active"
                            : ""
                        }
                      >
                        <LockOutlined />
                        <Typography ml={'12px'} fontWeight={'500'}> Stack</Typography>
                      </MenuItem>
                    </Menu>
                  </Box>
                  <Grid container spacing={2}>
                    <Grid item md={3} sm={6} xs={12}>
                      <Box className="reward-card">
                        <Box className="payment-icon-main">
                          <Box className="payment-icon">
                            <Payments className="icon" />
                          </Box>
                        </Box>
                        <Box className="payment-card-title">{walletBalance} RT</Box>
                        <Box className="payment-card-subtitle">
                          Wallet Balance
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item md={3} sm={6} xs={12}>
                      <Box className="reward-card">
                        <Box className="payment-icon-main">
                          <Box className="payment-icon">
                            <HttpsOutlined className="icon" />
                          </Box>
                        </Box>
                        <Box className="payment-card-title">{totalStakingAmount} RT</Box>
                        <Box className="payment-card-subtitle">
                          Total Staked
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item md={3} sm={6} xs={12}>
                      <Box className="reward-card">
                        <Box className="payment-icon-main">
                          <Box className="payment-icon">
                            <Insights className="icon" />
                          </Box>
                        </Box>
                        <Box className="payment-card-title">{totalCreditAmount} RT</Box>
                        <Box className="payment-card-subtitle">
                          Total Earnings
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item md={3} sm={6} xs={12}>
                      <Box className="reward-card">
                        <Box className="payment-icon-main">
                          <Box className="payment-icon">
                            <SwapHorizontalCircle className="icon" />
                          </Box>
                        </Box>
                        <Box className="payment-card-title">{totalWithdrawableAmount} RT</Box>
                        <Box className="payment-card-subtitle">
                          Withdrawable
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <Box className="right-section">
                  <Typography>30-Day Wallet Statistics</Typography>
                  <Box className="list-data">
                    <Box className="icons">
                      <Download />
                    </Box>
                    <Typography ml={"12px"} fontWeight={"500"}>
                      In
                    </Typography>
                  </Box>
                  <Box>
                    <Box className="progress-data">
                      <Box className="progress" style={{ width: "0%" }} />
                      <Box className={"progress-empty"} style={{ width: '100%' }}>
                        <Box className='progress-real' style={{ width: `${((thirtyDaysCreditAmount ? thirtyDaysCreditAmount : 0) * 100 / (thirtyDaysCreditAmount + thirtyDaysDebitAmount >= 1 ? thirtyDaysCreditAmount + thirtyDaysDebitAmount : 1) ?? 0).toFixed(2)}%` }} />
                      </Box>
                    </Box>
                    <Box className="sp-in-out-amount">
                      <Typography>{(thirtyDaysCreditAmount ? thirtyDaysCreditAmount : 0)} RT</Typography>
                      <Typography>{((thirtyDaysCreditAmount ? thirtyDaysCreditAmount : 0) * 100 / (thirtyDaysCreditAmount + thirtyDaysDebitAmount >= 1 ? thirtyDaysCreditAmount + thirtyDaysDebitAmount : 1) ?? 0).toFixed(2)}%</Typography>
                    </Box>
                  </Box>
                  <Box className="list-data">
                    <Box className="icons">
                      <Upload />
                    </Box>
                    <Typography ml={"12px"} fontWeight={"500"}>
                      Out
                    </Typography>
                  </Box>
                  <Box>
                    <Box className="progress-data">
                      <Box className="progress" style={{ width: "0%" }} />
                      <Box className="progress-empty" style={{ width: '100%' }}>
                        <Box className='progress-real' style={{ width: `${((thirtyDaysDebitAmount ? thirtyDaysDebitAmount : 0) * 100 / (thirtyDaysCreditAmount + thirtyDaysDebitAmount >= 1 ? thirtyDaysCreditAmount + thirtyDaysDebitAmount : 1) ?? 0).toFixed(2)}%` }} />
                      </Box>
                    </Box>
                    <Box className="sp-in-out-amount">
                      <Typography>{thirtyDaysDebitAmount ? thirtyDaysDebitAmount : 0} RT</Typography>
                      <Typography>{((thirtyDaysDebitAmount ? thirtyDaysDebitAmount : 0) * 100 / (thirtyDaysCreditAmount + thirtyDaysDebitAmount >= 1 ? thirtyDaysCreditAmount + thirtyDaysDebitAmount : 1) ?? 0).toFixed(2)}%</Typography>
                    </Box>
                  </Box>
                  {/* <Box className="show-more-btn">
                    <Button
                      className="btn-rounded"
                      color="warning"
                      sx={{ backgroundColor: "#FFF8F3", maxWidth: "123px" }}
                    >
                      {false ? "Show Less" : "Show More"}
                    </Button>
                  </Box> */}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>

      {/* Render projects */}
      <Box mt={2}>
        <TableContainer component={Paper} className="TableContainer">
          {/* <Typography className="font-22 text-white" mb={2}>
            Jobs
          </Typography> */}
          <Table aria-label="customized table">
            <TableHead className="TableHeader">
              <TableRow>
                <StyledTableCell>Sno.</StyledTableCell>
                <StyledTableCell>Title</StyledTableCell>
                <StyledTableCell>Description</StyledTableCell>
                <StyledTableCell>Transacton Date</StyledTableCell>
                <StyledTableCell>Amount</StyledTableCell>
                <StyledTableCell>Status</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {jobList && jobList.length > 0 ? jobList.map((row, index) => (
                <TableRow
                  key={row.id}
                  className='cursor-pointer'
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row"
                    sx={{ color: '#fff', borderBottom: 'none' }}
                  >
                    {index+1}
                  </TableCell>
                  <TableCell component="th" scope="row"
                    sx={{ color: '#fff', borderBottom: 'none' }}
                  >
                    {(row?.transaction_type === 'CONFIRM_ORD_BY_CLIENT') && row?.contractData?.title}
                  </TableCell>
                  <TableCell sx={{ color: '#fff', borderBottom: 'none' }}>
                    {(row?.transaction_type === 'CONFIRM_ORD_BY_CLIENT') && `Confirm contract order`}
                  </TableCell>
                  <TableCell sx={{ color: '#fff', borderBottom: 'none' }}>
                    {row?.createdAt ? formatDate(row?.createdAt, 'MMM d, yyyy') : ''}
                  </TableCell>
                  <TableCell sx={{ color: '#fff', borderBottom: 'none' }}>
                    ${row.status == 1 ? row.credit_amount : row.debit_amount}
                  </TableCell>
                  <TableCell sx={{ color: '#fff', borderBottom: 'none', cursor: "pointer" }}>
                    <Chip
                      sx={{
                        marginTop: 2,
                        marginBottom: 2,
                        color: "#fff",
                        bgcolor: row.status == 1 ? 'green' : 'red',
                        borderRadius: "8px",
                      }}
                      label={row.status == 1 ? 'Credit' : 'Debit'}
                    />
                  </TableCell>
                </TableRow>
              )) : (null)}
            </TableBody>
          </Table>
          {paginationData?.totalCount > 0 ?
            <Box className="pagination-container">
              <Pagination
                count={paginationData.pageSize === 'all' ? 1 : Math.ceil(paginationData?.totalCount / Number(paginationData.pageSize))}
                page={currentPage}
                onChange={handlePageChange}
                variant="outlined"
                shape="rounded"
                showFirstButton
                showLastButton
              />
            </Box> : <Typography className="font-14 font-readexpro" display={'flex'} justifyContent={'center'} color={'#9CA3AF'} mt={1}>
              Record not available
            </Typography>}
        </TableContainer>
      </Box>

      {/* Wallet connect */}
      {
        walletDialogOpen ?
          <ConnectWalletInvestor open={walletDialogOpen} handleCloseDialog={handleCloseDialog} handleConnectedSuccess={walletLogin} askConfirmation={false} />
          : null
      }
    </Box>
  );
};

export default SpvWallet;
