import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { NewLogo } from "../../../Components/Images/Images";
import { Close, Remove } from "@mui/icons-material";
import { Box, TextField, Button, Typography, IconButton, Avatar, Modal, Backdrop, CircularProgress, RadioGroup, FormControlLabel, Radio, FormControl } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import NumericFormatCustomDollar from "../../../Components/Common/NumericFormatCustomDollar";
import DownloadDoneIcon from '@mui/icons-material/DownloadDone';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PaymentsIcon from "@mui/icons-material/Payments";
import ConnectWalletInvestor from '../../../Web3/ConnectWalletInvestor';
import { useSnackbar } from "../../../Contexts/SnackbarContext";
import { update } from '../../../features/auth/authSlice'
import { useDispatch, useSelector } from 'react-redux';
import TransactionProgressModal from "../../../Components/SmartContract/TransactionProgressModal";
import { GetApiParam, postApi } from "../../../Api/Api";
import { INVESTMENT_TYPE_ID } from "../../../constants";
import './SetStackRts.css'

const style = {
    position: "relative",
    boxShadow: 24,
    borderRadius: "24px",
    width: "900px",
    height: '550px',
    // Media query for small devices
    '@media (max-width: 600px)': {
        width: '350px', // Set a different height for small screens
    },
    // Additional media queries for other screen sizes if needed
    '@media (min-width: 601px) and (max-width: 900px)': {
        // Custom styles for devices between 601px and 900px width
        width: '580px'
    },

    '@media (min-width: 901px) and (max-width: 1200px)': {
        // Custom styles for devices between 901px and 1200px width
        width: '750px'
    },

    // Specify the maximum width for large screens
    '@media (min-width: 1201px)': {
        maxWidth: "1043px",
        maxHeight: "687px",
    },

};

export default function SetRTs(props) {
    const dispatch = useDispatch();
    const { showSnackbar } = useSnackbar();
    const userData = useSelector(state => state.auth.user);

    // Initialize state for the total price of the asset
    const [openTransactionModal, setOpenTransactionModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [totalRaceToken, setTotalRaceToken] = useState(0);
    const [stakeAmt, setStakeAmt] = useState(0);
    const [errorMsg, setErrorMsg] = useState('');
    const [assetData, setAssetData] = useState([]);

    // state to hold payment currency type
    const [selectedOption, setSelectedOption] = useState('USDT');

    /**
   * Handle change in payment type
   * @param {Event} event 
   */
    const handleOptionChange = (event) => { setSelectedOption(event.target.value); };

    useEffect(() => {
        if (props?.assetId) {
            async function getAssetData() {
                setLoading(true);
                try {
                    const res = await postApi("/proposer/assetsDetails", { asset_id: props?.assetId });
                    if (res?.data?.data[0]) {
                        let assetDetails = res?.data?.data[0];
                        setAssetData(assetDetails)
                        await fetchRangeConfig(assetDetails?.category_id, assetDetails?.investment_type_id, assetDetails);
                    }
                } catch (error) {
                    console.log(error)
                }
            }
            async function fetchRangeConfig(category_id, investment_type_id, assetDetails) {
                try {
                    let projectAmt = 0;
                    if (investment_type_id === INVESTMENT_TYPE_ID.LOAN) {
                        projectAmt = assetDetails?.loan_amount;
                    }
                    else if (investment_type_id === INVESTMENT_TYPE_ID.FRACTION) {
                        projectAmt = assetDetails?.fractionalize_total_price;
                    }
                    else if (investment_type_id === INVESTMENT_TYPE_ID.PARCEL) {
                        projectAmt = assetDetails?.parcel_total_price;
                    }
                    else if (investment_type_id === INVESTMENT_TYPE_ID.SALE) {
                        projectAmt = assetDetails?.sale_price;
                    }
                    // getting response from api
                    const res = await GetApiParam(`/proposer/getAssetDicConfig`, {
                        assetId: props?.assetId
                    });
                    if (res?.data?.data) {
                        // calculating state amount for proposer
                        let stakeAmtDic = 0;
                        let stackAmtData = res?.data?.data;

                        if (stackAmtData?.dicStakeAmt) {
                            stakeAmtDic = parseInt(stackAmtData?.dicStakeAmt)
                        }
                        else {
                            if (projectAmt) {
                                stakeAmtDic = Math.ceil(parseFloat(projectAmt) * stackAmtData?.dicStakePercent / 100)
                            }
                        }
                        setLoading(false);
                        setStakeAmt(stakeAmtDic);
                        setTotalRaceToken(stakeAmtDic);
                    }
                } catch (error) {
                    setLoading(false)
                    console.log(error)
                }
            }
            getAssetData();
        }
    }, [props?.assetId])

    // Function to change the 'RtTotalPrice' based on the provided 'type' (either 'inc' or 'dec')
    const handleChangeRaceToken = (type) => {
        if (type === "inc") {
            // Increase 'RtTotalPrice' by 10
            setTotalRaceToken((prevCount) => prevCount ? parseInt(prevCount) + 10 : 10);
        } else {
            // Decrease 'RtTotalPrice' by 10, but ensure it doesn't go below 0
            setTotalRaceToken((prevCount) =>
                parseInt(prevCount) >= 10 ? parseInt(prevCount) - 10 : prevCount
            );
        }
    };

    /**
     * Function to handle the confirmation of the total price.
     * Checks if 'totalRaceToken' is greater than or equal to 10, then calls 'userResponse' from props and clears any error message.
     */
    const handleConfirm = async () => {
        if (totalRaceToken == "" && typeof totalRaceToken == 'string') {
            setErrorMsg("Please enter tokens.");
            return
        } else if (stakeAmt > totalRaceToken) {
            setErrorMsg(`Please enter minimum ${stakeAmt} Tokens`, 'error');
            return
        } else {
            setOpenTransactionModal(true);
        }
    };


    //Wallet connection code start
    const [walletDialogOpen, setWalletDialogOpen] = useState(false);

    const handleClickOpen = () => {
        if (userData && userData?.wallet_address) {
            showSnackbar('Wallet already connected', 'success');
            props.handleModalCloseFirst();
        } else {
            setWalletDialogOpen(true);
        }
    };

    const handleCloseDialog = (value) => {
        setWalletDialogOpen(false);
    };

    /**
     * gets called when a wallet is connect successfully 
     * @param {string} acc 
     */
    const walletLogin = async (acc) => {
        if (acc) {
            dispatch(update({ wallet_address: acc?.toLowerCase() }));
            showSnackbar('Wallet connected successfully', 'success');
            props.handleModalCloseFirst();
        }
    }

    //Wallet connection code end

    // useEffect to clear the error message when 'totalRaceToken' is greater than or equal to 10
    useEffect(() => {
        if (totalRaceToken >= 0 && errorMsg === 'Please enter tokens.') {
            setErrorMsg('');
        } else if (totalRaceToken >= stakeAmt && errorMsg?.includes('Please enter minimum')) {
            setErrorMsg('');
        }
    }, [totalRaceToken]);

    /**
     * Function call when transaction successful for confirm stake
     */
    const confirmStake = async (data, amount, asset_id, user_id) => {
        props.userResponse(totalRaceToken);
        try {
            let body = {
                amount: amount,
                sender_address: data.from,
                contract_address: data.to,
                transaction_hash: data.transactionHash,
                method_name: "dicStake",
                proposer_id: user_id,
                asset_id: asset_id
            }

            const transactionCreate = await postApi("/user/createBlockchainTransaction", body);
        } catch (error) {
            console.log('error', error);
        }
    }

    const handleModalClose = () => {
        setOpenTransactionModal(false)
    }

    return (
        <>
            {props.openRTs ?
                <Modal
                    open={props.openRTs}
                    onClose={props.handleClose}
                    className="kyc-modal"
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    BackdropComponent={Backdrop}
                    BackdropProps={{ open: false }}
                >
                    <Box className="set-stack-rts" sx={style}>
                        <Box className="p-headContent">
                            {/* <Link to="/"> */}
                            <Box className="logo">
                                <Avatar
                                    alt="Logo"
                                    src={NewLogo}
                                    variant="square"
                                    sx={{ width: 39, height: 90 }}
                                />
                            </Box>
                            {/* </Link> */}
                            <Close className="close-btn" onClick={props.handleClose} />
                        </Box>
                        <Box className="p-headContent" mt={4}>
                            <Box className="modalContentDone " py={{ md: '2px', sm: '0px' }} px={{ md: 18, sm: 2 }}>
                                <Typography component="h1" className="headText">Stake RACE Tokens</Typography>
                                <Box>
                                    <Box className="overview-text-box">
                                        {/* <Typography component="p" className="sub-headText overview">Enter Amount Below</Typography > */}
                                        <Typography component="p" className="sub-headText overviewText">You need to stake a minimum of {stakeAmt} RACE tokens to participate in project reviews and voting. Please ensure your wallet has sufficient RACE Tokens for Staking.<br /> Read more about staking <Link to='/dummy' target="_blank" className="link-review overview">here.</Link></Typography >
                                    </Box>
                                </Box>
                                <Box className="dic-NS-sale-details overview" mb={1} mt={-1}>
                                    <Box className="p-inc-dec-icon">
                                        <IconButton
                                            onClick={() => {
                                                handleChangeRaceToken("dec");
                                            }}
                                            className="p-inc-dec-icon-btn"
                                        >
                                            <Remove />
                                        </IconButton>
                                    </Box>
                                    <Box>
                                        <TextField
                                            id="outlined-number"
                                            type="text"
                                            className="loan-text"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                inputComponent: NumericFormatCustomDollar,
                                            }}
                                            FormHelperTextProps={{ style: { color: 'black' } }}
                                            value={totalRaceToken}
                                            size="small"
                                            helperText={totalRaceToken < 2 ? "RACE Token" : "RACE Tokens"}
                                            onChange={(e) =>
                                                setTotalRaceToken(e.target.value)
                                            }
                                        />
                                    </Box>
                                    <Box className="p-inc-dec-icon">
                                        <IconButton
                                            onClick={() => {
                                                handleChangeRaceToken("inc");
                                            }}
                                            className="p-inc-dec-icon-btn"
                                        >
                                            <AddIcon />
                                        </IconButton>
                                    </Box>
                                </Box>
                                {errorMsg && <Typography sx={{ color: '#FF6865', fontSize: '11px', textAlign: 'center', mb: 2 }}>{errorMsg}</Typography>}

                                <Box className='investor-select-currency-type'>
                                    <Typography className='pay-using font-14'>
                                        Pay using
                                    </Typography>
                                    <FormControl component="fieldset">
                                        <RadioGroup
                                            aria-label="payment-method"
                                            name="payment-method"
                                            defaultValue="USDT"
                                            row
                                            className='currency-options'
                                            value={selectedOption}
                                            onChange={handleOptionChange}
                                        >
                                            {/* <FormControlLabel
                                                value="RACE"
                                                control={<Radio />}
                                                label="RACE Token"
                                            /> */}
                                            <FormControlLabel
                                                value="USDT"
                                                control={<Radio />}
                                                label="USDT"
                                            />
                                            <FormControlLabel
                                                value="USDC"
                                                control={<Radio />}
                                                label="USDC"
                                            />
                                            <FormControlLabel
                                                value="DAI"
                                                control={<Radio />}
                                                label="DAI"
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Box>

                                <Box className='overview-btn-box' mt={1}>
                                    <Button className="wallet-button" sx={{ background: "#E2E8F0 !important" }}> <Box className="btn-icon" sx={{ color: "#053863 !important" }}><AttachMoneyIcon sx={{ color: "#000 !important" }} />Buy RACE Tokens</Box></Button>
                                    <Button className="wallet-button" sx={{ background: "#1771BD !important" }} onClick={handleConfirm}><Box className="btn-icon" ><DownloadDoneIcon />Confirm Stake</Box></Button>
                                </Box>
                            </Box>
                        </Box>
                        {/* {props.content} */}
                    </Box>
                </Modal >
                : null
            }
            {props.openWallet ?
                <Modal
                    open={props.openWallet}
                    onClose={props.handleModalCloseFirst}
                    className="kyc-modal"
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    BackdropComponent={Backdrop}
                    BackdropProps={{ open: false }}
                >
                    <Box className="set-stack-rts" sx={style}>
                        <Box className="p-headContent">
                            {/* <Link to="/"> */}
                            <Box className="logo">
                                <Avatar
                                    alt="Logo"
                                    src={NewLogo}
                                    variant="square"
                                    sx={{ width: 39, height: 90 }}
                                />
                            </Box>
                            {/* </Link> */}
                            {/* <CloseIcon className="close-btn" onClick={props.handleModalCloseFirst} /> */}
                        </Box>
                        <Box className="p-headContent" mt={4} textAlign={'center'}>
                            <Box className="modalContentDone " py={{ md: '2px', sm: '0px' }} px={{ md: 18, sm: 2 }}>
                                <Typography component="h1" className="headText">
                                    Please connect your wallet to access your Investment <br />{" "}
                                    Committee Account
                                </Typography>
                                <Box>
                                    <Box className="overview-text-box font-14" p={{ md: '23px 90px !important', sm: '10px 5px !important' }} >
                                        <Typography component="p" className="">
                                            Please ensure your wallet has sufficient RACE Tokens for
                                            Staking. If you don’t have a wallet, you can select “Torus”
                                            to create a wallet that’s linked to your phone number. Click
                                            <Link target="_blank" to={"/dummy"}>{" "}<span style={{ fontWeight: '600' }}>here</span></Link> for a detailed guide.
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box className="overview-btn-box" mt={1} width={'88%'}>
                                    <Button
                                        className="wallet-button"
                                        sx={{ background: "#1771BD !important" }}
                                        onClick={handleClickOpen}
                                    >
                                        <Box
                                            className="btn-icon"
                                            sx={{ color: "white !important" }}
                                        >
                                            Connect/Create Wallet
                                        </Box>
                                    </Button>

                                </Box >
                                <Box className="overview-btn-box" mt={1} width={'88%'}>
                                    <Button
                                        className="wallet-button"
                                        sx={{ background: "#06487E !important" }}
                                        onClick={props.handleModalCloseFirst}
                                    >
                                        <Box
                                            className="btn-icon"
                                            sx={{ color: "white !important" }}
                                        >
                                            <PaymentsIcon sx={{ color: "white !important" }} />
                                            Buy RACE Tokens
                                        </Box>
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Modal>
                : null
            }
            {
                walletDialogOpen ?
                    <ConnectWalletInvestor open={walletDialogOpen} handleCloseDialog={handleCloseDialog} handleConnectedSuccess={walletLogin} askConfirmation={false} />
                    : null
            }

            {loading && <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1301 }}
                open={loading}
            >
                <CircularProgress color="primary" />
            </Backdrop>
            }

            {/* stake race token progress modal */}
            {openTransactionModal ? <TransactionProgressModal identity={'dic-stake'} confirmStake={confirmStake} stakeAmt={totalRaceToken} propData={{ ...assetData, feeTokenType: selectedOption }} handleModalClose={handleModalClose} openTransactionModal={openTransactionModal} /> : null}
        </>
    );
}
