import React, { useEffect, useState } from 'react'
import { Avatar, Badge, Box, Card, CardActionArea, CardHeader, Divider, IconButton, InputBase, Paper, Stack, Typography } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { ArrowDropDown, Circle, MoreHoriz } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux';
import { decreaseTotalUnreadCount } from '../../features/auth/serviceChatSlice';

function Contacts({ contacts, changeChat, onlineUsers, currentChat, logedinUserData }) {
    const [searchString, setSearchString] = useState('');
    const [filteredContacts, setFilteredContacts] = useState([]);
    const [showMore, setShowMore] = useState(false);
    const dispatch = useDispatch();
    const contactUnreads = useSelector((state) => state.serviceChat.contactWiseUnreadCount);

    // Search contacts
    useEffect(() => {
        if (searchString && searchString.length > 1) {
            const filtered = contacts.filter((contact) => {
                return contact.chatName?.toLowerCase().includes(searchString.toLowerCase());
            })
            setFilteredContacts(filtered);
        } else {
            setFilteredContacts(contacts);
        }
    }, [searchString])

    /**
     * Handle show more button click
     */
    const handleShowMore = () => {
        setShowMore(true);
    }


    useEffect(() => {
        if (contacts?.length > 0) {
            // updates the contact with online status
            let filteredContacts = [...contacts];
            filteredContacts.forEach((element, index) => {
                element.online = false;
                if (onlineUsers && onlineUsers.includes(element?.receiver_id)) {
                    filteredContacts[index].online = true
                }
                if (element?.contract_data) {
                    filteredContacts[index].chatName = element?.contract_data?.title;
                    filteredContacts[index].avtar = null;
                } else {
                    const oppUser = element?.user_details && element?.user_details.length > 0 ?
                        element?.user_details.filter((row) => row.id != logedinUserData?.id) : []
                    if (oppUser && oppUser.length > 0) {
                        filteredContacts[index].chatName = `${oppUser[0]?.first_name} ${oppUser[0]?.last_name}`
                        filteredContacts[index].avtar = null;
                    }
                }
            });
            setFilteredContacts(filteredContacts); // update the state
        }
        //eslint-disable-next-line
    }, [onlineUsers, contacts])

    return (
        <>
            <Box className='ctNavSp' sx={{ marginLeft: "0" }}>
                <Box className='ctSearch'>
                    <Paper
                        className='ctPaper'
                        elevation={0}
                    >
                        <IconButton type="button" className='ctSearch-icon' aria-label="search">
                            <SearchIcon />
                        </IconButton>
                        <InputBase
                            className='ctSearch-input'
                            placeholder="Search project name or username"
                            inputProps={{ 'aria-label': 'search project name or username' }}
                            onChange={(e) => setSearchString(e.target.value)}
                        />
                    </Paper>
                </Box>
                <Box className='ctDivider'>
                    <Divider />
                </Box>
                <Box className="ctContactsWrap">
                    {
                        filteredContacts && filteredContacts.length > 0 ?
                            <>
                                {filteredContacts.map((contact, i) => {
                                    if (i < 5 || showMore) {
                                        return (
                                            <Card key={i} className='ctContact' elevation={0} >
                                                <CardActionArea onClick={() => changeChat(contact)} >
                                                    <CardHeader
                                                        avatar={

                                                            <Badge
                                                                overlap='circular'
                                                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                                // Condition for checking that User is Online or Offline
                                                                badgeContent={contact?.online ? <Circle sx={{ color: '#22C55E', width: '16px', height: '16px' }} /> : null}
                                                            >
                                                                <Avatar src={`${process.env.REACT_APP_IMAGE_URL}${contact?.avtar}`} alt={contact?.chatName} />
                                                            </Badge>

                                                        }
                                                        title={<div className="ctContactAssetTitle">
                                                            {contact.chatName}
                                                        </div>}
                                                        action={
                                                            <>
                                                                <Typography sx={{ fontSize: '12px' }} gutterBottom variant='subtitle1'
                                                                    color='#94A3B8'>
                                                                    {
                                                                        contact?.latestMessage?.createdAt ?
                                                                            new Date(contact?.latestMessage?.createdAt).toLocaleTimeString([], {
                                                                                hour: "2-digit",
                                                                                minute: "2-digit",
                                                                            })
                                                                            : ''
                                                                    }</Typography>
                                                                <Badge sx={{ mt: 1, ml: 2 }} badgeContent={contact?.unread} color='primary' />
                                                            </>
                                                        }
                                                        subheader={
                                                            contact?.latestMessage?.content?.length > 27 ?
                                                                contact?.latestMessage?.content.slice(0, 27) + '...' :
                                                                contact?.latestMessage?.content
                                                                ?? ''}
                                                        subheaderTypographyProps={{ color: "#94A3B8" }}
                                                    />
                                                </CardActionArea>
                                            </Card>
                                        )
                                    }
                                    else {
                                        return null
                                    }
                                })}
                                {(showMore === false && filteredContacts?.length > 5) &&
                                    <Stack flexDirection={'row'} justifyContent={'space-between'}>
                                        <Stack flexDirection={'row'} justifyContent={'space-around'}>
                                            <Box padding={'0px 12px 0px 23px'}>
                                                <MoreHoriz />
                                            </Box>
                                            <Typography>More</Typography>
                                        </Stack>
                                        <Box sx={{ cursor: 'pointer' }} onClick={handleShowMore}>
                                            <ArrowDropDown />
                                        </Box>
                                    </Stack>
                                }
                            </>
                            :
                            <Box className='ctNoContact'>
                                <Typography variant='body2' mt={1} textAlign={'center'} >No contacts found</Typography>
                            </Box>
                    }
                </Box>
            </Box >
        </>
    )
}

export default Contacts     