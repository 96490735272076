import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { Box, Grid, Typography, Button, TextField, FormControl, } from "@mui/material";
import { KeyboardArrowRight } from "@mui/icons-material";
import countryList from "react-select-country-list";
import { useForm } from "react-hook-form";

// Import custom components for this page
import { NewLogo } from "../../../Components/Images/Images";
import LinearProgressCompo from "./Components/LinearProgressCompo";
import "./Components/Questionnaire.css";

export default function ArtProjectDetails(props) {
    // Getting country name
    const countryNames = useMemo(() => countryList().getData(), []);

    // Destructure props to obtain necessary functions and data
    const { handleNext, handleback, getProposalData, updateProposalData, saveProposal, getProgress } = props;

    // Get current proposal and progress data
    var propData = getProposalData();
    let currentProgress = getProgress();

    // get current year
    const currentYear = new Date().getFullYear();

    // Destructure the useForm hook and extract the 'register', 'handleSubmit', and 'errors' properties
    const { register, handleSubmit, formState: { errors } } = useForm({
        // Set default form field values based on 'propData' if available
        defaultValues: {
            art_name: propData?.artProjectDetails?.art_name,
            artist: propData?.artProjectDetails?.artist,
            year: propData?.artProjectDetails?.year,
            medium: propData?.artProjectDetails?.medium,
            art_address: propData?.artProjectDetails?.art_address,
            art_floor: propData?.artProjectDetails?.art_floor,
            art_city: propData?.artProjectDetails?.art_city,
            art_state: propData?.artProjectDetails?.art_state,
            art_zip: propData?.artProjectDetails?.art_zip,
            art_country: propData?.artProjectDetails?.art_country,
        }
    });

    /**
     * Function to handle form submission
     * @param {Object} data 
     * @param {event} event 
     */
    const onSubmit = (data, event) => {
        // Get the text of the submit button that triggered the form submission
        const buttonText = event.nativeEvent.submitter.innerText;
        // Update 'propData' with the form data
        propData.artProjectDetails = data
        // setting art name as asset title
        propData.asset_title = data.art_name
        // setting art address as art address
        propData.asset_address = data.art_address
        // Update 'propData' in the main page 
        updateProposalData(propData)

        // Check which button was clicked and take appropriate action
        if (buttonText.includes("Save and Exit")) {
            SaveAndExit()
        } else {
            handleNext();
        }
    }

    /**
     * Function to perform a "Save and Exit" action
     */
    const SaveAndExit = () => {
        // Call the 'saveProposal' function to save the proposal data
        saveProposal();
    }
    return (
        <Box className="projectProposer questionnaire-page">
            <Box className="questionnaire-section">
                <Grid container spacing={0}>
                    <Grid item xs={12} sm={6} md={6}>
                        <Box className="auth-left">
                            {/* left-title */}
                            <Box className="quests-left-title">
                                <Typography component="h1" className="title">
                                    Let investors know what your art piece is
                                </Typography>
                            </Box>
                            {/* Logo */}
                            <Box className="auth-logo">
                                <Link to="/">
                                    <Box component="img" src={NewLogo} alt="Race logo" />
                                </Link>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <Box className="auth-right quests-right-contents-box" component="form"
                            onSubmit={handleSubmit(onSubmit)}>
                            <Box textAlign={"right"}>
                                <Box component="span" className="icon" ml={1}>
                                    <Button type="submit" className="btn-rounded btn-text-white btn-blue-600 saveAndExit">
                                        Save and Exit
                                    </Button>
                                </Box>
                            </Box>

                            <Box className="questionnaire6 qsn-middle-content" sx={{ pt: 4, mb: 5 }}>
                                <Box className="auth-form">
                                    <Box className="form-group label-field">
                                        <TextField
                                            type="text"
                                            autoComplete="title"
                                            id="outlined-required"
                                            label="Name/Title of Piece"
                                            name="art_name"
                                            {...register("art_name", {
                                                required: "Name/Title of Piece is required.",
                                                minLength: {
                                                    value: 2,
                                                    message: "Name/Title must be at least 2 characters."
                                                },
                                                maxLength: {
                                                    value: 255,
                                                    message: "Name/Title must not exceed 255 characters."
                                                },
                                                pattern: {
                                                    value: /^[a-zA-Z0-9,. -]+$/,
                                                    message: "Only alphanumeric characters and basic punctuation (, . -) are allowed."
                                                }
                                            })}
                                            error={Boolean(errors.art_name)}
                                            helperText={errors.art_name?.message}
                                        />

                                    </Box>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <Box className="form-group label-field">
                                                <TextField
                                                    type="text"
                                                    autoComplete="cc-name"
                                                    id="outlined-required"
                                                    label="Artist"
                                                    name="artist"
                                                    {...register("artist", {
                                                        required: "Artist Name is required.",
                                                        minLength: {
                                                            value: 3,
                                                            message: "Artist Name must be at least 3 characters."
                                                        },
                                                        maxLength: {
                                                            value: 100,
                                                            message: "Artist Name must not exceed 100 characters."
                                                        },
                                                        pattern: {
                                                            value: /^[a-zA-ZÀ-ž\s'-]+$/,
                                                            message: "Artist Name must contain only letters, spaces, hyphens, and apostrophes."
                                                        }
                                                    })}
                                                    error={Boolean(errors.artist)}
                                                    helperText={errors.artist?.message}
                                                />

                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <Box className="form-group label-field">
                                                <TextField
                                                    type="text"
                                                    autoComplete="on"
                                                    id="outlined-required"
                                                    label="Year"
                                                    name="year"
                                                    {...register("year", {
                                                        required: "Year is required.",
                                                        pattern: {
                                                            value: /^\d{4}$/,
                                                            message: "Please enter a valid year.",
                                                        },
                                                        validate: (value) => {
                                                            const year = parseInt(value, 10);
                                                            const currentYear = new Date().getFullYear();
                                                            if (year >= 1500 && year <= currentYear) {
                                                                return true;
                                                            }
                                                            return `Year must be between 1500 and ${currentYear}.`;
                                                        }
                                                    })}
                                                    onInput={(event) => {
                                                        event.target.value = event.target.value.replace(/\D/g, "").slice(0, 4);
                                                    }}
                                                    error={Boolean(errors.year)}
                                                    helperText={errors.year?.message}
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Box className="form-group label-field" mb={10}>
                                        <TextField
                                            type="text"
                                            autoComplete="on"
                                            id="outlined-required"
                                            label="Medium"
                                            name="medium"
                                            {...register("medium", {
                                                required: "Medium is required.",
                                                minLength: {
                                                    value: 2,
                                                    message: "Medium must be at least 2 characters."
                                                },
                                                maxLength: {
                                                    value: 50,
                                                    message: "Medium must not exceed 50 characters."
                                                },
                                                pattern: {
                                                    value: /^[a-zA-Z0-9,. ]+$/,
                                                    message: "Only alphanumeric characters and standard punctuation (.,) are allowed."
                                                }
                                            })}
                                            error={Boolean(errors.medium)}
                                            helperText={errors.medium?.message}
                                        />

                                    </Box>
                                    <Box className="form-group label-field" >
                                        <TextField
                                            id="outlined-required"
                                            type="text"
                                            autoComplete="address-line2"
                                            label="Address (Location of Asset)"
                                            name="art_address"
                                            {...register("art_address", {
                                                required: "Address (Location of Asset) is required.",
                                                minLength: {
                                                    value: 5,
                                                    message: "Address must be at least 5 characters."
                                                },
                                                maxLength: {
                                                    value: 255,
                                                    message: "Address must not exceed 255 characters."
                                                },
                                                pattern: {
                                                    value: /^[a-zA-Z0-9\s#\-\/]+$/,
                                                    message: "Only alphanumeric characters, spaces, and common address symbols (#, -, /) are allowed."
                                                }
                                            })}
                                            error={Boolean(errors.art_address)}
                                            helperText={errors.art_address?.message}
                                        />
                                    </Box>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <Box className="form-group label-field">
                                                <TextField
                                                    type="text"
                                                    autoComplete="address-line1"
                                                    id="outlined-required"
                                                    label="Suite/Floor"
                                                    name="art_floor"
                                                    {...register("art_floor", {
                                                        required: "Suite/Floor is required.",
                                                        maxLength: {
                                                            value: 50,
                                                            message: "Suite/Floor must not exceed 50 characters."
                                                        },
                                                        pattern: {
                                                            value: /^[a-zA-Z0-9\s]+$/,
                                                            message: "Only alphanumeric characters are allowed (e.g., Suite 101, Floor 2)."
                                                        }
                                                    })}
                                                    error={Boolean(errors.art_floor)}
                                                    helperText={errors.art_floor?.message}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <Box className="form-group label-field">
                                                <TextField
                                                    type="text"
                                                    autoComplete="home city"
                                                    id="outlined-required"
                                                    label="City"
                                                    name="art_city"
                                                    {...register("art_city", {
                                                        required: "City is required.",
                                                        minLength: {
                                                            value: 2,
                                                            message: "City must be at least 2 characters."
                                                        },
                                                        maxLength: {
                                                            value: 100,
                                                            message: "City must not exceed 100 characters."
                                                        },
                                                        pattern: {
                                                            value: /^[a-zA-Z\s]+$/,
                                                            message: "City must contain only letters and spaces."
                                                        }
                                                    })}
                                                    error={Boolean(errors.art_city)}
                                                    helperText={errors.art_city?.message}
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <Box className="form-group label-field">
                                                <TextField
                                                    type="text"
                                                    autoComplete="on"
                                                    id="outlined-required"
                                                    label="State/Province"
                                                    name="art_state"
                                                    {...register("art_state", {
                                                        required: "State/Province is required.",
                                                        minLength: {
                                                            value: 2,
                                                            message: "State/Province must be at least 2 characters."
                                                        },
                                                        maxLength: {
                                                            value: 100,
                                                            message: "State/Province must not exceed 100 characters."
                                                        },
                                                        pattern: {
                                                            value: /^[a-zA-Z]+$/,
                                                            message: "State/Province must contain only alphabetical characters."
                                                        }
                                                    })}
                                                    error={Boolean(errors.art_state)}
                                                    helperText={errors.art_state?.message}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <Box className="form-group label-field">
                                                <TextField
                                                    type="text"
                                                    autoComplete="postal-code"
                                                    id="outlined-required"
                                                    label="ZIP/Postal"
                                                    name="art_zip"
                                                    {...register("art_zip", {
                                                        required: "ZIP/Postal is required.",
                                                        pattern: {
                                                            value: /^(?:(\d{5}-\d{3}|\d{8})|([A-Za-z]\d[A-Za-z] ?\d[A-Za-z]\d)|([A-Za-z]{1,2}\d{1,2}[A-Za-z]?\s?\d[A-Za-z]{2})|(\d{3,10}))$/,
                                                            message: "Please enter a valid ZIP/Postal code."
                                                        },
                                                        minLength: {
                                                            value: 4,
                                                            message: "ZIP/Postal code must be at least 4 characters."
                                                        },
                                                        maxLength: {
                                                            value: 10,
                                                            message: "ZIP/Postal code must not exceed 10 characters."
                                                        }
                                                    })}
                                                    error={Boolean(errors.art_zip)}
                                                    helperText={errors.art_zip?.message}
                                                />

                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Box>
                                        <FormControl fullWidth>
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                                label="Country"
                                                id="outlined-select-country-native"
                                                select
                                                SelectProps={{
                                                    native: true
                                                }}
                                                name='art_country'
                                                {...register("art_country", {
                                                    required: "Country is required.",
                                                })}
                                                error={Boolean(errors.art_country)}
                                                helperText={errors.art_country?.message}
                                                // name='country'
                                                sx={{ input: { color: '#64748B !important' } }}
                                            >
                                                <option value={""}>
                                                    {"-Select a Country-"}
                                                </option>
                                                {countryNames.map((option) => (
                                                    <option key={`Topic${option?.label}`}
                                                        value={option?.label}
                                                    >
                                                        {option?.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </FormControl>
                                    </Box>
                                </Box>
                            </Box>
                            <Box>
                                <Box className="questionnaire-progress">
                                    <LinearProgressCompo value={currentProgress} />
                                </Box>
                                <Box
                                    sx={{ display: "flex", justifyContent: "space-between" }}
                                    mt={3}
                                >
                                    <Box textAlign={"left"} className="quests6-btn-box">
                                        <Button className="btn-rounded back-btn" onClick={handleback}>Back</Button>
                                    </Box>
                                    <Box textAlign={"right"} className="quests-btn-box">
                                        <Button
                                            // onClick={() => handleClick()}
                                            endIcon={<KeyboardArrowRight />}
                                            className="btn-rounded btn-text-white btn-blue-600"
                                            type="submit"
                                        >
                                            Next
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}
