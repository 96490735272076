import React, { useCallback, useEffect, useState } from "react";
import ServicesSearch from "../Components/ServicesSearch/ServicesSearch";
import { Grid, Typography, Box, Button, Chip } from "@mui/material";
import SearchCompo from "../Components/SearchCompo/SearchCompo";
import ProfileCard from "../Components/ProfileCard/ProfileCard";
import MainCard from "../Components/MainCard/MainCard";
import { IconButton, TextField, styled } from "@mui/material";
import {
  Search,
  ArrowForward,
  Dock,
  Payment,
  LocalFireDepartment,
  Payments,
} from "@mui/icons-material";
import { GetApiParam } from "../../Api/Api";
import { NavLink, useNavigate } from "react-router-dom";
import "./FindServiceProvider.css";
import { getUserDataFromLocalStorage } from "../../Services/localstorage.service";
import CommonBackdropLoader from "../../Components/Common/CommonBackdropLoader";

const SearchTextField = styled(TextField)({
  width: '100%',
  maxWidth: "262px",
  height: '48px',
  '& .MuiOutlinedInput-root': {
    '& input': {
      padding: '12px 16px',
    },
    '& fieldset': {
      borderRadius: '48px',
    },

  },
});
const FindServiceProvider = () => {
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('discover');
  const [selectedCategoryData, setSelectedCategoryData] = useState(null);
  const [categoryData, setCategoryData] = useState([]);
  const [serviceData, setServicesData] = useState([]);
  const [serviceProvider, setServiceProvider] = useState([]);
  const loginUserData = getUserDataFromLocalStorage();
  const [searchString, setSearchString] = useState('');
  const navigate = useNavigate();

  const SearchButton = styled(IconButton)({
    "& .MuiIconButton-root": {
      padding: "10px",
      backgroundColor: "#398CD1",
      borderRadius: "48px",
      "& .MuiSvgIcon-root": {
        color: "#ffffff",
        height: "12px",
        width: "12px",
      },
    },

    // disable hover
    "&:hover": {
      backgroundColor: "#398CD1",
      "& .MuiSvgIcon-root": {
        color: "#ffffff",
      },
    },
  });

  /**
   * All Get API calling for service provider
   */
  useEffect(() => {
    async function getCategoryData() {
      try {
        const payload = {
          user_id: loginUserData?.id
        }
        const categoryRes = await GetApiParam("/services/spCategory", payload);
        setCategoryData(categoryRes?.data?.data || []);
      } catch (error) {
        console.log(error)
      }
    }
    getCategoryData();
  }, [])

  const fetchData = async () => {
    try {
      setLoading(true);
      let payload = {
        login_user_id: loginUserData?.id
      };
      if (selectedCategoryData) {
        payload = {
          ...payload,
          sp_category_id: selectedCategoryData?.id
        };
      }
      if (activeTab && activeTab === 'your hires') {
        payload = {
          ...payload,
          yourHires: true
        };
      }
      if (activeTab && activeTab === 'recently viewed') {
        payload = {
          ...payload,
          recentlyViewed: true
        };
      }
      if (activeTab && activeTab === 'saved service providers') {
        payload = {
          ...payload,
          savedRequest: true
        };
      }
      const [servicesRes, serviceProviderRes] =
        await Promise.all([
          GetApiParam("/services/getServices", payload),
          GetApiParam("/services/getProfiles", payload),
        ]);
      setServicesData(
        servicesRes?.data?.data?.length ? servicesRes.data.data : []
      );
      setServiceProvider(
        serviceProviderRes?.data?.data?.length
          ? serviceProviderRes.data.data
          : []
      );
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [selectedCategoryData, activeTab]);

  // Filter service providers for each category
  const filteredServiceProvidersByCategory = categoryData.map((category) => {
    const filteredProviders =
      serviceProvider &&
      serviceProvider.filter(
        (provider) => provider?.serviceCategory?.title === category.title
      );
    return { category, filteredProviders };
  });

  setTimeout(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, 200);

  const handleSearchResults = () => {
    navigate("/service-proposer/service-provider-results", { state: { search: searchString } });
  };

  const fetchProjectAccordingToCategory = (selectedCategory) => {
    setSelectedCategoryData(selectedCategory);
  }
  return (
    <Box className="container">
      <CommonBackdropLoader loading={loading} />
      <Box className="find-sp-profile-page">
        {" "}
        <Box
          display={"flex"}
          flexDirection={"column"}
          gap={4}
          maxWidth={"660px"}
        >
          <Typography className="title">
            Find the professional you need
          </Typography>
          <Typography className="font-18">
            We have vetted service providers in over 300 services. Sign up to
            find the perfect service provider for all your investment needs.
          </Typography>
        </Box>
        <Box className="">
          <Box mt={3}>
            <ServicesSearch
              page={'findServiceProvider'}
              categoryData={categoryData}
              fetchServicesAccordingToCategory={fetchProjectAccordingToCategory}
            />
          </Box>
        </Box>
      </Box>

      <Grid container spacing={2} mt={6}>
        <Grid item xs={12} sm={12} md={12}>
          <Grid container spacing={2} direction="row">
            <Grid item xs={12} sm={12} md={2.5}>
              <Box onClick={() => setActiveTab('discover')} className={`dashboard-workspace ${activeTab === 'discover' ? 'active' : ''}`}>
                <NavLink className="dashboard-navlink">
                  <Dock className="icon" />
                  <span>Discover</span>
                </NavLink>
              </Box>
              <Box onClick={() => setActiveTab('your hires')} className={`dashboard-workspace ${activeTab === 'your hires' ? 'active' : ''}`}>
                <NavLink className="dashboard-navlink">
                  <Payment className="icon" />
                  <span>Your hires</span>
                </NavLink>
              </Box>

              <Box onClick={() => setActiveTab('recently viewed')} className={`dashboard-workspace ${activeTab === 'recently viewed' ? 'active' : ''}`}>
                <NavLink className="dashboard-navlink">
                  <LocalFireDepartment className="icon" />
                  <span>Recently viewed</span>
                </NavLink>
              </Box>
              <Box onClick={() => setActiveTab('saved service providers')} className={`dashboard-workspace ${activeTab === 'saved service providers' ? 'active' : ''}`}>
                <NavLink className="dashboard-navlink">
                  <Payments className="icon" />
                  <span>Saved Service Providers</span>
                </NavLink>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={9.5} p={2}>
              <Box>
                <Box
                  className="explore-provider-container"
                >
                  <Box className="explore-provider-left">
                    <Typography className="heading">
                      Explore Providers
                    </Typography>
                  </Box>
                  <Box className={"service-search-item "}>
                    <SearchTextField variant='outlined' placeholder="Search Service Provider"
                      onChange={(e) => {
                        setSearchString(e.target.value);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          handleSearchResults(searchString);
                        }
                      }}
                      InputProps={{
                        endAdornment: (
                          <SearchButton className='search-button' sx={{ p: '10px', bgcolor: '#cfa119' }} aria-label="search" onClick={() => handleSearchResults(searchString)} >
                            <Search style={{ color: '#05131B' }} sx={{ color: '#05131B', height: '12px', width: '12px' }} />
                          </SearchButton>
                        ),
                      }}
                    />
                  </Box>
                </Box>
                <Box className="explore-provider-right">
                  <Typography className="heading">
                    {serviceProvider && serviceProvider?.length > 0 ? <>{serviceProvider?.length} Service Providers</> : 'Service Provider Not Available'}
                  </Typography>
                </Box>
              </Box>

              {/* Profile Cards for Categories */}
              {filteredServiceProvidersByCategory &&
                filteredServiceProvidersByCategory
                  .filter(
                    ({ filteredProviders }) => filteredProviders.length > 0
                  )
                  .map(({ category, filteredProviders }, index) => (
                    <Box key={index} className="develop-it-main">
                      <Box
                        mt={5}
                        className="development-it-container"
                      >
                        <Typography className="font-24" color={"#ffffff"}>
                          {category?.title &&
                            category?.title ===
                            "Technology Developers & Software Services"
                            ? "Development & IT"
                            : category?.title}
                        </Typography>
                        <Box className="btn-wrap sp multi-btns">
                          <Button
                            className="btn-rounded btn-large"
                            style={{ background: "var(--golden-gradient)" }}
                            startIcon={<ArrowForward />}
                            onClick={() => navigate(`/service-proposer/service-provider-results`, { state: { category } })}
                          >
                            See more profiles
                          </Button>
                        </Box>
                      </Box>
                      <Box my={2} className="profile-card">
                        <Grid container spacing={3}>
                          {filteredProviders.length ? (
                            filteredProviders.slice(0, 4).map((card, index) => (
                              <Grid key={index} item md={3} sm={6} xs={12} xl={3}>
                                <NavLink
                                  to={`/service-proposer/profile/individual/${card?.user_id}`}
                                  state={{ user_id: card?.user_id }}
                                >
                                  <ProfileCard {...card} />
                                </NavLink>
                              </Grid>
                            ))
                          ) : (
                            <Typography ml={4} className="font-16">
                              No service Provider Found
                            </Typography>
                          )}
                        </Grid>
                      </Box>
                    </Box>
                  ))}

              {/* Services you may like */}
              <Box
                mt={5}
                className="services-you-may-like-container"
              >
                <Box>
                  <Typography className="font-45" color={"#fffff"}>
                    Services you may like
                  </Typography>
                  <Typography className="font-18" color={"#fffff"}>
                    Pressed for time? Browse and buy prescoped services in just
                    a few clicks
                  </Typography>
                </Box>
                <Box className="btn-wrap  multi-btns">
                  <Button
                    className="btn-rounded sp btn-large"
                    style={{ background: "var(--golden-gradient)" }}
                    startIcon={<ArrowForward />}
                    onClick={() => navigate('/service-proposer/client-category-results')}
                  >
                    Browse by services
                  </Button>
                </Box>
              </Box>

              {/* Main-card */}
              <Box className="main-card" >
                <Grid container spacing={3}>
                  {serviceData?.slice(0, 3).map((card, index) => (
                    <Grid key={index} item md={4} sm={6} xs={12} xl={4}>
                      <MainCard {...card} />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FindServiceProvider;
