import { Box, Button, Grid, Stack, TextField, Typography, Badge, InputAdornment, Tooltip } from '@mui/material'
import React, { useState, useEffect } from 'react'
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CloseIcon from '@mui/icons-material/Close';
import ClearIcon from '@mui/icons-material/Clear';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Link, useNavigate } from "react-router-dom";
import "../OnboardingProfile.css";
import { GetApi } from '../../../Api/Api';
import config from '../../../Config/Config';

const OnboardingServiceLeft = ({ onNext, onBack, stepThreePreviousData, handleDataUpdate, sharedDataStepTwo }) => {
    const [mainService, setMainService] = useState(null);
    const [serviceFee, setServiceFee] = useState({});
    const [validationErrors, setValidationErrors] = useState({}); // state for the validation of step 3
    const [selectedServices, setSelectedServices] = useState([]); // state t select Services
    const navigate = useNavigate(); // useNavigate variable
    const [formData, setFormData] = useState({
        mainServices: '',
        hourlyRate: '',
        serviceFee: '',
        youWillGet: '',
        availability: '', // Add availability field in formData
    });
    const [selectedValue, setSelectedValue] = useState(stepThreePreviousData?.availability ? stepThreePreviousData?.availability : 'open');
    useEffect(() => {
        if (stepThreePreviousData) {
            setFormData({
                mainServices: stepThreePreviousData.mainServices || '',
                hourlyRate: stepThreePreviousData.hourlyRate || '',
                serviceFee: stepThreePreviousData.serviceFee || '',
                youWillGet: stepThreePreviousData.youWillGet || '',
                availability: stepThreePreviousData.availability || 'open',
            });
            if(stepThreePreviousData?.mainServices){
                setSelectedServices(stepThreePreviousData?.mainServices?.split(', ')?.map(i => ({id: '', service_name: i})));
            }
        }
    }, [stepThreePreviousData]);

    /**
     * getting the service fee
     */
    useEffect(() => {
        const getServiceFeeConfig = async () => {
            try {
                const res = await GetApi("/services/serviceProviderConfig");
                if (res?.data && res?.data?.data?.spProfileServiceFee) setServiceFee(res?.data?.data?.spProfileServiceFee);
                else setServiceFee(config?.SERVICE_FEE?.SERVICE_PROVIDER_PROFILE);
            } catch (error) {
                console.log(error);
            }
        }
        getServiceFeeConfig();
    }, []);

    /**
     * Onchange function for the Service selection
     * @param {*} service 
     */

    const handleServiceSelection = (service) => {
        const serviceId = service.id;
        const serviceName = service.service_name;

        // Check if the skill is already selected
        if (!selectedServices.some((selectedService) => selectedService.service_name === serviceName)) {
            const tempArr = [...selectedServices, { id: serviceId, service_name: serviceName }];
            setSelectedServices(tempArr);
            // Update mainServices in formData
            setFormData(prevFormData => ({
                ...prevFormData,
                mainServices: tempArr?.map(i => i?.service_name).join(', ')
            }));
            if (tempArr.length > 0) {
                handleDataUpdate({
                    ...formData,
                    mainServices: tempArr?.map(i => i?.service_name).join(', ')
                })
            }
            // Clear validation error for main services
            setValidationErrors(prevErrors => ({
                ...prevErrors,
                mainServices: ''
            }));
        } else {
            // If skill is already selected, remove it from the array
            const tempArr = selectedServices.filter((selectedService) => selectedService.id !== serviceId);
            setSelectedServices(tempArr);
            setFormData((prevFormData) => {
                return {
                    ...prevFormData,
                    mainServices: tempArr?.map(i => i?.service_name).join(', ')
                }
            });
            if (tempArr.length > 0) {
                handleDataUpdate({
                    ...formData,
                    mainServices: tempArr?.map(i => i?.service_name).join(', ')
                })
            }
        }
        setMainService('');
    };

    /**
    * Function to update the avaialbility
     * @param {*} value 
     */
    const handleBoxClick = (value) => {
        setSelectedValue(value);
        setFormData(prevFormData => ({
            ...prevFormData,
            availability: value, // Update availability in formData
        }));
        // handleDataUpdate(prevFormData => ({
        //     ...prevFormData,
        //     availability: value
        // }));
        handleDataUpdate({
            ...formData,
            availability: value
        })
    };


    /**
     * Onchange function for the step 3 input fields
     * @param {*} e 
     */
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'hourlyRate') {
            setFormData(prevFormData => ({
                ...prevFormData,
                [name]: value,
                serviceFee: ((value / 100) * serviceFee ?? 0).toFixed(2),
                youWillGet: `${(value - (value / 100) * serviceFee ?? 0).toFixed(2)}`
            }));
            handleDataUpdate({
                ...formData,
                [name]: value,
                serviceFee: (value / 100) * 10,
                youWillGet: `${value - (value / 100) * 10}`
            })
        } else {
            setFormData({
                ...formData,
                [name]: value
            });
            handleDataUpdate({
                ...formData,
                [name]: value
            })
        }

        // Clear validation error for the field being updated
        setValidationErrors(prevErrors => ({
            ...prevErrors,
            [name]: ''
        }));

        // Clear validation errors for service fee and you will get fields when hourly rate is entered
        if (name === 'hourlyRate' && formData.hourlyRate >= 0 && formData.hourlyRate <= 9999999999) {
            setValidationErrors(prevErrors => ({
                ...prevErrors,
                serviceFee: '',
                youWillGet: ''
            }));
        }
    };

    /***
     * Validation function for the step 3
     */
    const validateForm = () => {
        const errors = {};

        // Validation logic for main services, hourly rate, service fee, and you will get fields...
        if (!selectedServices) {
            errors.mainServices = 'Main Services are required.';
        }
        if (selectedServices && selectedServices.length === 0) {
            errors.mainServices = 'Main Services are required.';
        }
        if (!formData.hourlyRate.trim()) {
            errors.hourlyRate = 'Hourly Rate is required.';
        }

        setValidationErrors(errors);

        return Object.keys(errors).length === 0;
    };


    /**
     * On submit function for the Stepper
     * @param {*} e 
     */
    const handleSubmit = (e) => {
        e.preventDefault();
        const isFormValid = validateForm();

        if (isFormValid) {
            onNext()
        }
    };

    /**
     * Error Msg Function
     * @param {*} fieldName 
     * @returns 
     */
    const renderErrorMessage = (fieldName) => {
        return validationErrors[fieldName] && (
            <Typography variant="body2" color="error">{validationErrors[fieldName]}</Typography>
        );
    };

    /**
     * HandleFinish function  (Onsubmit called)
     */
    const handleFinish = () => {
        handleSubmit(new Event('submit'));
    };

    return (
        <Box className="onboarding-service-left-container">
            <Typography className='font-36 heading' component="h4">
                Service Information
            </Typography>
            <Typography className='font-18 sub-heading' component="p">
                Provide your service information to make finding your Service Provider profile on RACE easier.
            </Typography>
            {/* Skill Section */}
            {/* Form */}
            <form onSubmit={handleSubmit}>
                <Box className="proff-info-box" mt={3}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={3} sm={12}>
                            <Typography className='font-22 title'>
                                Main Services
                            </Typography>
                            <Typography className='font-12 sub-title'>You can choose up to 10 work categories—your core services. We suggest picking the strongest, most specialized, and/or most in-demand. While the categories you select will impact how clients find your profile, you can submit proposals to job postings in any category.</Typography>
                        </Grid>
                        <Grid item xs={12} md={9} sm={12}>
                            {/* Input field for main services */}
                            {
                                <>
                                    <Box className="input-box">
                                        <TextField
                                            id="mainServices"
                                            name="mainServices"
                                            label="Select Service"
                                            InputLabelProps={{ shrink: true }}
                                            variant="outlined"
                                            placeholder='E.g. Auditor needed for real estate project'
                                            value={mainService}
                                            onChange={(e) => setMainService(e.target.value)}
                                            error={!!validationErrors.mainServices}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter' && e.target.value.length > 3) {
                                                    handleServiceSelection({ id: 0, service_name: e.target.value });
                                                }
                                            }}
                                        // disabled
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <Tooltip title={'After typing main service, please press enter'} arrow>
                                                            <InfoOutlinedIcon style={{color: "#fff"}}/>
                                                        </Tooltip>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        {renderErrorMessage('mainServices')}
                                    </Box>
                                    <Box className="button-div-bg-none skill-btn-badge" mt={2}>
                                        {selectedServices && selectedServices.length > 0 &&
                                            selectedServices.map((service, index) => (
                                                <Badge
                                                    badgeContent={<CloseIcon className="font-12" />}
                                                    color="error"
                                                    overlap="rectangular"
                                                    onClick={() => {
                                                        let tempArray = [...selectedServices];
                                                        tempArray = tempArray.filter((_, idx) => index !== idx);
                                                        setSelectedServices(tempArray);
                                                        handleDataUpdate({
                                                            ...formData,
                                                            mainServices: tempArray?.map(i => i?.service_name).join(', ')
                                                        });
                                                    }}
                                                >
                                                    <Button
                                                        key={service.id}
                                                        className={"skill-button"}
                                                        variant="outlined"
                                                        startIcon={<AddIcon />}
                                                        onClick={() => handleServiceSelection(service)}
                                                    >
                                                        {service.service_name}
                                                    </Button>
                                                </Badge>
                                            ))}
                                    </Box>
                                    <Box className="button-div-bg-none">
                                        {selectedServices && selectedServices.length > 0 && <br />}
                                        {sharedDataStepTwo &&
                                            sharedDataStepTwo?.serviceData?.filter(item => !new Set(selectedServices.map(itm => itm.service_name)).has(item.service_name)).map((service) => (
                                                <Button
                                                    key={service.id}
                                                    variant="outlined"
                                                    startIcon={<AddIcon />}
                                                    onClick={() => handleServiceSelection(service)}
                                                >
                                                    {service.service_name}
                                                </Button>
                                            ))}
                                    </Box>
                                </>
                            }
                        </Grid>
                    </Grid>
                </Box>

                <Box className="proff-info-box" mt={3}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={3} sm={12}>
                            <Typography className='font-22 title'>
                                Hourly Rate
                            </Typography>
                            <Typography className='font-12 sub-title'>Clients will see the rate on your profile once you publish it. You can adjust it anytime in your settings. You can also customize your rate every time you submit a proposal</Typography>
                        </Grid>
                        <Grid item xs={12} md={9} sm={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={9} sm={12}>
                                    <Typography className='font-18 title'>
                                        Hourly rate
                                    </Typography>
                                    <Typography className='font-14 sub-title'>Total amount the client will see</Typography>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    {/* Input field for service fee */}
                                    <TextField
                                        id="hourlyRate"
                                        name="hourlyRate"
                                        label="$ / hour"
                                        InputLabelProps={{ shrink: true }}
                                        variant="outlined"
                                        placeholder='0.00'
                                        inputProps={{ min: 0, max: 5000 }}
                                        value={formData.hourlyRate}
                                        onChange={handleInputChange}
                                        error={!!validationErrors.hourlyRate}
                                    />
                                    {renderErrorMessage('hourlyRate')}
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} mt={3}>
                                <Grid item xs={12} md={9} sm={12}>
                                    <Typography className='font-18 title'>
                                        Service fee
                                    </Typography>
                                    <Typography className='font-14 sub-title'>This helps us run the platform and provide services like customer support</Typography>
                                </Grid>
                                <Grid item xs={12} md={3} sm={12}>
                                    <TextField
                                        id="serviceFee"
                                        name="serviceFee"
                                        label="$ / hour"
                                        InputLabelProps={{ shrink: true }}
                                        variant="outlined"
                                        placeholder='0.00'
                                        disabled
                                        value={formData.serviceFee}
                                        onChange={handleInputChange}
                                        style={{ color: 'blue' }}
                                    // error={!!validationErrors.serviceFee}
                                    />
                                    {/* {renderErrorMessage('serviceFee')} */}
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} mt={3}>
                                <Grid item xs={12} md={9} sm={12}>
                                    <Typography className='font-18 title'>
                                        You’ll get
                                    </Typography>
                                    <Typography className='font-14 sub-title'>The estimated amount you’ll receive after service fees</Typography>
                                </Grid>
                                <Grid item xs={12} md={3} sm={12}>
                                    <TextField
                                        id="youWillGet"
                                        name="youWillGet"
                                        label="$ / hour"
                                        disabled
                                        InputLabelProps={{ shrink: true }}
                                        variant="outlined"
                                        placeholder='0.00'
                                        value={formData.youWillGet}
                                        onChange={handleInputChange}
                                    // error={!!validationErrors.youWillGet}
                                    />
                                    {/* {renderErrorMessage('youWillGet')} */}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>

                <Box className='proff-info-box' mt={3}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={3} sm={12}>
                            <Typography className='font-22 title'>
                                Availability
                            </Typography>
                            <Typography className='font-12 sub-title'>Are you available to take on work now? You can change your status anytime in your settings</Typography>
                        </Grid>
                       
                            <Box className={`work-exper-box ${selectedValue === 'open' ? 'highlight' : ''}`} onClick={() => handleBoxClick('open')}>
                                <Box display='flex' justifyContent='end'>
                                    <Box className='add-icon-box'>
                                        <CheckIcon className='icon' />
                                    </Box>
                                </Box>
                                <Typography className='title font-16'>Open to offers</Typography>
                            </Box>
                      
                      
                            <Box className={`work-exper-box ${selectedValue === 'notAvailable' ? 'highlight' : ''}`} onClick={() => handleBoxClick('notAvailable')}>
                                <Box display='flex' justifyContent='end'>
                                    <Box className='add-icon-box'>
                                        <ClearIcon className='icon' />
                                    </Box>
                                </Box>
                                <Typography className='title font-16'>Not available</Typography>
                            </Box>
                       
                    </Grid>
                </Box>

                <Box mt={5}>
                    <Stack direction="row" spacing={2} alignItems="center">
                        <Box className='go-back-btn'>
                            <Button startIcon={<ArrowBackIcon />} onClick={onBack}>
                                Go Back
                            </Button>
                        </Box>
                        <Box className="continue-btn">
                            <Button
                                variant="contained"
                                startIcon={<ArrowForwardIcon />}
                                className='btn-rounded btn-large'
                                style={{ background: "var(--golden-gradient)" }}
                                onClick={handleFinish}
                            >
                                Review Profile
                            </Button>
                        </Box>
                    </Stack>
                </Box>

            </form>
        </Box>
    )
}

export default OnboardingServiceLeft